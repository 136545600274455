import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import pattern from "assets/grunge/pattern.svg";
import { useAppSelector } from "store";
import { projectTitleSelector } from "store/selectors";
import { selectProjectId } from "store/project";
import SettingsMenuContainer from "Components/Menu/SettingsMenuContainer";
import { useIsFreeUser, useOrganisation } from "hooks";
import Breadcrumb from "Pages/sites/Breadcrumb";
import { selectRole } from "store/auth";
import { UserRole } from "@ehabitation/ts-utils/browser";
import logo_small from "assets/images/logo_square.png";

interface HeaderProps {
  handleOpenWBReportModal?: () => void;
  handleOpenPlanImport?: () => void;
  handleOpenCategorise?: () => void;
  handleOpenRiskExport?: () => void;
}

const dotMatrixBgStyle = {
  backgroundImage: `url(${pattern})`,
  backgroundRepeat: "repeat",
  backgroundPosition: "center",
};

const Header: FC<HeaderProps> = ({
  handleOpenWBReportModal,
  handleOpenPlanImport,
  handleOpenCategorise,
  handleOpenRiskExport,
}) => {
  const location = useLocation();
  const { pathname } = location;

  const projectTitle = useAppSelector(projectTitleSelector);
  const projectId = useAppSelector(selectProjectId);
  const role = useAppSelector(selectRole);

  const showBreadcrumbs =
    role !== UserRole.superAdmin && pathname.includes("sites");
  const isPlanner = pathname.endsWith("plan");

  const organisation = useOrganisation();
  const isFreeUser = useIsFreeUser();

  return (
    <>
      <header
        className="flex shrink-0 items-center w-full bg-white h-22 sm:h-22 z-50"
        style={dotMatrixBgStyle}
      >
        <div className="flex h-full mr-auto">
          {/* {isFreeUser ? null : <h2>{organisation?.name}</h2>} */}
          {showBreadcrumbs && organisation && (
            <div className="relative">
              <div className="top-0 left-0 min-w-[650px] w-full z-9999">
                <Breadcrumb />
              </div>
            </div>
          )}
        </div>

        {!showBreadcrumbs && (
          <>
          
          {/* One of these will be chosen depending on viewport width */}
          <div
            className="flex-col w-full ml-10 mt-10 justify-left items-start block lg:hidden h-20 sm:h-20 mr-8"
            data-testid="logo-link"
          >
            <a href="/"><img src={logo_small} alt="EHAB Small Logo" className="h-full" /></a>
          </div>

          <div
            className="flex-col w-full ml-10 mt-5 justify-center items-start hidden lg:block h-12 mr-4"
          >
            <a href="/"><img src={logo_small} alt="EHAB Logo" className="h-full" /></a>
          </div>
          </>
        )}  

        {!showBreadcrumbs &&
          (isPlanner ? (
            <h2
              data-testid="project-title"
              className="mr-8 bg-white/[.06] capitalize p-2"
            >
              {projectTitle}
            </h2>
          ) : (
            <Link to={`/projects/${projectId}`} className="text-gray-700">
              <h2
                data-testid="project-title"
                className="mr-8 bg-white/[.06] capitalize p-2"
              >
                {projectTitle}
              </h2>
            </Link>
          ))}

        <p className="sm:hidden px-6 text-gray-500 italic">Mobile View</p>

        <div className="mr-10 mt-2">
          <SettingsMenuContainer
            handleOpenWBReportModal={handleOpenWBReportModal}
            handleOpenPlanImport={handleOpenPlanImport}
            handleOpenCategorise={handleOpenCategorise}
            handleOpenRiskExport={handleOpenRiskExport}
          />
        </div>
      </header>
    </>
  );
};

export default Header;
