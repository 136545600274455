import styled from "styled-components";
// UI Elements
import { StyledMatrixContainer } from "elements";
import Header from "Components/Header/Header";
// Store
import { useAppSelector } from "store";
import { selectIsLoggedIn, selectRole } from "store/auth";
// Assets
import ErrorIllustration from "assets/images/error-illustration.png";

const StyledSpan = styled.span`
  color: rgb(85, 26, 139);
  cursor: pointer;
  text-decoration: underline;
`;

export default function ErrorComponent() {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const role = useAppSelector(selectRole);

  return (
    <>
      {isLoggedIn && role !== "superAdmin" && (
        <Header
          handleOpenWBReportModal={() => {}}
          handleOpenPlanImport={() => {}}
        />
      )}
      <StyledMatrixContainer>
        <h1>Something went wrong :(</h1>
        <img
          src={ErrorIllustration}
          alt="Error illustration"
          height={"600px"}
        />
        <h2>An error occoured and we couldn't automatically fix it :(</h2>
        <span>
          Please try to{" "}
          <StyledSpan onClick={() => window.location.reload()}>
            reload the page
          </StyledSpan>{" "}
          or <a href="/">go back to the dashboard</a>
        </span>
      </StyledMatrixContainer>
    </>
  );
}
