//Types
import { AppThunk } from "store";
//Store
import { batch } from "react-redux";
import {
  setAllHolidays,
  setAllWorkDays,
  setCalendarInitialised,
} from "store/calendar";
import { selectActiveSiteId } from "store/auth";
//Helpers
import { sortArrayOfISOStringASC, sortArrayOfWorkDays } from "helpers";
//Types
import { fetchSiteCalendar, setSiteCalendarAsDefault } from "./helpers";

export const fetchCalendarData: AppThunk = () => async (dispatch, getState) => {
  const state = getState();
  const siteId = selectActiveSiteId(state);
  const calendarInitialised = state.calendar.calendarInitialised;

  if (!siteId) return;
  if (calendarInitialised) return; //Only run once
  try {
    const [docExists, data] = await fetchSiteCalendar(siteId);

    if (!docExists && data) {
      const sortedHolidays = sortArrayOfISOStringASC(data.holidays);
      const sortedWorkDays = sortArrayOfWorkDays(data.workDays);

      await setSiteCalendarAsDefault(siteId);

      batch(() => {
        dispatch(setAllWorkDays(sortedWorkDays));
        dispatch(setAllHolidays(sortedHolidays));
        dispatch(setCalendarInitialised(true));
      });
    }

    if (docExists && data) {
      const sortedHolidays = sortArrayOfISOStringASC(data.holidays);
      const sortedWorkDays = sortArrayOfWorkDays(data.workDays);

      batch(() => {
        dispatch(setAllWorkDays(sortedWorkDays));
        dispatch(setAllHolidays(sortedHolidays));
        dispatch(setCalendarInitialised(true));
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      console.error(error);
      return `Error: ${error.message}`;
    }
  }
};
