import {
  CollectionType,
  ITask,
  ITaskCategory,
  WeatherKey,
  getRiskMatrixHashes,
} from "@ehabitation/ts-utils/browser";
import {
  Timestamp,
  collection,
  deleteField,
  doc,
  getDocs,
  query,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { SimulationTaskResult, getRiskMatrixDoc } from "helpers";
import { db } from "firebaseConfig";
import { isEqual } from "lodash";
import { ITaskObj } from "types";
import _ from "lodash";
import { pl } from "date-fns/locale";

const clearWeatherKeys = (empty?: boolean) => {
  const clearKeys = {} as any;
  for (const key in WeatherKey) {
    clearKeys[key as WeatherKey] = empty ? undefined : deleteField();
  }
  return clearKeys;
};

export const syncThresholds = async (
  tasks: (ITask & { simulation: SimulationTaskResult })[],
  projectId: string,
  planId: string
) => {
  if (projectId && planId) {
    const rmCategoryNames = new Set<string>();
    const deletedCategoryNames = new Set<string>();
    const updatedCategoryNames = new Set<string>();

    const projectRM = await getRiskMatrixDoc(
      projectId,
      CollectionType.Projects
    );

    const getRiskMatrixCategories = async (docId: string) => {
      const rmCollection = collection(
        db,
        CollectionType.RiskMatrix,
        docId,
        CollectionType.Categories
      );
      return {
        riskMatrix: (await getDocs(query(rmCollection))).docs,
        rmCollection,
      };
    };
    const { riskMatrix: projectRMCategoriesCollection } =
      await getRiskMatrixCategories(projectRM.id);

    const projectCategoriesNameMap = projectRMCategoriesCollection.reduce(
      (acc, doc) => {
        const category = { id: doc.id, ...doc.data() } as ITaskCategory;
        rmCategoryNames.add(category.name);
        acc.set(category.name, category);
        return acc;
      },
      new Map<string, ITaskCategory>()
    );

    const planRiskMatrixDoc = await getRiskMatrixDoc(
      planId,
      CollectionType.Plans
    );
    const {
      riskMatrix: planRiskMatrixCategoryDocs,
      rmCollection: planRMCategoriesCollection,
    } = await getRiskMatrixCategories(planRiskMatrixDoc.id);

    const planCategoriesNameMap = planRiskMatrixCategoryDocs.reduce(
      (acc, doc) => {
        const category = { id: doc.id, ...doc.data() } as ITaskCategory;
        rmCategoryNames.add(category.name);
        acc.set(category.name, category);
        return acc;
      },
      new Map<string, ITaskCategory>()
    );

    // Iterate over all existing categories in both risk matrices
    const zipRiskMatrixBatches = _.chunk(Array.from(rmCategoryNames), 300).map(
      (chunk) => {
        const batch = writeBatch(db);
        chunk.forEach((categoryName) => {
          const isPlanCategory = planCategoriesNameMap.has(categoryName);
          const isProjectCategory = projectCategoriesNameMap.has(categoryName);

          // If category name in project risk matrix but not in plan risk matrix, add it
          if (!isPlanCategory && isProjectCategory) {
            const { id, ...category } =
              projectCategoriesNameMap.get(categoryName)!;
            batch.set(doc(planRMCategoriesCollection, id), category, {
              merge: true,
            });
          }

          // If category name in plan risk matrix but not in project risk matrix, delete it
          if (isPlanCategory && !isProjectCategory) {
            const { id, name } = planCategoriesNameMap.get(categoryName)!;
            batch.delete(doc(planRMCategoriesCollection, id));
            deletedCategoryNames.add(name);
          }

          // If category name in both, update it (in the future this will check if thresholds match)
          if (isPlanCategory && isProjectCategory) {
            const planCategory = planCategoriesNameMap.get(categoryName)!;
            const projCategory = projectCategoriesNameMap.get(categoryName)!;
            const newCategory = {
              ...planCategory,
              shift: projCategory.shift,
              thresholds: projCategory.thresholds,
            };

            // We don't need to update thresholds if they are equal.
            if (!isEqual(planCategory, projCategory)) {
              batch.set(
                doc(planRMCategoriesCollection, planCategory.id),
                newCategory
              );
              updatedCategoryNames.add(newCategory.name);

              // mutate plan thresholds so that the hashes are correct later.
              planCategory.shift = projCategory.shift;
              planCategory.thresholds = projCategory.thresholds;
            }
          }
        });

        return batch;
      }
    );

    const updatedTasks: ITaskObj = {};

    const taskChangeBatches = _.chunk(tasks, 300).map((taskChunk) => {
      const batch = writeBatch(db);

      taskChunk.forEach(({ simulation, ...task }) => {
        const taskCategory = task.taskCategory?.selectedName!;

        if (
          !updatedCategoryNames.has(taskCategory) &&
          !deletedCategoryNames.has(taskCategory)
        )
          return;

        const updatedCategory = projectCategoriesNameMap.get(task.taskType);
        let updatedTask = { ...task } as any;
        if (deletedCategoryNames.has(taskCategory)) {
          // Delete the category off the task
          updatedTask.taskType = "";
          updatedTask.taskCategory = {
            ...task.taskCategory,
            selectedName: "",
            selectedId: "",
            selectedSource: "deletedCategory",
            categoryError: {
              hasError: true,
              message: "Category deleted off Risk Matrix",
            },
          };
          updatedTasks[task.id] = {
            ...updatedTask,
            ...clearWeatherKeys(true),
          };
          updatedTask = {
            ...updatedTask,
            ...clearWeatherKeys(false),
          };
        } else if (updatedCategoryNames.has(task.taskType)) {
          // Update the category on the task
          updatedTask.taskType = updatedCategory?.name;
          updatedTask.taskCategory = {
            ...task.taskCategory,
            selectedName: updatedCategory?.name,
            selectedId: updatedCategory?.id,
            categoryError: {
              hasError: false,
              message: "",
            },
          };
          updatedTasks[task.id] = {
            ...updatedTask,
            ...clearWeatherKeys(true),
            ...updatedCategory?.thresholds,
          };
          updatedTask = {
            ...updatedTask,
            ...clearWeatherKeys(false),
            ...updatedCategory?.thresholds,
          };
        }

        // Trim the deleted categories off the taskCategory suggestions
        const updatedSuggestions = { ...task.taskCategory?.suggestions };
        if (updatedSuggestions) {
          for (const suggestionKey of Object.keys(updatedSuggestions)) {
            // Check if the value is an array before filtering
            if (Array.isArray(updatedSuggestions[suggestionKey])) {
              updatedSuggestions[suggestionKey] = updatedSuggestions[suggestionKey].filter((suggestion) => {
                return !deletedCategoryNames.has(suggestion.name);
              });
            }
          }
          updatedTask.taskCategory = {
            ...updatedTask.taskCategory,
            suggestions: updatedSuggestions,
          };
        }
        batch.set(
          doc(db, CollectionType.Plans, planId, CollectionType.Tasks, task.id),
          updatedTask,
          { merge: true }
        );
      });
      return batch;
    });

    const {
      rmDocMajorHash: projectMajorHash,
      rmDocMinorHash: projectMinorHash,
    } = getRiskMatrixHashes(Array.from(projectCategoriesNameMap.values()));

    // Make batched updates to the plan risk matrix
    await Promise.all(zipRiskMatrixBatches.map((batch) => batch.commit()));

    // Update the last updated field of the plan risk matrix
    // Reset both hashed to the project matrix otherwise we need to do a whole lot of looping and processing of diffs.
    await updateDoc(planRiskMatrixDoc.ref, {
      minorHash: projectMinorHash,
      majorHash: projectMajorHash,
      lastUpdated: Timestamp.now(),
    });
    await updateDoc(projectRM.ref, {
      minorHash: projectMinorHash,
      majorHash: projectMajorHash,
      lastUpdated: Timestamp.now(),
    });

    // Make batched updates to the plan tasks
    await Promise.all(taskChangeBatches.map((batch) => batch.commit()));
  }
};