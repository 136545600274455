import { FC, useEffect, useState } from "react";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import { db } from "firebaseConfig";
import { useAppSelector } from "store";
import { selectUserId } from "store/auth";
import {
  CollectionType,
  IPlan,
  transformPlanDoc,
  PlanStatus,
  ISite,
  IProject,
} from "@ehabitation/ts-utils/browser";
import { LoadingType, LoadingWrapper } from "@ehabitation/ui";

import {
  PlanImportCategorySelection,
  PlanImportFile,
  PlanImportDraft,
  PlanImportComplete,
} from "./pages";
import { PlanImportPage } from "./types";
import { logImportPlanEvent } from "helpers/analytics";
import { isMultiSite } from "types";
import { PlanSanitizing } from "./pages/PlanSanitizing";

const useActivePlan = (
  siteId: string,
  projectId: string,
  multiSite: boolean
) => {
  const userId = useAppSelector(selectUserId);
  const [plan, setPlan] = useState<IPlan>();
  const [error, setError] = useState("");
  const [hasInitLoad, setHasInitLoad] = useState<boolean>(false);
  useEffect(() => {
    const planQuery = query(
      collection(db, CollectionType.Plans),
      where("project", "==", projectId),
      where("owner", "==", userId),
      where("complete", "==", false),
      where("status", "in", [
        PlanStatus.staging,
        PlanStatus.staged,
        PlanStatus.categorised,
        PlanStatus.multistaged,
      ]),
      orderBy("createdAt", "desc")
    );

    const unsubscribe = onSnapshot(
      planQuery,
      (snapshot) => {
        var fetchedActivePlanDocs;
        if (multiSite) {
          fetchedActivePlanDocs = snapshot.docs;
        } else {
          fetchedActivePlanDocs = snapshot.docs.filter(
            (p) => p.data().site === siteId
          );
        }

        if (fetchedActivePlanDocs.length) {
          const activePlanDoc = fetchedActivePlanDocs[0];
          setPlan(transformPlanDoc(activePlanDoc.id, activePlanDoc.data()));
        } else {
          setPlan(undefined);
        }
        setHasInitLoad(true);
      },
      (error) => {
        console.error(error);
        setError("Failed to load plan upload.");
      }
    );
    return unsubscribe;
  }, [siteId, userId]);
  return { plan, hasInitLoad, error };
};

const PlanImport: FC<{
  handleSuccess: Function;
  site: ISite;
  project: IProject | null;
}> = ({ handleSuccess, site, project }) => {
  const projectId = site.project;
  const [activePage, setActivePage] = useState<PlanImportPage>(
    PlanImportPage.Draft
  );
  const [completePlanId, setCompletePlanId] = useState<string>();

  const { hasInitLoad: hasInitPlanLoad, plan } = useActivePlan(
    site.id,
    site.project,
    isMultiSite(project)
  );

  useEffect(() => {
    if (!plan && hasInitPlanLoad && activePage === PlanImportPage.Draft) {
      setActivePage(PlanImportPage.File);
    }
  }, [plan]);

  const isMultiProject =
    plan?.xerProjectList && plan?.xerProjectList?.length > 1;

  return (
    <div className="h-full w-full">
      <div className="h-full w-full py-8 overflow-hidden flex flex-col justify-center">
        <LoadingWrapper
          message="Looking for plans in progress..."
          type={LoadingType.alt}
          loading={!hasInitPlanLoad}
        >
          {activePage === PlanImportPage.Draft && (
            <div
              data-testid="import-draft-slide"
              className="space-y-8 self-center"
            >
              <h2 className="font-bold">
                {isMultiProject
                  ? "Multi Project File"
                  : "Your in-progress import"}
              </h2>
              <PlanImportDraft
                plan={plan}
                site={site}
                setPage={setActivePage}
                project={project!}
              />
            </div>
          )}
          {activePage === PlanImportPage.File && (
            <div
              data-testid="import-file-slide"
              className="space-y-8 self-center"
            >
              <PlanImportFile
                site={site}
                plan={plan}
                setPage={setActivePage}
                project={project!}
              />
            </div>
          )}
          {activePage === PlanImportPage.CategorySelection &&
            (plan ? (
              <div
                data-testid="import-tasks-slide"
                className="self-stretch flex flex-col gap-2 overflow-hidden flex-grow"
              >
                <PlanImportCategorySelection
                  plan={plan}
                  site={site}
                  setPage={setActivePage}
                  handleComplete={() => {
                    setCompletePlanId(plan.id);
                    setActivePage(PlanImportPage.Complete);
                  }}
                />
              </div>
            ) : (
              <div>
                <p className="text-red-600">An error has occurred.</p>
                <p className="text-red-600">
                  Please reload this page and try again, or contact
                  support@ehab.co if this error persists.
                </p>
                <p className="text-red-600">
                  If you would like to try and recover an in-progress import,
                  please contact support@ehab.co. We might be able to help!
                </p>
              </div>
            ))}

          {/* {activePage === PlanImportPage.Sanitizing &&
            (plan ? (
              <div
                data-testid="import-tasks-slide"
                className="self-stretch flex flex-col gap-2 overflow-hidden flex-grow"
              >
                <PlanSanitizing 
                  plan={plan}
                  site={site}
                  setPage={setActivePage}
                  handleComplete={() => {
                    setCompletePlanId(plan.id);
                    setActivePage(PlanImportPage.Complete);
                  }}
                  handleBack={() => {
                    setCompletePlanId(undefined);
                    setActivePage(PlanImportPage.CategorySelection);
                  }}
                />
              </div>
            ) : (
              <div>
                <p className="text-red-600">An error has occurred.</p>
                <p className="text-red-600">
                  Please reload this page and try again, or contact
                  support@ehab.co if this error persists.
                </p>
                <p className="text-red-600">
                  If you would like to try and recover an in-progress import,
                  please contact support@ehab.co. We might be able to help!
                </p>
              </div>
            ))} */}

          {activePage === PlanImportPage.Complete ? (
            <div
              data-testid="import-complete-slide"
              className="self-stretch flex flex-col gap-2 overflow-hidden"
            >
              <PlanImportComplete
                planId={completePlanId!}
                handleBack={() => {
                  setCompletePlanId(undefined);
                  setActivePage(PlanImportPage.CategorySelection);
                }}
                handleSuccess={() => {
                  logImportPlanEvent(site.id, completePlanId!);
                  handleSuccess(completePlanId!);
                }}
                site={site}
              />
            </div>
          ) : null}

          {plan && activePage !== PlanImportPage.Draft ? (
            <div className="bg-slate-200 px-4 py-1 absolute bottom-4 left-4 text-[1.3rem] ">
              <strong className="truncate">{plan.title}</strong>{" "}
              <em>uploaded {plan.createdAt?.toLocaleString()}</em>
            </div>
          ) : null}
        </LoadingWrapper>
      </div>
    </div>
  );
};

export default PlanImport;
