import { useState } from "react";
import { useSaveAs } from "./useSaveAs";
import { useTriggerSimulation } from "hooks/useTriggerSimulation";

type BehaviourOptions = "new" | "overwrite";

export const useSaveAsForm = () => {
  const { saving, cloneExistingPlan, overwriteCurrentPlan } = useSaveAs();

  const [newPlanName, setNewPlanName] = useState("");
  const [behaviourSelection, setBehaviour] =
    useState<BehaviourOptions>("overwrite");
  const [error, setError] = useState("");

  const { triggerSimulation } = useTriggerSimulation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (behaviourSelection === "new") {
      if (newPlanName.length === 0) {
        setError("Please give the plan a name");
        return;
      }
      setError("");

      const newPlanId = await cloneExistingPlan(newPlanName);
      triggerSimulation(newPlanId);
    } else if (behaviourSelection === "overwrite") {
      setError("");
      await overwriteCurrentPlan();
    }
  };

  const handleRadioInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as BehaviourOptions;
    setBehaviour(name);
  };

  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPlanName(e.target.value);
  };

  return {
    saving,
    error,
    newPlanName,
    behaviourSelection,
    handleSubmit,
    handleRadioInputChange,
    handleTextInputChange,
  };
};
