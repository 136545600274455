import { ISimulation } from "@ehabitation/ts-utils/browser";
import { HeatmapRow, StatBox } from "@ehabitation/ui";
import { Tooltip } from "@material-ui/core";
import { firebaseFunction } from "helpers";

import { FC, useEffect, useState } from "react";
import { BsInfoCircle } from "react-icons/bs";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const PlanHeatmap: FC<{
  planId?: string;
  simulation?: ISimulation;
}> = ({ planId, simulation }) => {
  interface HeatmapData {
    [year: number]: Record<number, number>; // Use Record for better readability
  }

  const [heatmapData, setHeatmapData] = useState<HeatmapData>({}); // Initialize state for heatmapData

  useEffect(() => {
    const fetchHeatmapData = async () => {
      if (simulation?.heatmap) {
        const parsedHeatmap: HeatmapData = JSON.parse(simulation.heatmap);
        console.log(simulation.heatmap);
        setHeatmapData(parsedHeatmap);
      } else if (simulation) {
        const generateSimulationHeatmap = firebaseFunction(
          "GenerateSimulationHeatmap"
        );

        console.log("plan_id ", planId);
        console.log("simulation_id ", simulation.id);
        const response = (await generateSimulationHeatmap({
          plan_id: planId,
          simulation_id: simulation?.id,
        })) as {
          data?: {
            heatmap: string;
            error: string[];
          };
        };

        console.log(response.data);

        if (response.data?.heatmap) {
          const parsedHeatmap: HeatmapData = JSON.parse(response.data.heatmap);
          console.log(parsedHeatmap);
          setHeatmapData(parsedHeatmap);
        }
      }
      console.log("the end");
    };

    fetchHeatmapData();
  }, [planId, simulation]);

  return (
    <>
      {Object.keys(heatmapData).length ? (
        <div className="w-full py-4">
          <div className="px-8 py-6">
            <StatBox
              title="Weather Risk Heat Map"
              className="p-0"
              subTitle="(week/year)"
              subClassName="flex flex-col items-stretch content-center gap-1"
              headerRightSlot={<Legend />}
            >
              <div className="pb-10">
                Indicates the areas of risk in your program. Numbers (e.g., 0.1,
                0.7, 1.0) represent percentages in decimal form.
                <br />
                e.g. 0.7, red: 70% of planned activities are impacted.
              </div>

              <div className="w-full flex justify-between pl-14 pr" key="axis">
                {months.map((month, key) => (
                  <div key={key} className="w-[7.7%] grid place-items-center">
                    {month}
                  </div>
                ))}
              </div>
              {Object.keys(heatmapData).map((year) => (
                <div className="flex gap-3 items-center mb-3" key={year}>
                  <span className="">{year}</span>
                  <span className="flex-grow">
                    <HeatmapRow
                      key={"heatmap_row_" + { year }}
                      data={
                        heatmapData[year as unknown as keyof typeof heatmapData]
                      }
                      className="flex-grow"
                    />
                  </span>
                </div>
              ))}
            </StatBox>
          </div>
        </div>
      ) : null}
    </>
  );
};

const Legend = () => {
  return (
    <div>
      <Tooltip
        arrow
        title={
          <span className="text-xl p-1">
            <h2 className="pb-4">Risk Heatmap</h2>
            <div className="pb-2">
              Indicates the areas of risk in your program based on the "as
              planned" scenario.
            </div>
            <div className="flex align-middle gap-2 items-baseline pb-2">
              <div className="w-4 h-4 bg-slate-300"></div>
              <div>No weather impact or no activities impacted</div>
            </div>
            <div className="flex align-top gap-2 items-baseline pb-2">
              <div className="w-4 h-4 bg-green-300"></div>
              <div>Fewer than 20% of activities affected</div>
            </div>
            <div className="flex align-top gap-2 items-baseline pb-2">
              <div className="w-4 h-4 bg-amber-300"></div>
              <div>20% to 60% of activities are affected</div>
            </div>
            <div className="flex align-middle gap-2 items-baseline">
              <div className="w-4 h-4 bg-red-300"></div>
              <div>More than 60% of activities affected</div>
            </div>
          </span>
        }
      >
        <div className="flex justify-between">
          <BsInfoCircle className="mt-1" />
        </div>
      </Tooltip>
    </div>
  );
};
