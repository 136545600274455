import React from "react";
import {
  AiOutlineDownload,
  AiOutlineFilePdf,
  AiOutlineSave,
  AiOutlineUpload,
  AiOutlineFileExcel,
  AiOutlineBug,
  AiOutlineAudit,
  AiOutlineExclamationCircle,
  AiOutlineCloudSync,
} from "react-icons/ai";
import {
  BiBarChartSquare,
  BiCloudDownload,
  BiCog,
  BiHome,
} from "react-icons/bi";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { GrRisk, GrUserAdmin, GrOverview } from "react-icons/gr";
import { VscGraph } from "react-icons/vsc";

export const options = {
  dashboard: {
    icon: <BiHome className="h-7 w-7" />,
    text: "Dashboard",
  },
  settings: {
    icon: <BiCog className="h-7 w-7" />,
    text: "Settings",
  },
  overview: {
    icon: <GrOverview className="h-7 w-7" />,
    text: "Project Overview",
  },
  plan: {
    icon: <BiBarChartSquare className="h-7 w-7" />,
    text: "Planner",
  },
  admin: {
    icon: <GrUserAdmin className="h-7 w-7" />,
    text: "Admin",
  },
  save: {
    icon: <AiOutlineSave className="h-7 w-7" />,
    text: "Save...",
  },
  import: {
    icon: <AiOutlineDownload className="h-7 w-7" />,
    text: "Import",
  },
  categorise: {
    icon: <AiOutlineCloudSync className="h-7 w-7" />,
    text: "Categorise Tasks",
  },
  export: {
    icon: <AiOutlineUpload className="h-7 w-7" />,
    text: "Export",
  },
  wb: {
    icon: <AiOutlineFilePdf className="h-7 w-7" />,
    text: "Weather Baseline Report",
  },
  logout: {
    icon: <RiLogoutBoxRLine className="h-7 w-7" />,
    text: "Logout",
  },
  risk: {
    icon: <GrRisk className="h-7 w-7" />,
    text: "Risk Analysis Exports",
  },
  tornado: {
    icon: <VscGraph className="h-7 w-7" />,
    text: "Delay Charts",
  },
  historic: {
    icon: <BiCloudDownload className="h-7 w-7" />,
    text: "Historic Weather Data",
  },
  activity: {
    icon: <AiOutlineFileExcel className="h-7 w-7" />,
    text: "Activity Export",
  },
  ["debug-thresholds"]: {
    icon: <AiOutlineExclamationCircle className="h-7 w-7" />,
    text: "Apply Thresholds",
  },
  ["debug-model"]: {
    icon: <AiOutlineBug className="h-7 w-7" />,
    text: "Model Rerun",
  },
  ["debug-audit"]: {
    icon: <AiOutlineAudit className="h-7 w-7" />,
    text: "Log & Copy State",
  },
};
