import { FC, useEffect, useMemo, useState } from "react";
import { ITask, KeyViolationData } from "@ehabitation/ts-utils/browser";
import { Tooltip } from "@material-ui/core";

import { IThresholdUpdate, weatherUnitKey } from "types";
import { SimulationLevel, SimulationTaskResult } from "helpers";
import { WeatherKey } from "@ehabitation/ts-utils/browser";
import { weatherKeyIcons } from "../constants";
import { thresholdCategories } from "../weather/WeatherView";
import { DifferenceCell } from "../components/DifferenceCell";
import { getLevelBorderColorClass } from "@ehabitation/ui";

interface CategoryRowPrintableProps {
  task: ITask & { simulation: SimulationTaskResult };
  mitigatedTask?: Partial<
    SimulationTaskResult &
      IThresholdUpdate & {
        riskIntervalResults: any;
        likelyCause: string;
        isMitigated: boolean;
      }
  >;
  selectedSimLevel: SimulationLevel;
  mitigationSimulationPending: boolean;
  mitigationSimulationInProgress: boolean;
}

export const groupName = (name: string) => {
  const groupName = name.replace(/([A-Z])/g, " $1").trim();
  return groupName[0].toUpperCase() + groupName.slice(1);
};

const mappedKey = (key: string) => {
  switch (key) {
    case "maxTemp":
      return "temp";
    case "minTemp":
      return "temp";
    case "wind":
      return "windSpeed";
    case "dailyRainAcc":
      return "cumulative";
    case "hourlyRainAcc":
      return "rainfall";
    default:
      return key;
  }
};

export const CategoryRowPrintable: FC<CategoryRowPrintableProps> = ({
  task,
  mitigatedTask,
  selectedSimLevel,
  mitigationSimulationPending,
  mitigationSimulationInProgress,
}) => {
  const [impactPercentages, setImpactPercentages] = useState<{
    [key: string]: number;
  }>({});

  const isIndented = (task?.wbsHierarchyLevel || 0) > 0;
  const [targetThreshold, setTargetThreshold] = useState<WeatherKey | "">("");
  const planThreshold = task?.[targetThreshold as keyof ITask];
  const unitThreshold = useMemo(
    () => targetThreshold && task?.[weatherUnitKey[targetThreshold]],
    [targetThreshold, task, weatherUnitKey]
  );
  const planThresholdCategories = useMemo(
    () =>
      thresholdCategories.filter(({ key }) => typeof task[key] !== "undefined"),
    [task, thresholdCategories]
  );
  const mitigationThresholdDisplay: any = useMemo(() => {
    const acc = {
      ...(mitigatedTask?.isMitigated ? mitigatedTask : {}),
    };
    planThresholdCategories.forEach(({ key }) => {
      if (
        Number(acc[key]) === Number(task[key]) ||
        typeof acc[key] === "undefined"
      ) {
        delete acc[key];
      }
    });
    return acc;
  }, [task, mitigatedTask, planThresholdCategories]);

  const mitigatedThresholdCategories = useMemo(
    () =>
      planThresholdCategories.filter(
        ({ key }) => typeof mitigationThresholdDisplay[key] !== "undefined"
      ),
    [planThresholdCategories, mitigationThresholdDisplay]
  );
  useEffect(() => {
    if (
      mitigatedThresholdCategories.length > 0 &&
      task &&
      task.violationData &&
      task.maxDelayDays
    ) {
      const keys = Object.keys(task.violationData) as KeyViolationData[];
      const impacts: { [key: string]: number } = {};
      keys.forEach((key) => {
        const percentage =
          ((task?.violationData?.[key] || 0) / task.maxDelayDays!) * 100;
        impacts[key] = percentage;
        setImpactPercentages(impacts);
      });
    }
  }, [task, mitigatedThresholdCategories]);
  useEffect(() => {
    if (mitigatedThresholdCategories.length && targetThreshold === "") {
      setTargetThreshold(mitigatedThresholdCategories[0].key);
    }
  }, [mitigatedThresholdCategories]);

  useEffect(() => {
    if (mitigatedThresholdCategories.length && targetThreshold === "") {
      setTargetThreshold(mitigatedThresholdCategories[0].key);
    }
  }, [mitigatedThresholdCategories]);

  return (
    <>
      {mitigatedThresholdCategories.length ? (
        mitigatedThresholdCategories.map(({ key, title }, index) => (
          <tr
            data-task-id={task.id}
            className={`group ${task.WBS ? "" : "hover:bg-blue-50"}`}
            key={index}
          >
            {index === 0 ? (
              <>
                <td className={`border-x border-b relative`}>
                  <Tooltip
                    arrow
                    title={<span className="text-2xl">{task.title}</span>}
                  >
                    <div className="truncate">
                      {isIndented ? (
                        <>
                          <span
                            className={`border-l-2 absolute top-0 bottom-0 w-4 ${getLevelBorderColorClass(
                              task
                            )}`}
                          />
                          <span className="pr-4" />
                        </>
                      ) : null}
                      {task.title}
                    </div>
                  </Tooltip>
                </td>
                <td className={`px-4 truncate border-r border-b relative`}>
                  {task.taskType ? task.taskType : "-"}
                </td>
                <td
                  className={`px-4 truncate border-r border-b relative text-center  ${
                    mitigationSimulationInProgress && "animate-pulse"
                  }`}
                >
                  {task.simulation.riskIntervalResults?.[selectedSimLevel]
                    ?.weatherDays || 0}
                </td>
                <td
                  className={`px-4 truncate border-r border-b relative text-center  ${
                    mitigationSimulationInProgress && "animate-pulse"
                  }`}
                >
                  {mitigatedTask ? (
                    <DifferenceCell
                      base={
                        task.simulation.riskIntervalResults?.[selectedSimLevel]
                          ?.weatherDays
                      }
                      mitigated={
                        mitigatedTask && !mitigationSimulationPending
                          ? mitigatedTask?.riskIntervalResults?.[
                              selectedSimLevel
                            ]?.weatherDays || 0
                          : "?"
                      }
                    />
                  ) : (
                    "-"
                  )}
                </td>
              </>
            ) : (
              <td colSpan={4} className=" border-r border-b relative"></td>
            )}
            <td className="px-4 truncate border-r border-b text-center">
              <div className="flex justify-center">
                <div className="mr-2">
                  {impactPercentages?.[mappedKey(key)]
                    ? `${impactPercentages?.[mappedKey(key)]?.toFixed(0)}% `
                    : ""}
                </div>
                <div
                  key={key}
                  className="w-8 h-8 bg-contain"
                  style={{
                    backgroundImage: `url(${weatherKeyIcons[key]})`,
                  }}
                ></div>
              </div>
            </td>
            <td
              className={`px-4 truncate border-r border-b relative text-center`}
            >
              {title}
            </td>
            <td
              className={`px-4 truncate border-r border-b relative text-center`}
            >
              <div className="grid gap-1 grid-cols-2 items-baseline">
                <div className="text-end">{task?.[key as keyof ITask]}</div>
                <div className="text-start text-xl opacity-80">
                  {task?.[weatherUnitKey[key]]}
                </div>
              </div>
            </td>
            <td
              className={`px-4 truncate border-r border-b relative text-center`}
            >
              <span className="absolute inset-0">
                {typeof targetThreshold !== "undefined" &&
                typeof mitigationThresholdDisplay[targetThreshold] !==
                  "undefined" ? (
                  <>
                    <span>{mitigationThresholdDisplay[key]}</span>
                    <span className="pl-1 text-xl opacity-80">
                      {task?.[weatherUnitKey[key]]}
                    </span>
                  </>
                ) : (
                  "-"
                )}
              </span>
            </td>
          </tr>
        ))
      ) : (
        <tr
          data-task-id={task.id}
          className={`group ${task.WBS ? "" : "hover:bg-blue-50"}`}
        >
          <td className={`border-x border-b relative`}>
            <Tooltip
              arrow
              title={<span className="text-2xl">{task.title}</span>}
            >
              <div className="truncate">
                {isIndented ? (
                  <>
                    <span
                      className={`border-l-2 absolute top-0 bottom-0 w-4 ${getLevelBorderColorClass(
                        task
                      )}`}
                    />
                    <span className="pr-4" />
                  </>
                ) : null}
                {task.title}
              </div>
            </Tooltip>
          </td>
          <td className={`px-4 truncate border-r border-b relative`}>
            {task.taskType ? task.taskType : "-"}
          </td>
          <td
            className={`px-4 truncate border-r border-b relative text-center  ${
              mitigationSimulationInProgress && "animate-pulse"
            }`}
          >
            {task.simulation.riskIntervalResults?.[selectedSimLevel]
              ?.weatherDays || 0}
          </td>
          <td
            className={`px-4 truncate border-r border-b relative text-center  ${
              mitigationSimulationInProgress && "animate-pulse"
            }`}
          >
            {mitigatedTask ? (
              <DifferenceCell
                base={
                  task.simulation.riskIntervalResults?.[selectedSimLevel]
                    ?.weatherDays
                }
                mitigated={
                  mitigatedTask && !mitigationSimulationPending
                    ? mitigatedTask?.riskIntervalResults?.[selectedSimLevel]
                        ?.weatherDays || 0
                    : "?"
                }
              />
            ) : (
              "-"
            )}
          </td>
          <td className="px-4 truncate border-r border-b relative text-center">
            {mitigatedThresholdCategories.length
              ? [
                  ...new Set(
                    mitigatedThresholdCategories.map(({ key }) => (
                      <div
                        key={key}
                        className="w-8 h-8 bg-contain"
                        style={{
                          backgroundImage: `url(${weatherKeyIcons[key]})`,
                        }}
                      ></div>
                    ))
                  ),
                ]
              : "-"}
          </td>
          <td
            className={`px-4 truncate border-r border-b relative text-center`}
          >
            {
              planThresholdCategories.find(({ key }) => key === targetThreshold)
                ?.title
            }
          </td>
          <td
            className={`px-4 truncate border-r border-b relative text-center`}
          >
            {typeof planThreshold !== "undefined" ? (
              <div className="grid gap-1 grid-cols-2 items-baseline">
                <div className="text-end">{planThreshold}</div>
                <div className="text-start text-xl opacity-80">
                  {unitThreshold}
                </div>
              </div>
            ) : (
              "-"
            )}
          </td>
          <td
            className={`px-4 truncate border-r border-b relative text-center`}
          >
            <span className="absolute inset-0">
              {typeof targetThreshold !== "undefined" &&
              typeof mitigationThresholdDisplay[targetThreshold] !==
                "undefined" ? (
                <>
                  <span>{mitigationThresholdDisplay[targetThreshold]}</span>
                  <span className="pl-1 text-xl opacity-80">
                    {unitThreshold}
                  </span>
                </>
              ) : (
                "-"
              )}
            </span>
          </td>
        </tr>
      )}
    </>
  );
};
