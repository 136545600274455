import { FC } from "react";
import { useLocation } from "react-router-dom";
import MenuItem from "./MenuItem";
import { useMenuActions } from "./useMenuActions";
import { hasAccessToAdminPanel } from "helpers/permissions";
import { useAppSelector } from "store";
import { selectRole, selectUserState } from "store/auth";
import {
  isGuest as roleIsGuest,
  UserRole,
} from "@ehabitation/ts-utils/browser";
import { useFeatureFlag, useIsFreeUser } from "hooks";
import config from "config";
import {
  selectCurrentPlanSimulation,
  selectLatestCurrentPlanSimulation,
} from "store/tasks";
import { selectTasksUpdating } from "store/ui";
import { selectCurrentPlanId, selectCurrentSite } from "store/project";

interface SettingsMenuProps {
  handleOpenWBReportModal?: () => void;
  handleOpenPlanImport?: () => void;
  handleOpenCategorise?: () => void;
  handleOpenRiskExport?: () => void;
}

type RoleDisplays = {
  [key in UserRole]: string;
};

const roleDisplays: RoleDisplays = {
  [UserRole.superAdmin]: "Super Admin",
  [UserRole.orgAdmin]: "Org Admin",
  [UserRole.divisionAdmin]: "Division Admin",
  [UserRole.projectAdmin]: "Project Admin",
  [UserRole.planner]: "Planner",
  [UserRole.guest]: "Guest",
};

const SettingsMenu: FC<SettingsMenuProps> = ({
  handleOpenWBReportModal,
  handleOpenPlanImport,
  handleOpenCategorise,
  handleOpenRiskExport,
}) => {
  const location = useLocation();
  const { pathname } = location;

  const {
    handleGoToDashboard,
    handleGoToPlanPage,
    handleGoToSettingsPage,
    handleGoToAdminPage,
    handleLogout,
    handleOpenSaveAsModal,
    handleOpenTornadoModal,
    handleDownloadHistoricalWeatherData,
    handleDownloadTaskExport,
    handleRerunModel,
    handleResetThresholds,
    handleLogCopyKeyState,
  } = useMenuActions();

  const isFreeUser = useIsFreeUser();

  const role = useAppSelector(selectRole);
  const isGuest = roleIsGuest(role!);

  const currentPlanId = useAppSelector(selectCurrentPlanId);
  const currentSite = useAppSelector(selectCurrentSite);

  const isSiteActive = currentSite?.status === "open";

  const { email } = useAppSelector(selectUserState) || {};

  const hasDebugFeatureFlag = useFeatureFlag("debug");
  const hasHistoricFeatureFlag = useFeatureFlag("historic");

  const currentSimulation = useAppSelector(selectCurrentPlanSimulation);
  const latestSimulation = useAppSelector(selectLatestCurrentPlanSimulation);
  const isSimulating =
    !!latestSimulation &&
    !["error", "complete"].includes(latestSimulation?.status || "");

  const tasksUpdating = useAppSelector(selectTasksUpdating);

  return (
    <div
      data-testid="main-menu"
      className="right-24 z-30
     flex flex-col justify-start items-stretch bg-white rounded-2xl border-solid border-[0.1rem] border-gray-800 absolute p-4 top-0 mt-5 w-[30rem]"
    >
      {pathname.startsWith("/projects") && (
        <>
          {!isGuest && (
            <>
              {!pathname.endsWith("/plan") && (
                <MenuItem clickHandler={handleGoToPlanPage} type="plan" />
              )}

              {!pathname.endsWith("/settings") && (
                <MenuItem
                  clickHandler={handleGoToSettingsPage}
                  type="settings"
                />
              )}
              <div className="bg-gray-100 h-[0.1rem] my-6 mx-0"></div>
            </>
          )}

          {pathname.endsWith("/plan") && (
            <>
              {!isGuest && (
                <>
                  <MenuItem
                    clickHandler={handleOpenSaveAsModal}
                    type="save"
                    disabled={isSimulating || tasksUpdating}
                  />
                  <MenuItem clickHandler={handleOpenPlanImport} type="import" />
                  <MenuItem
                    clickHandler={handleOpenCategorise}
                    type="categorise"
                    disabled={isSimulating || tasksUpdating}
                  />
                  <MenuItem
                    clickHandler={handleResetThresholds}
                    type="debug-thresholds"
                    disabled={isSimulating || tasksUpdating}
                  />
                  <div className="bg-gray-100 h-[0.1rem] my-6 mx-0"></div>
                </>
              )}
              <MenuItem
                clickHandler={handleOpenRiskExport}
                type="risk"
                disabled={!currentSimulation || isSimulating || tasksUpdating}
              />
              <MenuItem
                clickHandler={handleOpenWBReportModal}
                type="wb"
                disabled={!isSiteActive}
              />
              <div className="bg-gray-100 h-[0.1rem] my-6 mx-0"></div>
              {hasHistoricFeatureFlag && (
                <MenuItem
                  clickHandler={handleDownloadHistoricalWeatherData}
                  type="historic"
                />
              )}
              <MenuItem
                clickHandler={handleDownloadTaskExport}
                disabled={!currentPlanId}
                type="activity"
              />
              <div className="bg-gray-100 h-[0.1rem] my-6 mx-0"></div>
              <MenuItem clickHandler={handleOpenTornadoModal} type="tornado" />
              <div className="bg-gray-100 h-[0.1rem] my-6 mx-0"></div>
            </>
          )}
        </>
      )}
      {!pathname.includes("/sites") && (
        <MenuItem clickHandler={handleGoToDashboard} type="dashboard" />
      )}
      {!pathname.endsWith("/admin") && hasAccessToAdminPanel(role) && (
        <MenuItem
          clickHandler={handleGoToAdminPage}
          type="admin"
          className="hidden sm:flex"
        />
      )}
      <MenuItem clickHandler={handleLogout} type="logout" />
      <div className="bg-gray-100 h-[0.1rem] my-6 mx-0"></div>
      <div className="flex flex-col justify-start items-center select-none">
        <div className="whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
          {email}
        </div>
        <div className="whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
          {isFreeUser ? "Free User" : roleDisplays[role!]}
        </div>
      </div>
      {hasDebugFeatureFlag && (
        <>
          <div className="bg-gray-100 h-[0.1rem] my-6 mx-0"></div>
          <div className="flex flex-col justify-start items-center select-none">
            <div className="whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
              commit: {config.COMMIT_HASH?.slice(0, 7) || "-"} | project:{" "}
              {config.PROJECT_ID || "local"}
            </div>
          </div>
          <MenuItem clickHandler={handleRerunModel} type="debug-model" />
          <MenuItem clickHandler={handleLogCopyKeyState} type="debug-audit" />
        </>
      )}
    </div>
  );
};

export default SettingsMenu;
