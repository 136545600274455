import { FC } from "react";

import { PieChart, StatBox } from "@ehabitation/ui";
import { getWeatherImpactData } from "Pages/sites/plan/helpers";
import { useMemo } from "react";
import { SimulationResult } from "../../helpers";

interface WeatherImpactBoxProps {
  simulationResult?: SimulationResult;
}

export const WeatherImpactBox: FC<WeatherImpactBoxProps> = ({
  simulationResult,
}) => {
  const weatherImpactData = useMemo(() => {
    return simulationResult
      ? getWeatherImpactData(simulationResult?.taskResults)
      : undefined;
  }, [simulationResult]);

  return (
    <StatBox
      title="Weather Impact Types"
      subTitle=""
      subClassName="p-0"
      className={`md:col-span-1`}
    >
      {weatherImpactData && (
        <PieChart
          id="sidepanel-wit"
          data={weatherImpactData}
          categoryKey="weatherType"
          className="h-[26rem]"
          noChartMessage="There are no weather types causing delays for this plan."
          settings={{
            chartRadius: 70,
            toggleKey: false,
          }}
        />
      )}
    </StatBox>
  );
  
};
