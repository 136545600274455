import {
  CollectionType,
  IPlan,
  ISite,
  ITask,
  transformPlanDoc,
} from "@ehabitation/ts-utils/browser";
import { LoadingType, LoadingWrapper } from "@ehabitation/ui";
import Initialising from "Components/Initialising/Initialising";
import { where } from "firebase/firestore";
import {
  SimulationLevel,
  SimulationPlanResult,
  SimulationRiskDriverResult,
  SimulationTaskResult,
} from "helpers";
import {
  useCollectionQuery,
  usePersonalSites,
  usePlanRiskMatrix,
  useRiskMatrixTopLevelCategories,
  useSetupSubscriptions,
  useSiteOpenPrintPageEvent,
} from "hooks";
import moment from "moment";
import { FC, useEffect, useMemo, useState } from "react";
import { BsSortDown } from "react-icons/bs";
import { useParams } from "react-router-dom";

import { useAppSelector } from "store";
import {
  selectOrganisationProjects,
  selectOrganisationSites,
} from "store/siteManagement";
import { IOrganisationSite } from "types";
import { PrintableView } from "../components/PrintableView";
import { impactMitigationLevels } from "../constants";
import {
  useProcessedTasks,
  useSimulation,
  useSimulationResult,
  useSimulationRiskDriverResult,
  useTaskDocs,
} from "../hooks";
import { RiskDriversRow } from "./RiskDriversRow";
import {
  MilestoneOption,
  calculateHierarchy,
  useRiskDriverMilestones,
  useSimulationRiskDrivers,
} from "./helpers";
import { EndDatesTable } from "../components/EndDatesTable";

const useLocalStorageValue = (key: string) =>
  useMemo(() => localStorage.getItem(key) || "", [key]);

interface SingleMilestoneProps {
  tasks: (ITask & { simulation: SimulationTaskResult })[];
  milestones: (ITask & { simulation: SimulationTaskResult })[];
  simulationPlanResult?: SimulationPlanResult;
  simulationRiskDriverResult: SimulationRiskDriverResult;
  selectedMilestone: MilestoneOption;
  selectedSimLevel: SimulationLevel;
  sortOrder: string;
  showIsCriticalPath: boolean;
}

export const SingleMilestoneRiskDriverPrintViewTable: FC<
  SingleMilestoneProps
> = ({
  tasks,
  milestones,
  simulationPlanResult,
  simulationRiskDriverResult,
  selectedMilestone,
  selectedSimLevel,
  sortOrder,
  showIsCriticalPath,
}) => {
  const { riskDriverViewContext } = useSimulationRiskDrivers(
    tasks,
    milestones,
    selectedMilestone,
    selectedSimLevel,
    sortOrder,
    showIsCriticalPath,
    simulationRiskDriverResult
  );
  if (riskDriverViewContext.visibleTasks.length == 0) {
    return (
      <p className="text-gray-500 md:text-3xl dark:text-gray-400">
        No risk drivers correlation found
      </p>
    );
  }

  return (
    <div>
      <div className="items-center">
        <EndDatesTable
          selectedSimLevel={selectedSimLevel}
          simulationPlanResult={simulationPlanResult}
          selectedMilestoneResult={riskDriverViewContext?.milestoneResult}
          enableSubColumn2={false}
        />
      </div>
      <table className="mb-8 divide-y w-full table-fixed">
        <thead className="bg-gray-50 sticky top-0 z-[8]">
          <tr>
            <th className="w-10"></th>
            <th
              scope="col"
              className="whitespace-nowrap py-3.5 px-4 text-left text-2xl font-semibold text-gray-900"
            >
              Risk Drivers
            </th>
            <th className="w-10"></th>
            <th
              scope="col"
              className="whitespace-nowrap py-3.5 pl-4 pr-8 text-left text-2xl font-semibold text-gray-900"
            >
              <div className="flex justify-between">
                <div className="px-1">Weather Days </div>
                <div>
                  <BsSortDown
                    className={`text-3xl ${
                      sortOrder === "weatherDays" && "text-green-500"
                    }`}
                  />
                </div>
              </div>
            </th>
            <th
              scope="col"
              className="whitespace-nowrap py-3.5 pl-4 pr-8 text-left text-2xl font-semibold text-gray-900"
            >
              <div className="flex justify-between">
                <div className="px-1">Correlation to Planned end date</div>
                <div>
                  <BsSortDown
                    className={`text-3xl ${
                      sortOrder === "correlation" && "text-green-500"
                    }`}
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {riskDriverViewContext.visibleTasks.map((task) => {
            return (
              <RiskDriversRow
                selectedSimLevel={selectedSimLevel}
                key={task.id}
                task={task}
                maxWeatherDays={riskDriverViewContext.maxWeatherDays}
                hierarchy={task.hierarchy}
                correlation={task.correlation}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export const RiskDriversViewPrintable: FC = () => {
  const subscriptionsReady = useSetupSubscriptions();

  const [currentSite, setSite] = useState<IOrganisationSite>();

  const [siteProject, setSiteProject] = useState<string>();
  const organisationSites = useAppSelector(selectOrganisationSites);

  const { siteId, planId } = useParams<{
    siteId: string;
    planId: string;
  }>();
  const showIsCriticalPath =
    useLocalStorageValue(`riskDrivers_isCriticalPath_${siteId}_${planId}`) ===
    "true"
      ? true
      : false;
  useSiteOpenPrintPageEvent("risk-drivers", siteId, planId);

  const { simulation } = useSimulation(siteId!, planId!);
  const { simulationRiskDriverResult, simulationRiskDriverResultLoading } =
    useSimulationRiskDriverResult(simulation);
  const { sites: personalSites = [] } = usePersonalSites();

  const projects = useAppSelector(selectOrganisationProjects);
  const { data: rawPlans = [] } = useCollectionQuery<IPlan>(
    CollectionType.Plans,
    where("site", "==", siteId!)
  );

  const plans = useMemo(() => {
    return (
      rawPlans &&
      rawPlans
        .map((plan) => transformPlanDoc(plan.id, plan))
        .filter(({ status }) => status !== "discarded")
    );
  }, [rawPlans]);

  useEffect(() => {
    setSite(
      organisationSites[siteId!] ||
        personalSites?.find(({ id }: ISite) => id === siteId)
    );
  }, [organisationSites, personalSites]);

  useEffect(() => {
    currentSite && setSiteProject(projects[currentSite.project]?.name);
  }, [projects, currentSite]);

  const basePlan = useMemo(() => {
    if (!plans || !planId) {
      return undefined;
    }
    return plans?.find(({ id }) => planId === id);
  }, [plans, planId]);

  const { simulationResult } = useSimulationResult(simulation);

  const { riskMatrix } = usePlanRiskMatrix(planId);

  const { categories, isLoading: isLoadingCategories } =
    useRiskMatrixTopLevelCategories(riskMatrix?.id);

  const { taskDocs, isLoading: isLoadingBaseTaskDocs } = useTaskDocs(
    siteId!,
    planId
  );

  const {
    tasks,
    milestones,
    isLoading: isLoadingProcessedTasks,
  } = useProcessedTasks(siteId!, categories, taskDocs, simulationResult);

  const selectedSimLevel = useLocalStorageValue(
    `risk_drivers_selectedSimLevel_${siteId}_${planId}`
  ) as SimulationLevel;

  const sortOrder: string = useLocalStorageValue(
    `risk_drivers_sortOrder_${siteId}_${planId}`
  );

  const { milestoneOptions } = useRiskDriverMilestones(
    milestones || [],
    selectedSimLevel,
    simulationRiskDriverResult
  );

  if (!subscriptionsReady)
    return (
      <Initialising>
        <p>Preparing your workspace...</p>
      </Initialising>
    );

  return (
    <PrintableView page="Risk-Drivers">
      <div>
        <div className="px-8 flex">
          <h2 className="text-4xl">Risk Drivers</h2>
          <p className="italic pl-4 pt-1">
            for site <strong>{currentSite?.name}</strong> in project{" "}
            <strong>{siteProject}</strong>, plan{" "}
            <strong>{basePlan?.title}</strong> (
            {basePlan?.id === currentSite?.mainPlanId ? "main" : "draft"})
          </p>
        </div>
        {
          <>
            <div className="px-4 pt-4">
              <div className="pl-4 min-h-[36rem] flex-auto overflow-auto">
                <LoadingWrapper
                  message="Loading tasks..."
                  type={LoadingType.full}
                  loading={
                    isLoadingBaseTaskDocs ||
                    simulationRiskDriverResultLoading ||
                    isLoadingProcessedTasks
                  }
                  white
                >
                  {[
                    {
                      value: "",
                      label: "Project End Date",
                      startDelay: undefined,
                    },
                    ...milestoneOptions,
                  ].map((milestone) => {
                    return (
                      <div key={milestone.value}>
                        <h1>{milestone.label}</h1>
                        <SingleMilestoneRiskDriverPrintViewTable
                          tasks={tasks || []}
                          milestones={milestones || []}
                          simulationPlanResult={simulationResult?.planResults}
                          simulationRiskDriverResult={
                            simulationRiskDriverResult!
                          }
                          selectedSimLevel={selectedSimLevel}
                          sortOrder={sortOrder}
                          selectedMilestone={milestone}
                          showIsCriticalPath={showIsCriticalPath}
                        ></SingleMilestoneRiskDriverPrintViewTable>
                        <hr />
                      </div>
                    );
                  })}
                </LoadingWrapper>
              </div>
            </div>
          </>
        }
      </div>
    </PrintableView>
  );
};
