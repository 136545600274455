import {
  ForecastTable,
  ForecastDay,
  ForecastHourly,
  threeDigitsMax,
  calculateBrokens,
  Accordion,
} from "@ehabitation/ui";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { db } from "firebaseConfig";
import IBMLogo from "assets/images/The_Weather_Company_IBM.svg";
import ECMWFLogo from "assets/images/ECMWF_logo.svg";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  orderBy,
  limit,
  getDoc,
} from "firebase/firestore";

import {
  ISite,
  WeatherKey,
  Unit,
  WeatherThresholds,
  warningTemperature,
  warningWind,
  warningPrecipitation,
  IRiskMatrix,
  ITaskCategory,
  ITask,
  IForecast,
  getTimeRangeTasks,
  transformForecastDoc,
  IHourlyForecast,
  transformDailyForecastDoc,
  transformHourlyForecastDoc,
  IForecastReading,
  IDailyForecast,
} from "@ehabitation/ts-utils/browser";
import { Spinner } from "Components/Spinner";
import { weatherCodeIconMap } from "Pages/admin/constants";
import TaskDetail from "Components/PlanImport/TaskDetail/TaskDetail";
import { Tooltip } from "@material-ui/core";
import moment from "moment";

interface CategoryCount {
  category: { id: string; name: string; thresholds: WeatherThresholds };
  count: number;
}

const thresholdDailyForecastKeyMap: Partial<Record<WeatherKey, string>> = {
  minTemp: "minTemp",
  maxTemp: "maxTemp",
  wind: "maxWindSpeed",
  hourlyRainAcc: "hourlyRainAcc",
  dailyRainAcc: "dailyRainAcc",
};

export const getCategoryImpactCounts = ({
  riskMatrix,
  dailyForecasts,
}: {
  riskMatrix:
    | { id: string; name: string; thresholds: WeatherThresholds }[]
    | undefined;
  dailyForecasts: IDailyForecast[] | undefined;
}) => {
  if (riskMatrix && dailyForecasts) {
    const categoryCount = riskMatrix
      .map((category) => {
        const brokens = Object.values(dailyForecasts)
          .map((dayData) => {
            const { brokenCount, warningCount } = calculateBrokens(
              category.thresholds,
              dayData
            );
            return brokenCount === -1 ? -1 : brokenCount + warningCount;
          })
          .filter((b) => b !== -1);
        return {
          category: category,
          count: brokens.reduce(
            (accumulator: number, curr: number) => accumulator + curr,
            0
          ),
        };
      })
      .filter((r) => r !== null);
    return categoryCount as CategoryCount[];
  }
  return [];
};

export const thresholdCategories = [
  {
    title: "Min Temp",
    key: WeatherKey.minTemp,
  },
  {
    title: "Max Temp",
    key: WeatherKey.maxTemp,
  },
  {
    title: "24hr Rain",
    key: WeatherKey.dailyRainAcc,
  },
  {
    title: "Hrly Rain",
    key: WeatherKey.hourlyRainAcc,
  },
  {
    title: "Wind",
    key: WeatherKey.wind,
  },
  {
    title: "Wave Hght",
    key: WeatherKey.waveHeight,
  },
  {
    title: "Gust",
    key: WeatherKey.windGusts,
  },
  {
    title: "24hr Snow",
    key: WeatherKey.snowfall24Hour,
  },
  {
    title: "Hrly Snow",
    key: WeatherKey.snowfall,
  },
  {
    title: "Visibility",
    key: WeatherKey.visibility,
  },
];

export const thresholdUnits: Record<WeatherKey, Unit> = {
  minTemp: "C",
  maxTemp: "C",
  wind: "m/s",
  dailyRainAcc: "mm",
  hourlyRainAcc: "mm/hr",
  snowfall: "mm",
  snowfall24Hour: "mm",
  waveHeight: "m",
  windGusts: "m/s",
  visibility: "km",
};

const todayMidnight = new Date();
todayMidnight.setHours(0, 0, 0, 0);

export const useForecastData = (forecastSourceIds?: string[]) => {
  const [forecast, setForecast] = useState<IForecast>();
  const [dailyForecasts, setDailyForecasts] = useState<IDailyForecast[]>();
  const [hourlyForecasts, setHourlyForecasts] = useState<IHourlyForecast[]>();
  const [sourceType, setSourceType] = useState<string[]>();
  const forecastSourceId = forecastSourceIds?.[0];

  const getDocuments = async (sources: string[]) => {
    return await Promise.all(
      sources.map(async (source) => {
        const forecastDoc = await getDoc(
          doc(collection(db, "sources"), source)
        );
        if (forecastDoc.exists()) {
          return forecastDoc.data()?.type;
        }
      })
    );
  };

  useEffect(() => {
    if (forecastSourceIds) {
      getDocuments(forecastSourceIds).then((sources) => setSourceType(sources));
    } else setSourceType(undefined);
  }, [forecastSourceIds]);

  useEffect(() => {
    setDailyForecasts(undefined);
    setHourlyForecasts(undefined);
    setForecast(undefined);
    if (forecastSourceId) {
      const forecastQuery = query(
        collection(db, "IBMForecasts"),
        where("sourceRef", "==", forecastSourceId),
        orderBy("createdAt", "desc"),
        limit(1)
      );
      const unsubscribe = onSnapshot(forecastQuery, (snap) => {
        if (snap.empty) {
          setForecast(undefined);
        } else {
          setForecast(
            transformForecastDoc(snap.docs[0].id, snap.docs[0].data())
          );
        }
      });

      return () => unsubscribe();
    }
  }, [forecastSourceId]);

  useEffect(() => {
    if (forecast) {
      const dailyForecastsQuery = query(
        collection(db, `IBMForecasts/${forecast.id}/dailyForecasts`),
        orderBy("dateReference", "asc")
      );

      const unsubscribeDaily = onSnapshot(dailyForecastsQuery, (snap) => {
        if (snap.empty) {
          setDailyForecasts(undefined);
        } else {
          const dailyForecasts = snap.docs.map((doc) => {
            const data = doc.data();
            return transformDailyForecastDoc(doc.id, data);
          });
          setDailyForecasts(dailyForecasts);
          // Get forecast figures from hourly & daily sub collections
        }
      });
      const forecastsQuery = query(
        collection(db, `IBMForecasts/${forecast.id}/hourlyForecasts`),
        orderBy("dateReference", "asc")
      );

      const unsubscribeHourly = onSnapshot(forecastsQuery, (snap) => {
        if (snap.empty) {
          setHourlyForecasts(undefined);
        } else {
          const hourlyForecasts = snap.docs
            .map((doc) => {
              const data = doc.data();
              const hourlyForecast = transformHourlyForecastDoc(doc.id, data);
              if (hourlyForecast.dateReference < todayMidnight) {
                return false;
              }
              return hourlyForecast;
            })
            .filter(Boolean) as IHourlyForecast[];
          setHourlyForecasts(hourlyForecasts);
          // Get forecast figures from hourly & daily sub collections
        }
      });
      return () => {
        unsubscribeDaily();
        unsubscribeHourly();
      };
    }
  }, [forecast?.id]);

  return { forecast, dailyForecasts, hourlyForecasts, sourceType };
};

export const useTasksInWindow = (
  allTasks?: ITask[],
  dailyForecasts?: IDailyForecast[]
) => {
  const leafTasks = useMemo(() => {
    return allTasks?.filter(
      (task) => !task.WBS && !task.milestone && !task.levelOfEffort
    );
  }, [allTasks]);

  const tasksInWindow = useMemo(() => {
    return leafTasks && dailyForecasts
      ? getTimeRangeTasks(leafTasks, {
          start: dailyForecasts[0].dateReference,
          end: dailyForecasts[dailyForecasts.length - 1].dateReference,
        }).sort(
          ({ start: startA }, { start: startB }) =>
            startA.getTime() - startB.getTime()
        )
      : undefined;
  }, [leafTasks, dailyForecasts]);

  return { tasksInWindow, leafTasks };
};

export const useTaskCategories = (
  categories: ITaskCategory[],
  dailyForecasts?: IDailyForecast[],
  leafTasks?: ITask[],
  tasksInWindow?: ITask[]
) => {
  const activeCategories = useMemo(() => {
    if (!leafTasks) return categories;
    const activeTaskCategoryNames = leafTasks.reduce((acc, task) => {
      if (task.taskCategory?.selectedName) {
        acc.add(task.taskCategory.selectedName);
      }
      return acc;
    }, new Set<string>());
    return categories.filter(({ name }) => activeTaskCategoryNames.has(name));
  }, [leafTasks, categories]);

  const [upcomingCategories, untaskedCategories] = useMemo(() => {
    const categoryNames = new Set();
    const upcomingCategories: ITaskCategory[] = [];
    const otherCategories: ITaskCategory[] = [];
    activeCategories
      .sort((a, b) => {
        // sort by id so deduplication is consistent
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        } else {
          return 0;
        }
      })
      .forEach((category) => {
        if (categoryNames.has(category.name)) {
          return false;
        }
        categoryNames.add(category.name);
        if (
          tasksInWindow &&
          tasksInWindow.some(
            ({ taskCategory }) => taskCategory?.selectedName === category.name
          )
        ) {
          upcomingCategories.push(category);
        } else {
          otherCategories.push(category);
        }
      });
    return [upcomingCategories, otherCategories];
  }, [activeCategories, tasksInWindow]);

  const orderedUpcomingCategoryImpactCounts = useMemo(
    () =>
      getCategoryImpactCounts({
        riskMatrix: upcomingCategories,
        dailyForecasts,
      }).sort((a, b) => {
        if (a.count > b.count) return -1;
        if (a.count < b.count) return 1;
        return a.category.name
          .toLowerCase()
          .localeCompare(b.category.name.toLowerCase());
      }),
    [upcomingCategories, dailyForecasts]
  );
  const orderedUntaskedCategoryImpactCounts = useMemo(
    () =>
      getCategoryImpactCounts({
        riskMatrix: untaskedCategories,
        dailyForecasts,
      }).sort((a, b) => {
        if (a.count > b.count) return -1;
        if (a.count < b.count) return 1;
        return a.category.name
          .toLowerCase()
          .localeCompare(b.category.name.toLowerCase());
      }),
    [untaskedCategories, dailyForecasts]
  );

  return {
    orderedUpcomingCategoryImpactCounts,
    orderedUntaskedCategoryImpactCounts,
    upcomingCategories,
    untaskedCategories,
  };
};

const Forecast: FC<{
  riskMatrix: IRiskMatrix;
  categories: ITaskCategory[];
  forecastSourceIds?: string[];
  allTasks?: ITask[];
  siteId: string;
  planId?: string;
}> = ({ forecastSourceIds, categories, allTasks, siteId, planId }) => {
  const [selectedTaskId, setSelectedTaskId] = useState<string>();
  const [selectedCategoryName, setSelectedCategoryName] = useState<
    string | undefined
  >();
  const [selectedForecastIndex, setSelectedForecastIndex] = useState(0);
  const selectedDayRef = useRef<HTMLDivElement>(null);

  const { forecast, dailyForecasts, hourlyForecasts, sourceType } =
    useForecastData(forecastSourceIds);

  const selectedTask = useMemo(() => {
    return allTasks?.find(({ id }) => id === selectedTaskId);
  }, [allTasks, selectedTaskId]);

  const { tasksInWindow, leafTasks } = useTasksInWindow(
    allTasks,
    dailyForecasts
  );

  const {
    orderedUpcomingCategoryImpactCounts,
    orderedUntaskedCategoryImpactCounts,
    upcomingCategories,
    untaskedCategories,
  } = useTaskCategories(categories, dailyForecasts, leafTasks, tasksInWindow);

  const activeCategory = useMemo(() => {
    const activeCategoryName =
      selectedCategoryName || selectedTask?.taskCategory?.selectedName;
    return [...upcomingCategories, ...untaskedCategories].find(
      (category) => category.name === activeCategoryName
    );
  }, [
    upcomingCategories,
    untaskedCategories,
    selectedCategoryName,
    selectedTask,
  ]);

  useEffect(() => {
    if (orderedUpcomingCategoryImpactCounts?.length) {
      setSelectedCategoryName(
        orderedUpcomingCategoryImpactCounts[0].category.name
      );
    } else if (orderedUntaskedCategoryImpactCounts?.length) {
      setSelectedCategoryName(
        orderedUntaskedCategoryImpactCounts[0].category.name
      );
    }
  }, [
    orderedUpcomingCategoryImpactCounts,
    orderedUntaskedCategoryImpactCounts,
  ]);

  useEffect(() => {
    localStorage.setItem(
      `forecast_selectedCategoryName_${siteId}_${planId || 0}}`,
      `${selectedCategoryName}`
    );
  }, [selectedCategoryName]);
  useEffect(() => {
    localStorage.setItem(
      `forecast_selectedTaskId_${siteId}_${planId || 0}}`,
      `${selectedTaskId}`
    );
  }, [selectedTaskId]);

  return dailyForecasts && hourlyForecasts ? (
    <>
      <div className="min-h-0 overflow-hidden grid sm:grid-cols-4 gap-4 sm:px-4 max-w-[130rem]">
        <ForecastTable
          className="col-span-3 min-h-0"
          dailyForecasts={dailyForecasts}
          highlightIndex={selectedForecastIndex}
          handleDayClick={(index: number) => {
            setSelectedForecastIndex(index);
          }}
          thresholds={activeCategory?.thresholds}
          error=""
          forecastSiteId={forecast?.id || null}
          weatherCodeIconMap={weatherCodeIconMap}
          orderedUpcomingCategoryImpactCounts={
            orderedUpcomingCategoryImpactCounts
          }
          orderedUntaskedCategoryImpactCounts={
            orderedUntaskedCategoryImpactCounts
          }
          selectedCategoryName={selectedCategoryName}
          setSelectedCategoryName={setSelectedCategoryName}
          selectedTaskId={selectedTaskId}
          setSelectedTaskId={setSelectedTaskId}
          selectedDayRef={selectedDayRef}
          tasks={tasksInWindow}
        />
        <div className="hidden sm:flex py-2 col-span-1 min-h-0 flex-col">
          {activeCategory ? (
            <TaskDetail>
              {selectedTask ? (
                <div>
                  <TaskDetail.TaskTitle
                    title={selectedTask.title}
                    task={selectedTask}
                    taskId={selectedTask.id}
                    footer={
                      <div className="rounded-t-lg border border-b-0 bg-gray-50">
                        <Accordion
                          headerClass="flex items-center gap-1 w-full hover:bg-gray-100"
                          title={({ open }) => (
                            <div className="flex-grow flex justify-between items-center pr-2 gap-2">
                              <p>
                                {selectedTask.WBS ? (
                                  <span className="font-bold">WBS</span>
                                ) : selectedTask.milestone ? (
                                  <span className="text-blue-500 tracking-wider">
                                    Milestone
                                  </span>
                                ) : selectedTask.levelOfEffort ? (
                                  <span className="text-blue-500 tracking-wider">
                                    Level of Effort
                                  </span>
                                ) : (
                                  "Task"
                                )}
                              </p>
                              {open ? null : (
                                <p className="truncate text-gray-500">
                                  {selectedTask.milestone
                                    ? selectedTask.start.toLocaleDateString()
                                    : `${
                                        moment(selectedTask.end).diff(
                                          selectedTask.start,
                                          "days"
                                        ) + 1
                                      } day${
                                        moment(selectedTask.end).diff(
                                          selectedTask.start,
                                          "days"
                                        ) +
                                          1 ===
                                        1
                                          ? ""
                                          : "s"
                                      }`}
                                </p>
                              )}
                            </div>
                          )}
                        >
                          <div className="flex flex-col gap-6 pb-6 px-4">
                            <div className="flex flex-col">
                              {selectedTask.milestone ? null : (
                                <div className="self-stretch flex justify-between">
                                  <p>
                                    {selectedTask.start.toLocaleDateString()}
                                  </p>{" "}
                                  <p>{selectedTask.end.toLocaleDateString()}</p>
                                </div>
                              )}
                              <div className="self-stretch border border-gray-400 border-x-2 border-x-gray-600 bg-blue-300 mx-12 grid place-items-center">
                                {selectedTask.milestone
                                  ? selectedTask.start.toLocaleDateString()
                                  : `${
                                      moment(selectedTask.end).diff(
                                        selectedTask.start,
                                        "days"
                                      ) + 1
                                    } day${
                                      moment(selectedTask.end).diff(
                                        selectedTask.start,
                                        "days"
                                      ) === 0
                                        ? ""
                                        : "s"
                                    }`}
                              </div>
                            </div>
                            <div className="border-b border-gray-400 flex items-center justify-between gap-4 leading-6">
                              <p>Object Id</p>
                              <p>{selectedTask.objectId}</p>
                            </div>
                            {selectedTask.categoryCode ? (
                              <div className="border-b border-gray-400 flex items-center justify-between gap-4 leading-6">
                                <p>Category Code</p>
                                <p>{selectedTask.categoryCode}</p>
                              </div>
                            ) : null}
                            {selectedTask.guid ? (
                              <div className="border-b border-gray-400 flex items-center justify-between gap-4 leading-6">
                                <p>GUID</p>
                                <p className="text-xl">{selectedTask.guid}</p>
                              </div>
                            ) : null}
                          </div>
                        </Accordion>
                      </div>
                    }
                  />
                </div>
              ) : selectedCategoryName ? (
                <TaskDetail.TaskTitle
                  title={activeCategory?.name}
                  taskId={selectedCategoryName}
                />
              ) : null}
              <div className="flex-grow flex-shrink overflow-y-auto overflow-x-hidden p-2 border-x border-x-gray-200 flex flex-col gap-2 border-b rounded-b-lg">
                <div className="rounded-lg border border-gray-200">
                  <Accordion
                    defaultOpen
                    headerClass="flex items-center gap-1 w-full hover:opacity-70  hover:bg-gray-100"
                    title={({ open }) => (
                      <div className="flex-grow flex justify-between items-center pr-2 gap-2 overflow-hidden">
                        <h4 className="text-2xl leading-10">Thresholds</h4>
                        {open ? null : (
                          <p className="truncate text-gray-500">
                            {
                              Object.keys(activeCategory?.thresholds || {})
                                .length
                            }
                          </p>
                        )}
                      </div>
                    )}
                  >
                    <>
                      {activeCategory?.name && !selectedCategoryName ? (
                        <div className="px-4 pt-2 font-bold truncate">
                          {activeCategory.name}
                        </div>
                      ) : null}
                      {activeCategory.name &&
                      activeCategory.name !== "No Weather Impact" ? (
                        <div
                          className="grid grid-cols-1 xl:grid-cols-2 gap-x-4 gap-y-2 auto-rows-fr grid-flow-row-dense p-2"
                        >
                          {thresholdCategories?.length
                            ? thresholdCategories.map(({ title, key }) => {
                                const threshold =
                                  activeCategory?.thresholds?.[key];
                                if (typeof threshold !== "number") {
                                  return null;
                                }
                                const thresholdIsMin = key.includes("min");
                                const forecastKey =
                                  thresholdDailyForecastKeyMap[
                                    key
                                  ] as keyof IDailyForecast;
                                const selectedForecast =
                                  dailyForecasts[selectedForecastIndex];
                                const forecastHasThreshold =
                                  forecastKey &&
                                  typeof selectedForecast[forecastKey] !==
                                    "undefined";
                                let thresholdBroken = false;
                                let thresholdWarning = false;
                                if (forecastHasThreshold) {
                                  const forecastValue = (
                                    selectedForecast[
                                      forecastKey
                                    ] as IForecastReading
                                  ).value;
                                  if (forecastValue !== null) {
                                    thresholdBroken = thresholdIsMin
                                      ? forecastValue < threshold
                                      : forecastValue > threshold;
                                    if (!thresholdBroken) {
                                      thresholdWarning =
                                        key === "minTemp"
                                          ? forecastValue <
                                            threshold + warningTemperature
                                          : key === "maxTemp"
                                          ? forecastValue >
                                            (threshold > warningTemperature
                                              ? threshold - warningTemperature
                                              : 0)
                                          : forecastKey === "maxWindSpeed"
                                          ? forecastValue >
                                            (threshold > warningWind
                                              ? threshold - warningWind
                                              : 0)
                                          : key === "dailyRainAcc" ||
                                            key === "hourlyRainAcc"
                                          ? forecastValue >
                                            (threshold > warningPrecipitation
                                              ? threshold - warningPrecipitation
                                              : 0)
                                          : false;
                                    }
                                  }
                                }
                                return (
                                  <div
                                    key={key}
                                    className={`min-w-[10rem] max-w-[16rem] text-base p-2 pb-1 flex flex-row items-end gap-1 justify-between border-b-2 ${
                                      forecastHasThreshold
                                        ? thresholdBroken
                                          ? "border-rose-200 bg-rose-200 rounded-lg"
                                          : thresholdWarning
                                          ? "border-amber-200 bg-amber-200 rounded-lg"
                                          : "border-[#1CEAA0]"
                                        : "border-gray-200"
                                    }`}
                                  >
                                    <div className="p-1 font-[500]">
                                      {title}
                                    </div>
                                    <div
                                      className={`p-1 whitespace-nowrap pr-12 relative`}
                                    >
                                      {typeof threshold === "number"
                                        ? threeDigitsMax(threshold, true)
                                        : "-"}{" "}
                                      <span
                                        className={`text-xl absolute left-[calc(100%-2.5rem)] top-0`}
                                      >
                                        {thresholdUnits[key]}
                                      </span>
                                    </div>
                                  </div>
                                );
                              })
                            : "No Upcoming Tasks"}
                        </div>
                      ) : null}
                    </>
                  </Accordion>
                </div>
              </div>
            </TaskDetail>
          ) : null}
          <div className="flex-grow grid place-content-center pt-4">
            <div className="flex justify-between gap-10 items-center">
              {sourceType && sourceType.length > 0 ? (
                sourceType?.map((type) =>
                  type === "IBM" ? (
                    <Tooltip
                      key={type}
                      arrow
                      title={
                        <span className="text-xl">
                          Data sourced from IBM Weather Company
                        </span>
                      }
                    >
                      <img src={IBMLogo} className="h-32 ml-auto" />
                    </Tooltip>
                  ) : type === "ECMWF" ? (
                    <Tooltip
                      key={type}
                      arrow
                      title={
                        <span className="text-xl">
                          Data sourced from ECMWF (European Centre for
                          Medium-Range Weather Forecasts)
                        </span>
                      }
                    >
                      <img src={ECMWFLogo} className="h-16 ml-auto" />
                    </Tooltip>
                  ) : (
                    <></>
                  )
                )
              ) : (
                <div>Not Available</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-4 p-2 pb-0 sm:p-4 sm:pb-0">
        <div
          onClick={() =>
            setSelectedForecastIndex(
              dailyForecasts[selectedForecastIndex - 1]
                ? selectedForecastIndex - 1
                : dailyForecasts.length - 1
            )
          }
          className="hidden md:grid hover:cursor-pointer text-gray-700 bg-[#d9f8ec] hover:bg-[#cfebe0] rounded-lg transition-colors place-content-center"
          role="button"
          aria-label="previous day"
        >
          <div className="text-4xl">
            <BiChevronLeft />
          </div>
        </div>
        <div className="md:w-[38rem] grid place-content-center">
          <ForecastDay
            data={dailyForecasts[selectedForecastIndex]}
            weatherMap={weatherCodeIconMap}
            thresholds={activeCategory?.thresholds}
          />
        </div>
        <div className="min-w-0">
          {hourlyForecasts.length ? (
            <ForecastHourly
              hourlyForecasts={hourlyForecasts}
              selectedForecastIndex={selectedForecastIndex}
              thresholds={activeCategory?.thresholds}
            />
          ) : (
            <p className="text-red-400 p-8">Hourly forecast failed to load.</p>
          )}
        </div>
        <div
          className="hidden md:grid hover:cursor-pointer text-gray-700 bg-[#d9f8ec] hover:bg-[#cfebe0] rounded-lg transition-colors place-content-center ml-8"
          onClick={() =>
            setSelectedForecastIndex(
              dailyForecasts[selectedForecastIndex + 1]
                ? selectedForecastIndex + 1
                : 0
            )
          }
          role="button"
          aria-label="next day"
        >
          <div className="text-4xl">
            <BiChevronRight />
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="w-full grid place-items-center h-72">
      <Spinner />
    </div>
  );
};

export const WeatherView: FC<{
  site: ISite;
  riskMatrix: IRiskMatrix;
  categories: ITaskCategory[];
  setPrintableLink: (id: string) => void;
  setHelpLink: (id: string) => void;
  planId?: string;
  allTasks?: ITask[];
}> = ({ site, riskMatrix, categories, setPrintableLink, setHelpLink, planId, allTasks }) => {
  useEffect(() => {
    planId
      ? setPrintableLink(`/print/${site.id}/${planId}/weather`)
      : setPrintableLink(`/print/${site.id}/weather`);
    return () => setPrintableLink("");
  }, [site.id, planId]);
  
  useEffect(() => {
    setHelpLink('https://ehab.co/docs/how-to-use-the-forecast-dashboard/');
    return () => setHelpLink("");
  }, []);

  return (
    <>
      <Forecast
        allTasks={allTasks}
        forecastSourceIds={site?.forecastInUse}
        riskMatrix={riskMatrix}
        categories={categories}
        siteId={site.id}
        planId={planId}
      />
    </>
  );
};
