import { FC, useState } from "react";
import Admin from "Pages/admin/AdminPage";
import Auth from "Routers/Auth";
import Site from "Routers/Site";
import NotFound from "Routers/NotFound";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ProjectRoutes from "Routers/ProjectRoutes";
import Header from "Components/Header/Header";
import SuperAdminPanel from "Pages/super/SuperAdminPanel";
import { useAppSelector } from "store";
import { selectRole, selectIsLoggedIn, selectUserState } from "store/auth";
import AuthAction from "Routers/AuthAction";
import UnsubscribePage from "Pages/unsubscribe/Unsubscribe";
import { UserRole } from "@ehabitation/ts-utils/browser";
import { WeatherViewPrintable } from "Pages/sites/weather/WeatherViewPrintable";
import { MitigationsViewPrintable } from "Pages/sites/mitigations/MitigationsViewPrintable";
import { TraViewPrintable } from "Pages/sites/tra-qsra/TraViewPrintable";
import { VarianceViewPrintable } from "Pages/sites/variance/VarianceViewPrintable";
import CreateNewSite from "Pages/sites/create";
import { RiskDriversViewPrintable } from "Pages/sites/risk-drivers/RiskDriversViewPrintable";
import EnrollMFAPage from "Components/Auth/EnrollMFA";
import { User } from "firebase/auth";

const Main: FC<{ mfaUser?: User }> = ({ mfaUser }) => {
  const [wbModalOpen, setWBModalOpen] = useState<boolean>(false);
  const [planImportOpen, setPlanImportOpen] = useState<boolean>(false);
  const [categoriseOpen, setCategoriseOpen] = useState<boolean>(false);
  const [riskExportOpen, setRiskExportOpen] = useState<boolean>(false);

  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  const role = useAppSelector(selectRole);

  const location = useLocation();
  const userState = useAppSelector(selectUserState);

  if (!isLoggedIn && !!!mfaUser) {
    return <Auth />;
  } else if (mfaUser) {
    return <EnrollMFAPage mfaUser={mfaUser!} />;
  } else if (role === UserRole.superAdmin) {
    return (
      <>
        <Header />
        <Routes>
          <Route path="dashboard" element={<Navigate to="/" />} />
          <Route element={<SuperAdminPanel />} path="super/*" />
          <Route element={<Navigate to="/super" />} path="" />
          <Route path="auth_action/*" element={<AuthAction />} />
          <Route path="unsubscribe" element={<UnsubscribePage />} />
          <Route element={<NotFound />} path="*" />
        </Routes>
      </>
    );
  } else {
    if (location.state?.from) {
      return <Navigate to={location.state.from} />;
    }
  }

  return (
    <>
      {!location.pathname.includes("print/") && (
        <Header
          handleOpenWBReportModal={() => setWBModalOpen(true)}
          handleOpenPlanImport={() => setPlanImportOpen(true)}
          handleOpenCategorise={() => setCategoriseOpen(true)}
          handleOpenRiskExport={() => setRiskExportOpen(true)}
        />
      )}

      <div className="grow overflow-auto">
        <Routes>
          <Route path="dashboard" element={<Navigate to="/" />} />
          <Route path="register" element={<Navigate to="/" />} />
          <Route path="login" element={<Navigate to="/" />} />
          <Route
            path=""
            element={
              <Navigate
                to={userState.siteId ? `sites/${userState.siteId}` : "sites/"}
              />
            }
          />
          <Route path="auth_action/*" element={<AuthAction />} />
          <Route path="unsubscribe" element={<UnsubscribePage />} />

          <Route path="sites">
            <Route index element={<Site />} />
            <Route element={<CreateNewSite />} path="new" />
            <Route element={<Site />} path=":siteId/*" />
          </Route>

          <Route path="print">
            <Route element={<WeatherViewPrintable />} path=":siteId/weather" />
            <Route
              element={<WeatherViewPrintable />}
              path=":siteId/:planId/weather"
            />
            {/* <Route
              element={<PlanViewPrintable />}
              path=":siteId/:planId/:mitigationPlanId/plan"
            /> */}
            <Route
              element={<TraViewPrintable />}
              path=":siteId/:planId/tra-qsra"
            />
            <Route
              element={<MitigationsViewPrintable />}
              path=":siteId/:planId/:mitigationPlanId/mitigations"
            />
            <Route
              element={<VarianceViewPrintable />}
              path=":siteId/:planId/:variancePlanId/variance"
            />
            <Route
              element={<RiskDriversViewPrintable />}
              path=":siteId/:planId/risk-drivers"
            />
          </Route>

          <Route
            element={
              <ProjectRoutes
                wbModalOpen={wbModalOpen}
                setWBModalOpen={setWBModalOpen}
                categoriseOpen={categoriseOpen}
                setCategoriseOpen={setCategoriseOpen}
                planImportOpen={planImportOpen}
                setPlanImportOpen={setPlanImportOpen}
                riskExportOpen={riskExportOpen}
                setRiskExportOpen={setRiskExportOpen}
              />
            }
            path="projects/:projectId/*"
          />

          <Route element={<Admin />} path="admin" />

          <Route element={<NotFound />} path="*" />
        </Routes>
      </div>
    </>
  );
};

export default Main;
