//React
import { useRef } from "react";
//Store
import { useAppSelector, useAppDispatch } from "store";
import { selectHolidays } from "store/calendar";
//Types
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { addHolidayDay, removeHolidayDay } from "./thunks";

export const useHolidays = () => {
  const dispatch = useAppDispatch();

  const holidays = useAppSelector(selectHolidays);

  const dateInputRef = useRef<HTMLInputElement | null>(null);

  const handleAddHoliday = (selectedDate: MaterialUiPickersDate) => {
    dispatch(addHolidayDay(selectedDate));

    if (!dateInputRef.current) return;
    dateInputRef.current.value = ""; //Clear Input
  };

  const handleDeleteHoliday = async (holiday: string) => {
    dispatch(removeHolidayDay(holiday));
  };

  return {
    holidays,
    handleAddHoliday,
    handleDeleteHoliday,
    dateInputRef,
  };
};
