import React from "react";
import CalendarSettings from "Components/Settings/Calendar/CalendarContainer";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "store";
import { selectCurrentSite } from "store/project";

export enum SettingsMenuOption {
  Calendar = "Calendar",
  CategoryThresholds = "Category Thresholds",
  CompensationEvents = "Compensation Events",
}

export const settingsMenuMap: Record<SettingsMenuOption, React.FC> = {
  [SettingsMenuOption.Calendar]: CalendarSettings,
  [SettingsMenuOption.CategoryThresholds]: () => {
    const site = useAppSelector(selectCurrentSite);
    return site ? (
      <Navigate to={`/sites/${site.id}/settings/thresholds`} />
    ) : null;
  },
  [SettingsMenuOption.CompensationEvents]: () => {
    const site = useAppSelector(selectCurrentSite);
    return site ? (
      <Navigate to={`/sites/${site.id}/settings/compensation-events`} />
    ) : null;
  },
};
