import { useMemo } from "react";
import { useAppSelector } from "store";
import { selectAllTasksWithoutWBS } from "store/tasks";
import { IRiskIntervals } from "types";
import { useTornadoChart } from "./useTornadoChart";
import { ISite, ITask } from "@ehabitation/ts-utils/browser";
import { SimulationTaskResult } from "helpers";

const useTasksWithTitlesWithoutWBS = (
  tasksWithResults?: (ITask & { simulation: SimulationTaskResult })[]
) => {
  useMemo(
    () => tasksWithResults?.filter((task) => !task.WBS && task.title),
    [tasksWithResults]
  );
  const tasksWithoutWBS = useAppSelector(selectAllTasksWithoutWBS);

  if (tasksWithResults) {
    return tasksWithResults;
  }

  return tasksWithoutWBS;
};

const useRiskIntervals = (site: ISite) => {
  return useMemo(() => {
    const DEFAULT_RISK_INTERVALS = {
      low: 20,
      medium: 40,
      high: 80,
    };

    if (!site?.riskIntervals) return DEFAULT_RISK_INTERVALS;

    const riskIntervalArray = site.riskIntervals;

    if (riskIntervalArray && riskIntervalArray.length === 3) {
      return {
        low: riskIntervalArray[0],
        medium: riskIntervalArray[1],
        high: riskIntervalArray[2],
      };
    }

    return DEFAULT_RISK_INTERVALS;
  }, [site]);
};

const estimateChartDataSize = (
  tasks: any[] | null,
  siteRiskIntervals: IRiskIntervals
) => {
  const taskRiskSize: any[] = [];
  if (!tasks) return "100%";
  tasks.forEach((task) => {
    if (!task.risks) return taskRiskSize;
    const riskObject = Object.assign({}, ...task.risks);

    if (
      siteRiskIntervals.low in riskObject ||
      siteRiskIntervals.medium in riskObject ||
      siteRiskIntervals.high in riskObject
    ) {
      taskRiskSize.push(task);
    }
  });

  if (taskRiskSize.length < 10) {
    return "100%";
  } else {
    return `${taskRiskSize.length * 10}%`;
  }
};

const TornadoChart = ({
  site,
  tasksWithResults,
}: {
  site: ISite;
  tasksWithResults?: (ITask & { simulation: SimulationTaskResult })[];
}) => {
  const tasksWithoutWBS = useTasksWithTitlesWithoutWBS(tasksWithResults);
  const siteRiskIntervals = useRiskIntervals(site);

  // Calculates the height of the chart based on a number of tasks with Risk
  const calculatedChartHeight = estimateChartDataSize(
    tasksWithoutWBS,
    siteRiskIntervals
  );

  useTornadoChart(tasksWithoutWBS, siteRiskIntervals);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div id="tornadocharttoolsdiv"></div>
      <div
        style={{
          height: calculatedChartHeight,
        }}
        id="tornadochartdiv"
        data-testid="tornado-chart"
      ></div>
    </div>
  );
};

export default TornadoChart;
