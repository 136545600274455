import styled from "styled-components";
import { spacing, grays, font, flex, fontSize, replaceOutline } from "styles";

export const StyledContent = styled.div`
  height: 100%;
  padding-right: ${spacing.l};

  > h1 {
    ${font("title")};
    font-weight: 300;
  }

  h2,
  p {
    ${font("body")};
    font-size: ${fontSize.m};
    font-weight: 400;
  }

  section {
    padding: ${spacing.l} 0;
    border-bottom: 1px solid ${grays.veryLight};

    > h1 {
      padding-bottom: ${spacing.m};
    }

    .row {
      ${flex("row", "flex-start", "center")};
      padding-bottom: ${spacing.s};

      > h2 {
        width: 25%;
      }

      select {
        ${replaceOutline()};
        font-size: ${fontSize.m};
        text-transform: capitalize;
        text-align: center;
        width: 20rem;
        border: none;
        background: white;
        cursor: pointer;
        padding: ${spacing.s} 0;
      }
    }
  }
`;
