import React from "react";
import {
  StyledFloatingCloseButton,
  StyledMain,
  StyledModal,
  StyledSlideContainer,
  StyledContentContainer,
  StyledContent,
} from "elements";
import { useModalOpenStatus } from "./useModalOpenStatus"; //Components
import SaveAsForm from "./SaveAsForm";

const PlanSaveAsModal = () => {
  const { handleCloseModal, saveAsModalIsOpen } = useModalOpenStatus();

  if (saveAsModalIsOpen)
    return (
      <StyledModal
        aria-labelledby="task-dialog"
        open={saveAsModalIsOpen}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <StyledFloatingCloseButton onClick={handleCloseModal}>
          X
        </StyledFloatingCloseButton>

        <StyledMain>
          <StyledSlideContainer>
            <StyledContentContainer>
              <StyledContent>
                <SaveAsForm />
              </StyledContent>
            </StyledContentContainer>
          </StyledSlideContainer>
        </StyledMain>
      </StyledModal>
    );
  return null;
};

export default PlanSaveAsModal;
