//React
import React, { FC } from "react";
//MUI
import { DatePicker } from "@material-ui/pickers";
//Styles
import { StyledHolidays } from "./styles";
//Icons
import { TiDeleteOutline } from "react-icons/ti";
//Hooks
import { useHolidays } from "./useHolidays";
import { useDatePickerControls } from "./useDatePickerControls";

const Holidays: FC = () => {
  const { holidays, handleAddHoliday, handleDeleteHoliday } = useHolidays();

  const { pickerIsOpen, handleClose, handleOpen, handleKeyboardOpen } =
    useDatePickerControls();

  return (
    <StyledHolidays>
      <h2>Holidays</h2>

      <div>
        <ul>
          {holidays.map((holiday) => (
            <li key={holiday}>
              <p className="date">{holiday}</p>
              <button
                data-testid={`delete-${holiday}`}
                className="delete-button"
                onClick={() => {
                  handleDeleteHoliday(holiday);
                }}
              >
                <TiDeleteOutline />
              </button>
            </li>
          ))}
        </ul>

        <div className="picker-container">
          <DatePicker
            open={pickerIsOpen}
            value={null}
            onChange={handleAddHoliday}
            disableToolbar
            format="dd MMM yyyy"
            emptyLabel="Add A New Holiday"
            onOpen={handleOpen}
            onClose={handleClose}
            onKeyPress={handleKeyboardOpen}
            data-testid="holiday-picker"
          />
        </div>
      </div>
    </StyledHolidays>
  );
};

export default Holidays;
