import { ICalendar } from "types";

export const dummyCalendar: ICalendar = {
  workDays: [
    {
      day: "Monday",
      startTime: "0800",
      endTime: "1730",
    },
    {
      day: "Tuesday",
      startTime: "0800",
      endTime: "1730",
    },
    {
      day: "Wednesday",
      startTime: "0800",
      endTime: "1730",
    },
    {
      day: "Thursday",
      startTime: "0800",
      endTime: "1730",
    },
    {
      day: "Friday",
      startTime: "0800",
      endTime: "1730",
    },
    {
      day: "Saturday",
      startTime: "0800",
      endTime: "1730",
    },
    {
      day: "Sunday",
      startTime: null,
      endTime: null,
    },
  ],
  holidays: [],
};
