import React, { FC } from "react";
import MenuButton from "./MenuButton";
import SettingsMenu from "./SettingsMenu";
import Backdrop from "./Backdrop";
import FileErrorSnackbar from "Components/FileErrorSnackbar/FileErrorSnackbar";
import { selectMenuStatus } from "store/menu";
import { useAppSelector } from "store";

interface SettingsMenuContainerProps {
  handleOpenWBReportModal?: () => void;
  handleOpenPlanImport?: () => void;
  handleOpenCategorise?: () => void;
  handleOpenRiskExport?: () => void;
}

const SettingsMenuContainer: FC<SettingsMenuContainerProps> = ({
  handleOpenWBReportModal,
  handleOpenPlanImport,
  handleOpenCategorise,
  handleOpenRiskExport,
}) => {
  const menuIsOpen = useAppSelector(selectMenuStatus);

  return (
    <div className="flex-col justify-center items-center outline-none focus:shadow-[0_0_0.2rem_0.1rem] focus:shadow-[#13cd89]">
      <MenuButton />

      {/* Menu is absolutely placed next to the button */}
      {menuIsOpen && (
        <SettingsMenu
          handleOpenWBReportModal={handleOpenWBReportModal}
          handleOpenPlanImport={handleOpenPlanImport}
          handleOpenCategorise={handleOpenCategorise}
          handleOpenRiskExport={handleOpenRiskExport}
        />
      )}
      {menuIsOpen && <Backdrop />}

      <FileErrorSnackbar />
    </div>
  );
};

export default SettingsMenuContainer;
