import { useState } from "react";

export const useDatePickerControls = () => {
  const [pickerIsOpen, setPickerIsOpen] = useState(false);

  const handleOpen = () => {
    setPickerIsOpen(true);
  };

  const handleClose = () => {
    setPickerIsOpen(false);
  };

  const handleKeyboardOpen = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") setPickerIsOpen(true);
  };

  return { pickerIsOpen, handleClose, handleOpen, handleKeyboardOpen };
};
