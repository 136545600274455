//React
import { useCallback } from "react";
//Store
import { useAppSelector, useAppDispatch } from "store";
import { setSaveAsModalIsOpen } from "store/project";
//Types
import { RootState } from "store";

export const useModalOpenStatus = () => {
  const dispatch = useAppDispatch();

  const saveAsModalIsOpen = useAppSelector(
    (state: RootState) => state.project.saveAsModalIsOpen
  );

  const handleCloseModal = useCallback(() => {
    dispatch(setSaveAsModalIsOpen(false));
  }, [dispatch]);

  return { saveAsModalIsOpen, handleCloseModal };
};
