import { useFetchCalendarData } from "./useFetchCalendarData";
import { settingsMenuMap } from "./constants";
import { TabPageWrapper } from "@ehabitation/ui";

const Settings = () => {
  useFetchCalendarData();
  return <TabPageWrapper map={settingsMenuMap} loading={false} id="settings" />;
};

export default Settings;
