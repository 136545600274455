import { FC } from "react";
import {
  extremeDailyRainAcc,
  extremeMinTemp,
  extremeWind,
  extremeMaxTemp,
  WeatherKey,
  WeatherThresholds,
  extremeSnowfall,
  extremeWaveHeight,
  extremeWindGusts,
  extremeSnowfall24Hour,
  extremeVisibility,
} from "@ehabitation/ts-utils/browser";

interface ThresholdInputProps {
  id: string;
  readOnly: boolean;
  className?: string;
  type: WeatherKey;
  handleChange: Function;
  thresholds: WeatherThresholds;
  label: string;
}

const isError = (type: WeatherKey, thresholds: WeatherThresholds) => {
  switch (type) {
    case WeatherKey.minTemp:
    case WeatherKey.maxTemp:
      return typeof thresholds.minTemp !== "undefined" &&
        typeof thresholds.maxTemp !== "undefined" &&
        thresholds.minTemp >= thresholds.maxTemp!
        ? `error`
        : "";
    case WeatherKey.dailyRainAcc:
    case WeatherKey.hourlyRainAcc:
      return typeof thresholds.hourlyRainAcc !== "undefined" &&
        typeof thresholds.dailyRainAcc !== "undefined" &&
        thresholds.hourlyRainAcc > thresholds.dailyRainAcc
        ? `error`
        : "";
    case WeatherKey.snowfall24Hour:
    case WeatherKey.snowfall:
      return typeof thresholds.snowfall !== "undefined" &&
          typeof thresholds.snowfall24Hour !== "undefined" &&
          thresholds.snowfall > thresholds.snowfall24Hour
        ? `error`
        : "";
    default:
      return "";
  }
};

export const minMaxFetchers: Record<
  WeatherKey,
  (thresholds: WeatherThresholds) => { min: number; max: number }
> = {
  [WeatherKey.minTemp]: (thresholds) => ({
    min: extremeMinTemp,
    max: thresholds.maxTemp ?? extremeMaxTemp,
  }),
  [WeatherKey.maxTemp]: (thresholds) => ({
    min: thresholds.minTemp ?? 0,
    max: extremeMaxTemp,
  }),
  [WeatherKey.dailyRainAcc]: (thresholds) => ({
    min: thresholds.hourlyRainAcc ?? 0,
    max: extremeDailyRainAcc,
  }),
  [WeatherKey.hourlyRainAcc]: (thresholds) => ({
    min: 0,
    max: thresholds.dailyRainAcc ?? extremeDailyRainAcc,
  }),
  [WeatherKey.wind]: () => ({
    min: 0,
    max: extremeWind,
  }),
  [WeatherKey.snowfall]: () => ({
    min: 0,
    max: extremeSnowfall,
  }),
  [WeatherKey.waveHeight]: () => ({
    min: 0,
    max: extremeWaveHeight,
  }),
  [WeatherKey.windGusts]: () => ({
    min: 0,
    max: extremeWindGusts,
  }),
  [WeatherKey.snowfall24Hour]: () => ({
    min: 0,
    max: extremeSnowfall24Hour,
  }),
  [WeatherKey.visibility]: () => ({
    min: 0,
    max: extremeVisibility,
  }),
};

const ThresholdInput: FC<ThresholdInputProps> = ({
  id,
  readOnly,
  type,
  thresholds,
  handleChange,
  label,
  className,
}) => {
  const value =
    typeof thresholds[type] === "undefined"
      ? undefined
      : Number(thresholds[type]);
  const { min, max } = minMaxFetchers[type](thresholds);
  const errored =
    isError(type, thresholds) ||
    (typeof value === "number" ? value > max || value < min : false);
  const errorClass = errored ? "text-red-500 font-bold" : "";
  return (
    <div className={`w-full ${className}`} data-testid={`category-${type}`}>
      <input
        type="number"
        placeholder={value === undefined ? "None" : ""}
        data-testid={`input-${id}`}
        value={value}
        onChange={(event) =>
          handleChange(
            isNaN(Number(event.target.value)) || event.target.value === ""
              ? undefined
              : Number(event.target.value)
          )
        }
        name={type}
        role="spinbutton"
        aria-invalid={!!errorClass}
        min={min}
        max={max}
        className={`${errorClass} bg-inherit w-full`}
        aria-label={label}
        disabled={readOnly}
        style={{ minWidth: "50px" }} // Ensure a minimum width to show full number and spin buttons
      />
    </div>
  );
};

export default ThresholdInput;
