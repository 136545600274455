import {
  CollectionType,
  IPlan,
  ISimulation,
  ISite,
  ITask,
  ThresholdsSchema,
  isGuest as roleIsGuest,
} from "@ehabitation/ts-utils/browser";
import {
  Button,
  FilterButton,
  FilterIndicator,
  Input,
  Modal,
  Spinner,
} from "@ehabitation/ui";
import { Tooltip } from "@material-ui/core";
import { doc, writeBatch } from "firebase/firestore";
import { db } from "firebaseConfig";
import {
  SimulationLevel,
  SimulationPlanResult,
  SimulationResult,
  SimulationTaskResult,
  duplicatePlanData,
} from "helpers";
import { logSimulateMitigations } from "helpers/analytics";
import { useSiteOpenPageEvent } from "hooks";
import { triggerSimulation } from "hooks/useTriggerSimulation";
import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { BsInfoCircle } from "react-icons/bs";
import { FiX } from "react-icons/fi";
import { RiPlayLine } from "react-icons/ri";
import { useVirtual } from "react-virtual";
import { useAppSelector } from "store";
import { selectRole, selectUserId } from "store/auth";
import { selectOrganisationSites } from "store/siteManagement";
import { IThresholdUpdate } from "types";
import { EndDatesTable } from "../components/EndDatesTable";
import { nonBreakingSpace } from "../constants";
import { getPlanTaskId, wbsPaddingLevel } from "../helper";
import {
  useMitigationPlanThresholdsByBaseId,
  useMitigationsVisibleTasks,
} from "../hooks";
import { CategoryRow } from "./CategoryRow";

export interface MitigationsViewProps {
  siteId: string;
  tasks: (ITask & { simulation: SimulationTaskResult })[];
  basePlan: IPlan;
  simulationPlanResult?: SimulationPlanResult;
  mitigationPlan?: IPlan;
  mitigationSimulation?: ISimulation;
  mitigationSimulationResult?: SimulationResult;
  comparisonResultsByBaseTaskId: {
    [id: string]: ITask & { simulation: SimulationTaskResult };
  };
  setPrintableLink: (id: string) => void;
  setHelpLink: (id: string) => void;
  handleOpenCategorise: (taskId: string) => void;
}

const getCompleteMitigationThresholds = (
  baseTask?: ITask,
  mitigations?: IThresholdUpdate,
  newMitigations?: IThresholdUpdate
) => {
  const baseThresholds = ThresholdsSchema.parse(baseTask || {});
  return {
    ...baseThresholds,
    ...mitigations,
    ...newMitigations,
  };
};

export const MitigationsView: FC<MitigationsViewProps> = ({
  siteId,
  tasks,
  basePlan,
  simulationPlanResult,
  mitigationPlan,
  mitigationSimulation,
  mitigationSimulationResult,
  comparisonResultsByBaseTaskId,
  setPrintableLink,
  setHelpLink,
  handleOpenCategorise,
}) => {
  const role = useAppSelector(selectRole);
  const isGuest = roleIsGuest(role!);

  useSiteOpenPageEvent("mitigations", siteId, basePlan?.id);

  const [showOnlyImpactedTasks, setShowOnlyImpactedTasks] =
    useState<boolean>(true);

  const [
    showOnlyActivitiesWithMitigations,
    setShowOnlyActivitiesWithMitigations,
  ] = useState<boolean>(false);

  const tasksContainerRef = useRef<HTMLTableSectionElement>(null);

  const [selectedSimLevel, setSelectedSimLevel] =
    useState<SimulationLevel>("80");
  const [mitigationSimulationRequested, setMitigationSimulationRequested] =
    useState(false);
  const [openApplyMitigation, setOpenApplyMitigation] = useState(false);
  const [currentMitigation, setCurrentMitigation] = useState<
    | {
        category: string;
        threshold: keyof ITask;
        value: string | undefined;
      }
    | undefined
  >();
  const [mitigationPlanRequested, setMitigationPlanRequested] = useState(false);

  const userId = useAppSelector(selectUserId);

  const [editingTasks, setEditingTasks] = useState<Map<string, boolean>>(
    new Map()
  );

  const organisationSites = useAppSelector(selectOrganisationSites);
  const site = organisationSites[siteId] as ISite;

  /* TODO extract 
    - mitigationSimulationErrored 
    - mitigationSimulationInProgress 
    - mitigationSimulationPending
    - mitigationPlanReady
   to a helper file */
   
  const mitigationSimulationErrored =
    !!mitigationSimulation &&
    (mitigationSimulation?.status as string) === "error";

  const mitigationSimulationInProgress =
    mitigationSimulationRequested ||
    mitigationPlanRequested ||
    (!!mitigationSimulation &&
      !["error", "complete"].includes(mitigationSimulation?.status)) ||
    (mitigationSimulation?.status === "complete" &&
      mitigationSimulation?.simulatedTasks > 0 &&
      !mitigationSimulationResult);

  const mitigationSimulationPending =
    mitigationSimulationErrored || mitigationSimulationInProgress;

  const mitigationPlanReady =
    !!mitigationPlan &&
    ["imported", "complete", "categorised"].includes(
      mitigationPlan?.status as string
    );
    // up to here!

  useEffect(() => {
    mitigationPlan
      ? setPrintableLink(
          `/print/${siteId}/${basePlan.id}/${mitigationPlan.id}/mitigations`
        )
      : setPrintableLink(`/print/${siteId}/${basePlan.id}/0/mitigations`);
    return () => setPrintableLink("");
  }, [mitigationPlan]);

  useEffect(() => {
    localStorage.setItem(
      `mitigations_selectedSimLevel_${siteId}_${basePlan.id}_${
        mitigationPlan?.id || 0
      }`,
      `${selectedSimLevel}`
    );
  }, [selectedSimLevel, siteId, basePlan, mitigationPlan]);

  useEffect(() => {
    if (mitigationPlanReady && mitigationPlanRequested) {
      handleSimulateAction();
      setMitigationPlanRequested(false);
    }
  }, [mitigationPlanReady, mitigationPlanRequested]);

  const { mitigationPlanThresholdsByBaseId } =
    useMitigationPlanThresholdsByBaseId(
      siteId,
      basePlan.id,
      mitigationPlan?.id
    );

  const [titleFilter, setTitleFilter] = useState("");

  useEffect(() => {
    if (mitigationSimulation && mitigationSimulationRequested) {
      setMitigationSimulationRequested(false);
    }
  }, [mitigationSimulation, mitigationSimulationRequested]);

  useEffect(() => {
    setHelpLink('https://ehab.co/docs/how-to-use-the-mitigations-dashboard/');
    return () => setHelpLink("");
  }, []);
  
  const [pendingMitigations, setPendingMitigations] = useState<any>({});

  const newMitigations = useMemo(() => {
    const result = Object.keys(pendingMitigations).reduce(
      (acc: Record<string, IThresholdUpdate>, taskId: string) => {
        const pendingTaskMitigations = pendingMitigations[taskId];
        const appliedTaskMitigations =
          mitigationPlanThresholdsByBaseId?.get(taskId);
        const baseTaskThresholds = tasks.find((task) => task.id === taskId)!;
        const appliedThresholds = {
          ...baseTaskThresholds,
          ...appliedTaskMitigations,
        };
        for (const threshold of Object.keys(pendingTaskMitigations)) {
          const value = pendingTaskMitigations[threshold];
          if (typeof value === "undefined") continue;
          const appliedValue =
            appliedThresholds?.simulation[
              threshold as keyof SimulationTaskResult
            ];
          if (typeof appliedValue !== "undefined") {
            if (Number(appliedValue) === Number(value)) {
              continue;
            }
          }
          if (!isNaN(Number(value))) {
            acc[taskId] = {
              ...acc[taskId],
              [threshold]: Number(value),
            };
          }
        }
        return acc;
      },
      {}
    );
    return Object.keys(result || {}).length ? result : undefined;
  }, [pendingMitigations, tasks, mitigationPlanThresholdsByBaseId]);

  const handleSimulateAction = async () => {
    // save newMitigations to mitigationPlan
    setMitigationSimulationRequested(true);
    logSimulateMitigations(siteId, basePlan.id);
    let updatesBatch = writeBatch(db);
    let updatesCount = 0;
    const updatePromises = [];

    if (newMitigations) {
      for (const [taskId, newTaskMitigations] of Object.entries(
        newMitigations
      )) {
        const taskDoc = doc(
          db,
          `${CollectionType.Plans}/${mitigationPlan?.id}/${
            CollectionType.Tasks
          }/${getPlanTaskId(taskId, mitigationPlan?.id)}`
        );
        const baseTask = tasks.find(({ id }) => id === taskId);
        const completeMitigations = getCompleteMitigationThresholds(
          baseTask,
          mitigationPlanThresholdsByBaseId?.get(taskId),
          newTaskMitigations
        );
        updatesBatch.update(taskDoc, {
          ...completeMitigations,
          isMitigated: true,
        });
        updatesCount++;
        if (updatesCount > 499) {
          updatePromises.push(updatesBatch.commit());
          updatesBatch = writeBatch(db);
          updatesCount = 0;
        }
      }
    }

    if (
      updatesCount > 0 ||
      updatePromises.length ||
      mitigationSimulationErrored
    ) {
      updatesCount && updatePromises.push(updatesBatch.commit());
      Promise.all(updatePromises).then(() => {
        setEditingTasks(new Map());
        mitigationPlan?.id &&
          triggerSimulation(mitigationPlan?.id, siteId, userId, site);
      });
    }
  };

  const { visibleTasks } = useMitigationsVisibleTasks(
    titleFilter,
    showOnlyImpactedTasks,
    tasks,
    comparisonResultsByBaseTaskId,
    showOnlyActivitiesWithMitigations,
    selectedSimLevel,
    mitigationPlan,
    mitigationPlanThresholdsByBaseId,
    mitigationSimulationResult,
    pendingMitigations,
    newMitigations
  );

  const { virtualItems: virtualRows, totalSize } = useVirtual({
    size: visibleTasks.length,
    parentRef: tasksContainerRef,
    estimateSize: useCallback(() => 42, []),
    overscan: 20,
  });

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;
  const storeImpactedTasksFilter = (value: boolean) => {
    const id = mitigationPlan?.id || 0;
    value
      ? localStorage.removeItem(
          `mitigations_impactedTasks_${siteId}_${basePlan.id}_${id}`
        )
      : localStorage.setItem(
          `mitigations_impactedTasks_${siteId}_${basePlan.id}_${id}`,
          `${value}`
        );
    setShowOnlyImpactedTasks(value);
  };
  const storeTitleFilter = (value: string) => {
    const id = mitigationPlan?.id || 0;
    value === ""
      ? localStorage.removeItem(
          `mitigations_title_${siteId}_${basePlan.id}_${id}`
        )
      : localStorage.setItem(
          `mitigations_title_${siteId}_${basePlan.id}_${id}`,
          value
        );
    setTitleFilter(value);
  };

  const storeActivitiesWithMitigationsFilter = (value: boolean) => {
    const id = mitigationPlan?.id || 0;
    value
      ? localStorage.removeItem(
          `mitigations_activitiesWithMitigations_${siteId}_${basePlan.id}_${id}`
        )
      : localStorage.setItem(
          `mitigations_activitiesWithMitigations_${siteId}_${basePlan.id}_${id}`,
          `${value}`
        );
    setShowOnlyActivitiesWithMitigations(value);
  };

  const applyMitigationToAll = () => {
    visibleTasks.forEach((task) => {
      if (task.taskType === currentMitigation?.category) {
        if (
          typeof currentMitigation.value === "string" &&
          !currentMitigation.value
        ) {
          setPendingMitigations((prev: any) => {
            const prevClone = { ...prev };
            delete prevClone[task.id][currentMitigation.threshold];
            return prevClone;
          });
        } else {
          setPendingMitigations((prev: any) => {
            return {
              ...prev,
              [task.id]: {
                ...prev[task.id],
                [currentMitigation.threshold]: currentMitigation.value,
              },
            };
          });
        }
      }
    });
    setOpenApplyMitigation(false);
    setCurrentMitigation(undefined);
  };

  useEffect(() => {
    storeImpactedTasksFilter(true);
    storeActivitiesWithMitigationsFilter(false);
    storeTitleFilter("");
  }, []);
  return (
    <>
      <EndDatesTable
        selectedSimLevel={selectedSimLevel}
        setSelectedSimLevel={setSelectedSimLevel}
        simulationPlanResult={simulationPlanResult}
        mitigationSimulationResult={mitigationSimulationResult}
      />
      <div className="col-span-4 xl:col-span-3 bg-gray-50 rounded-md gap-12 flex justify-between items-center mb-1">
        <div className="flex gap-4 px-4 py-2 items-center">
          <div className="text-xl">Filter by</div>
          <FilterButton
            isEnabled={showOnlyImpactedTasks}
            onClick={() => storeImpactedTasksFilter(!showOnlyImpactedTasks)}
            className="text-xl py-[0.35rem]"
          >
            Only impacted tasks
          </FilterButton>
          <FilterButton
            isEnabled={showOnlyActivitiesWithMitigations}
            onClick={() =>
              storeActivitiesWithMitigationsFilter(
                !showOnlyActivitiesWithMitigations
              )
            }
            className="text-xl py-[0.35rem]"
          >
            Only activities with mitigations
          </FilterButton>
        </div>
        <div className="flex gap-1 items-center px-2">
          <div className="flex items-center relative">
            <Input
              autoComplete="off"
              id="filter-task-name"
              placeholder="Filter by task or category names"
              value={titleFilter}
              onChange={(e) => storeTitleFilter(e.target.value)}
              className="pt-0 pb-0 pl-4 pr-20 mr-2 min-w-[270px]"
            />
            <button
              className="absolute right-4 rounded-lg enabled:hover:bg-gray-200 disabled:text-gray-400"
              aria-label="Clear Filter"
              disabled={!titleFilter}
              onClick={() => storeTitleFilter("")}
            >
              <FiX className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
      <div
        className="pl-4 min-h-[36rem] flex-auto overflow-auto border-b"
        ref={tasksContainerRef}
      >
        <div style={{ height: totalSize + 60 }}>
          <table className="mb-8 divide-y w-full table-auto border-separate border-spacing-0">
            <thead className="bg-gray-50 sticky top-0 z-[8]">
              <tr className="border ">
                <th
                  scope="col"
                  className="whitespace-nowrap py-3.5 px-4 text-left text-2xl font-semibold text-gray-900 sm:pl-6 border"
                >
                  Task Name
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap py-3.5 px-4 text-left text-2xl font-semibold text-gray-900 sm:pl-6 border"
                >
                  Category
                </th>
                <th
                  colSpan={2}
                  scope="col"
                  className="py-3.5 px-4 text-2xl font-semibold text-gray-900 sm:pl-6 border text-center"
                >
                  <Tooltip
                    arrow
                    title={
                      <span className="text-2xl">
                        Expected weather-related days during the task.
                      </span>
                    }
                  >
                    <div className="flex justify-between">
                      <span>
                        <span className="px-1">{"Weather days"}</span>
                        <FilterIndicator
                          isEnabled={!!showOnlyImpactedTasks}
                          handleClick={() =>
                            storeImpactedTasksFilter(!showOnlyImpactedTasks)
                          }
                          label="Toggle impacted filter"
                        />
                      </span>
                      <BsInfoCircle className="mt-1" />
                    </div>
                  </Tooltip>
                </th>
                <th
                  scope="col"
                  className="py-3.5 px-4 text-2xl font-semibold text-gray-900 sm:pl-6 border text-center"
                >
                  <div className="flex">
                    <span className="px-1">Mitigated</span>
                    <FilterIndicator
                      isEnabled={!!showOnlyActivitiesWithMitigations}
                      handleClick={() =>
                        storeActivitiesWithMitigationsFilter(
                          !showOnlyActivitiesWithMitigations
                        )
                      }
                      label="Toggle activities with mitigations filter"
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  className="py-3.5 px-4 text-2xl font-semibold text-gray-900 sm:pl-6 border text-center"
                  colSpan={3}
                >
                  <Tooltip
                    arrow
                    title={
                      <span className="text-2xl">
                        Choose the desired threshold for risk reduction.
                      </span>
                    }
                  >
                    <div className="flex justify-between">
                      <span>
                        <span className="px-1">Thresholds</span>
                      </span>
                      <BsInfoCircle className="mt-1" />
                    </div>
                  </Tooltip>
                </th>
              </tr>
              <tr>
                <th className="border text-xl font-normal"></th>
                <th className="border text-xl font-normal"></th>
                <th className="border text-xl font-normal min-w-[7rem]">Pre</th>
                <th className="border text-xl font-normal min-w-[9rem]">
                  Post
                </th>
                <th className="border text-xl font-normal min-w-[8rem]"></th>
                <th className="border text-xl font-normal min-w-[12rem]"></th>
                <th className="border text-xl font-normal min-w-[5.5rem]">
                  Pre
                </th>
                <th
                  className="border text-xl font-normal min-w-[7rem]"
                >
                  Post
                </th>
              </tr>
            </thead>
            <tbody>
              {paddingTop > 0 && (
                <tr>
                  <td style={{ height: `${paddingTop}px` }} />
                </tr>
              )}
              {virtualRows.map((virtualRow) => {
                const task = visibleTasks[virtualRow.index];
                if (task.WBS) {
                  return (
                    <tr
                      key={task.id}
                      className="sticky top-[5.9rem] z-[8] bg-white"
                    >
                      <td
                        style={wbsPaddingLevel(task.wbsHierarchyLevel)}
                        className={`px-4 truncate border-x border-b relative font-bold`}
                        colSpan={8}
                      >
                        {task.title}
                      </td>
                    </tr>
                  );
                }

                const mitigationTaskId = getPlanTaskId(
                  task.id,
                  mitigationPlan?.id
                );
                const mitigatedThresholds =
                  mitigationPlanThresholdsByBaseId &&
                  mitigationPlanThresholdsByBaseId.get(task.id);
                const mitigationTaskResult =
                  mitigationSimulationResult?.taskResults?.[mitigationTaskId];
                return (
                  <CategoryRow
                    selectedSimLevel={selectedSimLevel}
                    key={task.id}
                    task={task}
                    mitigatedTask={
                      mitigationPlan && {
                        isMitigated: !!mitigatedThresholds,
                        ...mitigatedThresholds,
                        ...mitigationTaskResult,
                      }
                    }
                    mitigationSimulationInProgress={
                      mitigationSimulationInProgress
                    }
                    mitigationSimulationPending={mitigationSimulationPending}
                    editedMitigations={pendingMitigations?.[task.id]}
                    pendingMitigations={newMitigations?.[task.id]}
                    setIsEditing={(isEditing: boolean) =>
                      setEditingTasks((prev) => {
                        const prevClone = new Map(prev);
                        prevClone.set(task.id, isEditing);
                        return prevClone;
                      })
                    }
                    isEditing={editingTasks.get(task.id)}
                    isGuest={isGuest}
                    setMitigation={(key, value) => {
                      if (typeof value === "string" && !value) {
                        setPendingMitigations((prev: any) => {
                          const prevClone = { ...prev };
                          delete prevClone[task.id][key];
                          return prevClone;
                        });
                      } else {
                        setPendingMitigations((prev: any) => {
                          return {
                            ...prev,
                            [task.id]: {
                              ...prev[task.id],
                              [key]: value,
                            },
                          };
                        });
                      }
                    }}
                    handleOpenCategorise={handleOpenCategorise}
                    applyMitigation={(key, value) => {
                      setCurrentMitigation({
                        category: task.taskType,
                        threshold: key as keyof ITask,
                        value: value,
                      });
                      setOpenApplyMitigation(true);
                    }}
                  />
                );
              })}
              {paddingBottom > 0 && (
                <tr>
                  <td style={{ height: `${paddingBottom}px` }} />
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="self-center flex flex-col items-center">
        <Tooltip
          arrow
          disableHoverListener={!isGuest}
          title={
            <span className="text-2xl">{"Guest accounts are read-only"}</span>
          }
        >
          <span>
            <Button
              className={`py-2 flex items-center gap-2 mt-4 ${
                mitigationPlanRequested ||
                (mitigationPlan && !mitigationPlanReady) ||
                mitigationSimulationRequested
                  ? "animate-pulse"
                  : ""
              }`}
              type="button"
              aria-label="Simulate"
              disabled={
                mitigationPlanRequested ||
                (mitigationPlan && !mitigationPlanReady) ||
                mitigationSimulationInProgress ||
                (!newMitigations && !mitigationSimulationErrored) ||
                isGuest
              }
              onClick={() => {
                if (!mitigationPlan?.id) {
                  setMitigationPlanRequested(true);
                  duplicatePlanData(
                    basePlan.id,
                    userId,
                    basePlan.title + " (Mitigation)",
                    true
                  );
                } else {
                  setMitigationSimulationRequested(true);
                  handleSimulateAction();
                }
              }}
            >
              {mitigationSimulationInProgress ? (
                ""
              ) : (
                <RiPlayLine className="inline" />
              )}
              {mitigationSimulationInProgress ? "Simulating" : "Simulate"}{" "}
              {!mitigationSimulationInProgress && newMitigations
                ? Object.keys(newMitigations).length
                : ""}{" "}
              Mitigation
              {Object.keys(newMitigations || {}).length === 1 ? "" : "s"}
              {mitigationSimulationInProgress ? <Spinner size="xSmall" /> : ""}
            </Button>
          </span>
        </Tooltip>
        <p className="text-red-600">
          {mitigationSimulationErrored
            ? "Mitigation simulation error"
            : nonBreakingSpace}
        </p>
      </div>
      {openApplyMitigation && (
        <Modal
          id="mitigations-modal"
          handleCloseModal={() => {
            setOpenApplyMitigation(false);
          }}
          className="!h-[230px] w-[400px]"
          title="Apply Mitigation"
        >
          <div>
            <div className="flex gap-2 items-center text-2xl pt-16">
              Would you like to apply the same mitigation to all activities of
              this category?
            </div>
            <div className="flex flex-row justify-end mt-16 mr-6">
              <button
                onClick={() => {
                  setOpenApplyMitigation(false);
                  setCurrentMitigation(undefined);
                }}
                className="bg-[#F1F1F1] cursor-pointer text-2xl font-medium px-6 py-3 uppercase flex gap-2 items-center disabled:text-gray-400"
                aria-label="apply mitigation to all activities of same category"
              >
                <span>NO</span>
              </button>
              <button
                onClick={() => applyMitigationToAll()}
                className="ml-4 bg-black cursor-pointer text-white text-2xl font-medium px-6 py-3 uppercase flex gap-2 items-center disabled:text-gray-400"
                aria-label="apply mitigation to all activities of same category"
              >
                <span>Yes</span>
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
