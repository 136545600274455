import {
  CollectionType,
  IPlan,
  ISite,
  ITask,
  transformPlanDoc,
} from "@ehabitation/ts-utils/browser";
import {
  Filter,
  FilterButton,
  FilterIndicator,
  LoadingType,
  LoadingWrapper,
} from "@ehabitation/ui";
import { Tooltip } from "@material-ui/core";
import { useComparisonImpactedTasksNotInBase, useProcessedTasks, useSimulation, useSimulationResult, useTaskDocs } from "Pages/sites/hooks";
import { where } from "firebase/firestore";
import { SimulationLevel, SimulationTaskResult } from "helpers";
import {
  useCollectionQuery,
  usePersonalSites,
  usePlanRiskMatrix,
  useRiskMatrixTopLevelCategories,
  useSetupSubscriptions,
  useSiteOpenPrintPageEvent,
} from "hooks";
import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { BsInfoCircle } from "react-icons/bs";
import { VscFilterFilled } from "react-icons/vsc";
import { useParams } from "react-router-dom";
import { useVirtual } from "react-virtual";
import { useAppSelector } from "store";
import {
  selectOrganisationProjects,
  selectOrganisationSites,
} from "store/siteManagement";
import { IOrganisationSite } from "types";
import { EndDatesTable } from "../components/EndDatesTable";
import { PrintableView } from "../components/PrintableView";
import { getRowHeight } from "../constants";
import { getPlanTaskId } from "../helper";
import {
  useMitigationPlanThresholdsByBaseId,
  useMitigationsVisibleTasks
} from "../hooks";
import { CategoryRowPrintable } from "./CategoryRowPrintable";

const useLocalStorageValue = (key: string) =>
  useMemo(() => localStorage.getItem(key) || "", [key]);

export const MitigationsViewPrintable: FC = () => {

  const subscriptionsReady = useSetupSubscriptions();

  const [currentSite, setSite] = useState<IOrganisationSite>();
  const [siteProject, setSiteProject] = useState<string>();
  const tasksContainerRef = useRef<HTMLTableSectionElement>(null);

  const organisationSites = useAppSelector(selectOrganisationSites);
  const { sites: personalSites = [] } = usePersonalSites();
  const [mitigationSimulationRequested, setMitigationSimulationRequested] =
    useState(false);
  const [tasks, setTasks] = useState<
    (ITask & { simulation: SimulationTaskResult })[]
  >([]);
  const {
    siteId = "",
    planId: selectedPlanId,
    mitigationPlanId,
  } = useParams<{
    siteId: string;
    planId: string;
    mitigationPlanId: string;
  }>();

  useSiteOpenPrintPageEvent("mitigations", siteId, selectedPlanId);

  const selectedSimLevel = useLocalStorageValue(
    `mitigations_selectedSimLevel_${siteId}_${selectedPlanId}_${mitigationPlanId}`
  ) as SimulationLevel;

  const titleFilter = useLocalStorageValue(
    `mitigations_title_${siteId}_${selectedPlanId}_${mitigationPlanId}`
  );
  const showOnlyImpactedTasks =
    useLocalStorageValue(
      `mitigations_impactedTasks_${siteId}_${selectedPlanId}_${mitigationPlanId}`
    ) === "false"
      ? false
      : true;
  const showOnlyActivitiesWithMitigations =
    useLocalStorageValue(
      `mitigations_activitiesWithMitigations_${siteId}_${selectedPlanId}_${mitigationPlanId}`
    ) === "false"
      ? false
      : true;

  const { data: rawPlans = [] } = useCollectionQuery<IPlan>(
    CollectionType.Plans,
    where("site", "==", siteId),
    where('project', '==', currentSite?.project ?? "")
  );

  const plans = useMemo(() => {
    return (
      rawPlans &&
      rawPlans
        .map((plan) => transformPlanDoc(plan.id, plan))
        .filter(({ status }) => status !== "discarded")
    );
  }, [rawPlans]);

  const mitigationPlan = useMemo(() => {
    if (!plans || !selectedPlanId) {
      return undefined;
    }
    return plans.find((plan) => plan.mitigatesPlanId === selectedPlanId);
  }, [plans, selectedPlanId]);

  const basePlan = useMemo(() => {
    if (!plans || !selectedPlanId) {
      return undefined;
    }
    return plans.find((plan) => plan.id === selectedPlanId);
  }, [plans, selectedPlanId]);

  const projects = useAppSelector(selectOrganisationProjects);

  const { riskMatrix } = usePlanRiskMatrix(selectedPlanId);

  const { categories, isLoading: isLoadingCategories } =
    useRiskMatrixTopLevelCategories(riskMatrix?.id);

  const { taskDocs: baseTaskDocs, isLoading: isLoadingBaseTaskDocs } =
    useTaskDocs(siteId!, selectedPlanId);

  const {
    taskDocs: mitigationTaskDocs,
    isLoading: isLoadingMitigationTaskDocs,
  } = useTaskDocs(siteId!, mitigationPlanId);

  const { simulation: mitigationSimulation } = useSimulation(
    siteId!,
    mitigationPlanId
  );
  const { simulationResult: mitigationSimulationResult } =
    useSimulationResult(mitigationSimulation);

  const { simulation } = useSimulation(siteId!, selectedPlanId);
  const { simulationResult } = useSimulationResult(simulation);

  const { tasks: simulatedBaseTasks } = useProcessedTasks(
    siteId!,
    categories,
    baseTaskDocs,
    simulationResult
  );

  const { tasks: simulatedMitigationTasks } = useProcessedTasks(
    siteId!,
    categories,
    mitigationTaskDocs,
    mitigationSimulationResult
  );

  useEffect(() => {
    setTasks(simulatedBaseTasks || []);
  }, [simulatedBaseTasks]);

  const { comparisonResultsByBaseTaskId } = useComparisonImpactedTasksNotInBase(
    tasks,
    simulatedMitigationTasks,
    mitigationPlanId,
    selectedPlanId
  );
/* TODO extract 
    - mitigationSimulationErrored 
    - mitigationSimulationInProgress 
    - mitigationSimulationPending
    - mitigationPlanReady
   to a helper file */
  const mitigationSimulationErrored =
    !!mitigationSimulation &&
    (mitigationSimulation?.status as string) === "error";

  const mitigationSimulationInProgress =
    mitigationSimulationRequested ||
    (!!mitigationSimulation &&
      !["error", "complete"].includes(mitigationSimulation?.status)) ||
    (mitigationSimulation?.status === "complete" &&
      !mitigationSimulationResult);

  const mitigationSimulationPending =
    mitigationSimulationErrored || mitigationSimulationInProgress;
// up to here

  useEffect(() => {
    setSite(
      organisationSites[siteId!] ||
        personalSites?.find(({ id }: ISite) => id === siteId)
    );
  }, [organisationSites, personalSites, siteId]);

  useEffect(() => {
    currentSite && setSiteProject(projects[currentSite.project]?.name);
  }, [projects, currentSite]);

  const { mitigationPlanThresholdsByBaseId } =
    useMitigationPlanThresholdsByBaseId(
      siteId,
      selectedPlanId,
      mitigationPlanId
    );

  useEffect(() => {
    if (mitigationSimulation && mitigationSimulationRequested) {
      setMitigationSimulationRequested(false);
    }
  }, [mitigationSimulation, mitigationSimulationRequested]);

  const { visibleTasks } = useMitigationsVisibleTasks(
    titleFilter,
    showOnlyImpactedTasks,
    tasks,
    comparisonResultsByBaseTaskId,
    showOnlyActivitiesWithMitigations,
    selectedSimLevel,
    mitigationPlan,
    mitigationPlanThresholdsByBaseId,
    mitigationSimulationResult
  );

  const { virtualItems: virtualRows, totalSize } = useVirtual({
    size: visibleTasks.length,
    parentRef: tasksContainerRef,
    estimateSize: useCallback(getRowHeight, []),
    overscan: 20,
  });

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  return (
    <PrintableView page="Mitigations">
      <div>
        <div className="mx-8 flex">
          <h2 className="text-4xl">Mitigations</h2>
          <p className="italic pl-4 pt-1">
            for site <strong>{currentSite?.name}</strong> in project{" "}
            <strong>{siteProject}</strong>, plan{" "}
            <strong>{basePlan?.title}</strong> (
            {basePlan?.id === currentSite?.mainPlanId ? "main" : "draft"})
          </p>
        </div>
        {
          <div>
            <div className="pt-4">
              <EndDatesTable
                selectedSimLevel={selectedSimLevel}
                simulationPlanResult={simulationResult?.planResults}
                mitigationSimulationResult={mitigationSimulationResult}
              />
            </div>
            <div className="bg-gray-50 rounded-md border border-gray-200 gap-12 col-span-3 flex justify-between items-center z-20 px-4 mb-2">
              <div className="flex gap-4 px-4 py-2 items-center">
                <div className="text-3xl text-gray-500">
                  <VscFilterFilled />
                </div>
                <Filter
                  disabled={true}
                  enabledText={titleFilter || ""}
                  title="Task or category names"
                >
                  {() => <></>}
                </Filter>
                <FilterButton
                  isEnabled={showOnlyImpactedTasks}
                  disabled={true}
                  className="text-xl py-[0.35rem]"
                >
                  Only impacted tasks
                </FilterButton>
                <FilterButton
                  isEnabled={showOnlyActivitiesWithMitigations}
                  disabled={true}
                  className="text-xl py-[0.35rem]"
                >
                  Only activities with mitigations
                </FilterButton>
              </div>
            </div>
            <div className="flex-auto" ref={tasksContainerRef}>
              <div>
                <LoadingWrapper
                  message="Loading tasks..."
                  type={LoadingType.full}
                  loading={
                    !visibleTasks || (visibleTasks && visibleTasks.length === 0)
                  }
                  white
                >
                  <table className="mb-8 divide-y w-full table-auto border-separate border-spacing-0">
                    <thead className="bg-gray-50 sticky top-0 z-[8]">
                      <tr className="border ">
                        <th
                          scope="col"
                          className="whitespace-nowrap py-3.5 px-4 text-left text-2xl font-semibold text-gray-900 sm:pl-6 border"
                        >
                          Task Name{" "}
                          <FilterIndicator
                            isEnabled={!!titleFilter}
                            disabled={true}
                            label="Open Task Name Filter"
                          />
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap py-3.5 px-4 text-left text-2xl font-semibold text-gray-900 sm:pl-6 border"
                        >
                          Category
                        </th>
                        <th
                          colSpan={2}
                          scope="col"
                          className="py-3.5 px-4 text-2xl font-semibold text-gray-900 sm:pl-6 border text-center"
                        >
                          <Tooltip
                            arrow
                            title={
                              <span className="text-2xl">
                                Expected weather-related days during the task.
                              </span>
                            }
                          >
                            <div className="flex justify-between">
                              <span>
                                <span className="px-1">Weather&nbsp;days</span>
                                <FilterIndicator
                                  isEnabled={!!showOnlyImpactedTasks}
                                  disabled={true}
                                  label="Toggle impacted filter"
                                />
                              </span>
                              <BsInfoCircle className="mt-1" />
                            </div>
                          </Tooltip>
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 px-4 text-2xl font-semibold text-gray-900 sm:pl-6 border text-center"
                        >
                          <div className="flex">
                            <span className="px-1">Mitigated</span>
                            <FilterIndicator
                              isEnabled={!!showOnlyActivitiesWithMitigations}
                              disabled={true}
                              label="Toggle activities with mitigations filter"
                            />
                          </div>
                        </th>
                        <th
                          colSpan={3}
                          scope="col"
                          className="py-3.5 px-4 text-2xl font-semibold text-gray-900 sm:pl-6 border text-center"
                        >
                          <Tooltip
                            arrow
                            title={
                              <span className="text-2xl">
                                Choose the desired threshold for risk reduction.
                              </span>
                            }
                          >
                            <div className="flex justify-between">
                              <span>
                                <span className="px-1">Thresholds</span>
                              </span>
                              <BsInfoCircle className="mt-1" />
                            </div>
                          </Tooltip>
                        </th>
                      </tr>
                      <tr>
                        <th className="border text-xl font-normal"></th>
                        <th className="border text-xl font-normal"></th>
                        <th className="border text-xl font-normal min-w-[7rem]">
                          Pre
                        </th>
                        <th className="border text-xl font-normal min-w-[9rem]">
                          Post
                        </th>
                        <th className="border text-xl font-normal min-w-[8rem]"></th>
                        <th className="border text-xl font-normal min-w-[12rem]"></th>
                        <th className="border text-xl font-normal min-w-[5.5rem]">
                          Pre
                        </th>
                        <th className="border text-xl font-normal min-w-[6rem]">
                          Post
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {paddingTop > 0 && (
                        <tr>
                          <td style={{ height: `${paddingTop}px` }} />
                        </tr>
                      )}
                      {virtualRows.map((virtualRow) => {
                        const task = visibleTasks[virtualRow.index];
                        if (task.WBS) {
                          return (
                            <tr
                              key={task.id}
                              className="sticky top-[5.9rem] z-[8] bg-white"
                            >
                              <td
                                style={
                                  (task?.wbsHierarchyLevel || 0) > 0
                                    ? {
                                        paddingLeft: `${
                                          task.wbsHierarchyLevel! * 2 + 1
                                        }rem`,
                                      }
                                    : {}
                                }
                                className={`px-4 truncate border-x border-b relative font-bold`}
                                colSpan={8}
                              >
                                {task.title}
                              </td>
                            </tr>
                          );
                        }

                        const mitigationTaskId = getPlanTaskId(
                          task.id,
                          mitigationPlanId
                        );
                        const mitigatedThresholds =
                          mitigationPlanThresholdsByBaseId &&
                          mitigationPlanThresholdsByBaseId.get(task.id);
                        const mitigationTaskResult =
                          mitigationSimulationResult?.taskResults?.[
                            mitigationTaskId
                          ];
                        return (
                          <CategoryRowPrintable
                            selectedSimLevel={selectedSimLevel}
                            key={task.id}
                            task={task as any}
                            mitigatedTask={
                              mitigationPlan && {
                                isMitigated: !!mitigatedThresholds,
                                ...mitigatedThresholds,
                                ...mitigationTaskResult,
                              }
                            }
                            mitigationSimulationInProgress={
                              mitigationSimulationInProgress
                            }
                            mitigationSimulationPending={
                              mitigationSimulationPending
                            }
                          />
                        );
                      })}
                      {paddingBottom > 0 && (
                        <tr>
                          <td style={{ height: `${paddingBottom}px` }} />
                        </tr>
                      )}
                    </tbody>
                  </table>
                </LoadingWrapper>
              </div>
            </div>
          </div>
        }
        <div className="pt-4">
          <div className="sticky print:static bg-gray-200 text-center bottom-0 w-full z-10">
            <span className="px-6">
              Don&apos;t have an ehab account? Sign up{" "}
              <a target="_blak" href="/" className="underline">
                here
              </a>{" "}
              for free weather insights
            </span>
          </div>
        </div>
      </div>
    </PrintableView>
  );
};
