import { ITaskCategory, WeatherKey } from "@ehabitation/ts-utils/browser";
import { TableCell } from "@material-ui/core";
import React, { FC } from "react";
import { TableRow } from "ui-shadcn/table";
import { ISiteHeatmapData } from "../CategoryHeatmaps/SingleCategoryHeatmap";
import { SingleRowHeatmap } from "../CategoryHeatmaps/singlerow-heatmap";
import ThresholdInput from "./ThresholdInput";

interface ThresholdListProps {
  id: string;
  thresholds: Record<string, any>;
  displayedColumns: WeatherKey[];
  handleThresholdChange: (threshold: WeatherKey, value: any) => void;
  handleRemoveThreshold: (threshold: WeatherKey) => void;
  changeMap: Record<string, any>;
  activeThresholds: Record<string, any>;
  readOnly: boolean;
  readableThresholdNames: Record<WeatherKey, string>;
  category: ITaskCategory;
  heatmap: ISiteHeatmapData | undefined;
}

const ThresholdList: FC<ThresholdListProps> = ({
  id,
  thresholds,
  displayedColumns,
  handleThresholdChange,
  handleRemoveThreshold,
  changeMap,
  activeThresholds,
  readOnly,
  readableThresholdNames,
  category,
  heatmap,
}) => {
  return (
    <>
      {displayedColumns.map((threshold) => (
        <TableRow className="border-hidden" key={`${id}_${threshold}`}>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <React.Fragment>
            <TableCell>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="whitespace-nowrap">
                    Avg. {readableThresholdNames[threshold]}
                  </span>
                </div>
                <div className="flex items-center justify-end">
                  <span className="ml-4 mr-4">
                    {threshold === WeatherKey.minTemp ? ">" : "<"}
                  </span>
                  <ThresholdInput
                    id={`${id}_${threshold}`}
                    className={`flex-1 border border-black ${
                      changeMap[id]?.[threshold] !== thresholds[threshold]
                        ? "border-emerald-500 border-2"
                        : ""
                    }`}
                    thresholds={activeThresholds}
                    readOnly={readOnly}
                    handleChange={(value: number) =>
                      handleThresholdChange(threshold, value)
                    }
                    type={threshold}
                    label={readableThresholdNames[threshold]}
                  />
                  <button
                    onClick={() => handleRemoveThreshold(threshold)}
                    className="ml-2 text-red-300"
                  >
                    X
                  </button>
                </div>
              </div>
            </TableCell>
            <TableCell>
              <div className="col-span-8 items-start">
                <SingleRowHeatmap
                  data={
                    heatmap && heatmap[category.name]
                      ? heatmap[category.name].factors_success_ratio[readableThresholdNames[threshold]]
                      : 
                      []
                  }
                  title={""}
                />
              </div>
            </TableCell>
          </React.Fragment>
        </TableRow>
      ))}
    </>
  );
};

export default ThresholdList;
