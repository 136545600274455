import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
  CollectionType,
  IPlan,
  ISimulation,
  ISite,
  ITask,
  ITaskCategory,
  NotificationConfig,
  NotificationType,
  NotificationTypeEnum,
  WeatherKey,
  transformSimulationDoc,
} from "@ehabitation/ts-utils/browser";
import { useAppSelector } from "store";
import { selectUserClaims, selectUserId } from "store/auth";
import { useCollectionQuery, useDocQuery, useIsMounted } from "hooks";
import { DocumentData, QueryDocumentSnapshot, doc, onSnapshot, updateDoc, getDoc } from "firebase/firestore";
import { db } from "firebaseConfig";
import {
  getLastSimulationQuery,
  getSimulationResults,
  getSimulationRiskDriverResults,
  SimulationLevel,
  SimulationResult,
  SimulationRiskDriverResult,
  SimulationTaskResult,
} from "helpers/firebase/simulation";
import { triggerSimulation } from "hooks/useTriggerSimulation";
import { fetchSiteTaskDocs, firebaseFunction, getTasksQuery } from "helpers";

import { getPlanTaskId, yieldMainThread } from "./helper";
import moment from "moment";
import { IThresholdUpdate } from "types";
import { thresholdCategories } from "./weather/WeatherView";
import { selectOrganisationSites } from "store/siteManagement";
import {
  ExportTypes,
  logExportEvent,
  logSafranProjectEvent,
  logSafranRiskEvent,
} from "helpers/analytics";
import { getBlob, getStorage, ref, uploadBytes } from "firebase/storage";
import config from "config";
import fileDownload from "js-file-download";
import { useParams } from "react-router-dom";
import { processRawTasks, getComparisonImpactedTasksNotInBase } from "./plan/helpers";
import { pageTitles } from "Components/Site/MainWrapper";

export type SimulationResultsKey =
  | "resultsFileWithDependencies"
  | "traResultsWithDependencies";

export const useSimulationResult = (
  simulation?: ISimulation,
  resultsKey: SimulationResultsKey = "resultsFileWithDependencies"
) => {
  const [simulationResultLoading, setSimulationResultLoading] =
    useState<boolean>(!!simulation);
  const [simulationResult, setSimulationResult] = useState<SimulationResult>();
  const isMounted = useIsMounted();

  useEffect(() => {
    setSimulationResult(undefined);

    if (simulation) {
      if (simulation?.[resultsKey]) {
        // possibility of getting stuck with old form lastFilename, make sure we don't get stucj waiting for non existent simulation.
        setSimulationResultLoading(true);
        getSimulationResults(simulation?.[resultsKey])
          .then((simResults) => {
            if (isMounted() && simResults) {
              setSimulationResult(simResults);
            }
          })
          .finally(() => {
            if (isMounted()) setSimulationResultLoading(false);
          });
      } else {
        setSimulationResultLoading(false);
      }
    }
  }, [simulation?.id, simulation?.[resultsKey]]);

  return { simulationResult, simulationResultLoading };
};

export const useSimulationRiskDriverResult = (simulation?: ISimulation) => {
  const resultsKey = "riskDriverResultsWithDependencies";
  const [simulationRiskDriverResultLoading, setSimulationResultLoading] =
    useState<boolean>(!!simulation);
  const [simulationRiskDriverResult, setSimulationResult] =
    useState<SimulationRiskDriverResult>();
  const isMounted = useIsMounted();

  useEffect(() => {
    setSimulationResult(undefined);

    if (simulation) {
      if (simulation?.[resultsKey]) {
        setSimulationResultLoading(true);
        getSimulationRiskDriverResults(simulation?.[resultsKey])
          .then((simResults) => {
            if (isMounted() && simResults) {
              setSimulationResult(simResults);
            }
          })
          .finally(() => {
            if (isMounted()) setSimulationResultLoading(false);
          });
      } else {
        setSimulationResultLoading(false);
      }
    }
  }, [simulation?.id, simulation?.[resultsKey]]);

  return { simulationRiskDriverResult, simulationRiskDriverResultLoading };
};

export const useSimulation = (siteId: string, planId?: string) => {
  const [simulation, setSimulation] = useState<ISimulation>();
  const [simulationRequested, setSimulationRequested] =
    useState<boolean>(false);
  const [simulationLoading, setSimulationLoading] = useState<boolean>(false);
  const userId = useAppSelector(selectUserId);

  const organisationSites = useAppSelector(selectOrganisationSites);
  const site = organisationSites[siteId] as ISite;

  const isMounted = useIsMounted();

  const handleRequestSimulation = () => {
    if (planId) {
      setSimulationRequested(true);
      triggerSimulation(planId, siteId, userId, site).catch((error) => {
        console.error(error);
        setSimulationRequested(false);
      });
    }
  };

  useEffect(() => {
    if (planId) {
      setSimulation(undefined);
      setSimulationLoading(true);
      const simQuery = getLastSimulationQuery(planId, siteId);
      const unsubscribe = onSnapshot(simQuery, (snapshot) => {
        const lastSim = snapshot.docs.pop();

        if (isMounted()) {
          if (lastSim?.exists()) {
            if (lastSim?.id !== simulation?.id) {
              setSimulationRequested(false);
            }
            setSimulation(transformSimulationDoc(lastSim.id, lastSim.data()));
          }
          setSimulationLoading(false);
        }
      });
      return () => unsubscribe();
    } else {
      setSimulation(undefined);
      setSimulationLoading(false);
    }
  }, [planId]);
  return {
    simulation,
    simulationLoading: simulationLoading || simulationRequested,
    requestSimulation: handleRequestSimulation,
  };
};

export const useMitigationPlanThresholdsByBaseId = (
  siteId?: string,
  selectedPlanId?: string,
  mitigationPlanId?: string
) => {
  const [
    mitigationPlanThresholdsByBaseId,
    setMitigationPlanThresholdsByBaseId,
  ] = useState<Map<string, IThresholdUpdate>>();
  useEffect(() => {
    if (mitigationPlanId && siteId) {
      const tasksQuery = getTasksQuery(mitigationPlanId);
      const unsubscribe = onSnapshot(tasksQuery, (snapshotDocs) => {
        if (snapshotDocs.empty) {
          setMitigationPlanThresholdsByBaseId(new Map());
        } else {
          const update = new Map<string, IThresholdUpdate>(
            mitigationPlanThresholdsByBaseId
          );
          Promise.all(
            snapshotDocs.docs.map(async (doc, i) => {
              if (i % 250 === 0) {
                await yieldMainThread();
              }
              const data = doc.data();
              if (data.isMitigated) {
                const baseTaskId = getPlanTaskId(doc.id, selectedPlanId);
                const thresholds: IThresholdUpdate = {};
                for (const key in WeatherKey) {
                  if (data[key]) {
                    thresholds[key as WeatherKey] = Number(data[key]);
                  }
                }
                update.set(baseTaskId, thresholds);
              }
            })
          );
          setMitigationPlanThresholdsByBaseId(update);
        }
      });
      return () => unsubscribe();
    }
  }, [siteId, selectedPlanId, mitigationPlanId]);
  return { mitigationPlanThresholdsByBaseId };
};
export const hasMitigation = (
  task: ITask & { simulation: SimulationTaskResult },
  mitigationTaskId: string,
  mitigationPlan?: IPlan,
  mitigationPlanThresholdsByBaseId?: Map<string, any>,
  mitigationSimulationResult?: SimulationResult,
  editedMitigations?: any,
  mitigations?: any
) => {
  const mitigatedThresholds =
    mitigationPlanThresholdsByBaseId &&
    mitigationPlanThresholdsByBaseId.get(task.id);
  if (!mitigatedThresholds) return false;
  const mitigationTaskResult =
    mitigationSimulationResult?.taskResults?.[mitigationTaskId];
  const mitigatedTask = mitigationPlan && {
    ...mitigatedThresholds,
    ...mitigationTaskResult,
  };
  const mitigationThresholdDisplay = {
    ...(mitigatedTask || {}),
    ...editedMitigations,
    ...mitigations,
  };
  thresholdCategories.forEach(({ key }) => {
    if (
      Number(mitigationThresholdDisplay[key]) === Number(task[key]) ||
      typeof mitigationThresholdDisplay[key] === "undefined"
    ) {
      delete mitigationThresholdDisplay[key];
    }
  });
  return (
    thresholdCategories
      .map(({ key }) => mitigationThresholdDisplay[key])
      .filter(Boolean).length > 0
  );
};

export const useMitigationsVisibleTasks = (
  titleFilter: string,
  showOnlyImpactedTasks: boolean,
  tasks: (ITask & { simulation: SimulationTaskResult })[],
  comparisonResultsByBaseTaskId: {
    [id: string]: ITask & { simulation: SimulationTaskResult };
  },
  showOnlyActivitiesWithMitigations: boolean,
  selectedSimLevel: SimulationLevel,
  mitigationPlan?: IPlan,
  mitigationPlanThresholdsByBaseId?: Map<string, any>,
  mitigationSimulationResult?: SimulationResult,
  pendingMitigations?: any,
  newMitigations?: Record<string, IThresholdUpdate>
) => {
  const visibleTasks = useMemo(() => {
    const lowerCaseSearchInput = titleFilter?.toLowerCase();
    const leafTasks = tasks.filter(({ WBS }) => !WBS);

    let visibleTasks = !showOnlyImpactedTasks
      ? leafTasks
      : leafTasks.filter((task) => {
          const {
            simulation: { riskIntervalResults },
            id,
          } = task;
          const comparisonWeatherDays =
            comparisonResultsByBaseTaskId[id]?.simulation[
              "riskIntervalResults"
            ]?.[selectedSimLevel]?.weatherDays;
          return (
            !!riskIntervalResults?.[selectedSimLevel]["weatherDays"] ||
            comparisonWeatherDays
          );
        });
    visibleTasks = lowerCaseSearchInput
      ? visibleTasks.filter(
          (t) =>
            t.title.toLowerCase().includes(lowerCaseSearchInput) ||
            t.taskType?.toLowerCase().includes(lowerCaseSearchInput)
        )
      : visibleTasks;

    visibleTasks = showOnlyActivitiesWithMitigations
      ? visibleTasks.filter((t) => {
          const mitigationTaskId = getPlanTaskId(t.id, mitigationPlan?.id);
          const editedMitigations = pendingMitigations?.[t.id];
          const mitigations = newMitigations?.[t.id];
          const hasMit = hasMitigation(
            t,
            mitigationTaskId,
            mitigationPlan,
            mitigationPlanThresholdsByBaseId,
            mitigationSimulationResult,
            editedMitigations,
            mitigations
          );
          return hasMit;
        })
      : visibleTasks;

    const WBS = tasks.filter(({ WBS }) => WBS);
    const allWBS: (ITask & { simulation: SimulationTaskResult })[] = [];
    let currentWBS = visibleTasks || [];
    while (
      currentWBS.filter(
        ({ parentId, internalParentId }) => parentId && internalParentId
      ).length > 0
    ) {
      const temp = currentWBS;
      currentWBS = [];
      WBS.forEach((WBS) => {
        const findParent = temp.find(
          ({ parentId, internalParentId }) =>
            internalParentId === WBS.id ||
            parentId === WBS.id ||
            parentId === WBS.objectId
        );
        findParent && allWBS.push(WBS);
        findParent && currentWBS.push(WBS);
      });
      visibleTasks = [...visibleTasks, ...allWBS];
    }

    return Array.from(new Set(visibleTasks)).sort(
      ({ wbsHierarchyOrder: a }, { wbsHierarchyOrder: b }) => a! - b!
    );
  }, [
    tasks,
    comparisonResultsByBaseTaskId,
    showOnlyImpactedTasks,
    titleFilter,
    showOnlyActivitiesWithMitigations,
    mitigationPlan,
    mitigationPlanThresholdsByBaseId,
    mitigationSimulationResult,
    pendingMitigations,
    newMitigations,
    selectedSimLevel,
  ]);
  return { visibleTasks };
};

const hasDifference = (
  task: ITask & { simulation: SimulationTaskResult },
  varianceTaskResult: ITask & { simulation: SimulationTaskResult },
  selectedSimLevel: SimulationLevel
) => {
  const basePlannedStartDate = task?.start;
  const baseProposedStartDate =
    task?.simulation.riskIntervalResults?.[selectedSimLevel]?.startDate ||
    basePlannedStartDate;

  const variancePlannedStartDate = varianceTaskResult?.start;
  const varianceProposedStartDate =
    varianceTaskResult?.simulation.riskIntervalResults?.[selectedSimLevel]
      ?.startDate || variancePlannedStartDate;

  const basePlannedEndDate = task?.end;
  const baseProposedEndDate =
    task?.simulation.riskIntervalResults?.[selectedSimLevel]?.endDate ||
    basePlannedEndDate;

  const variancePlannedEndDate = varianceTaskResult?.end;
  const varianceProposedEndDate =
    varianceTaskResult?.simulation.riskIntervalResults?.[selectedSimLevel]
      ?.endDate || variancePlannedEndDate;
  return (
    (!!varianceProposedStartDate &&
      moment(varianceProposedStartDate)
        .startOf("day")
        .diff(moment(baseProposedStartDate).startOf("day"), "days") !== 0) ||
    (!!variancePlannedStartDate &&
      moment(variancePlannedStartDate)
        .startOf("day")
        .diff(moment(basePlannedStartDate).startOf("day"), "days") !== 0) ||
    (!!varianceProposedEndDate &&
      moment(varianceProposedEndDate)
        .startOf("day")
        .diff(moment(baseProposedEndDate).startOf("day"), "days") !== 0) ||
    (!!variancePlannedEndDate &&
      moment(variancePlannedEndDate)
        .startOf("day")
        .diff(moment(basePlannedEndDate).startOf("day"), "days") !== 0) ||
    // DH TODO: add additional weather keys to variance
    (varianceTaskResult?.simulation.riskIntervalResults?.[selectedSimLevel]
      ?.weatherDays || 0) -
      (task?.simulation.riskIntervalResults?.[selectedSimLevel]?.weatherDays ||
        0) !==
      0 ||
    ((varianceTaskResult as ITask & { simulation: SimulationTaskResult })
      ?.minTemp || 0) -
      (task?.minTemp || 0) !==
      0 ||
    ((varianceTaskResult as ITask & { simulation: SimulationTaskResult })
      ?.maxTemp || 0) -
      (task?.maxTemp || 0) !==
      0 ||
    ((varianceTaskResult as ITask & { simulation: SimulationTaskResult })
      ?.wind || 0) -
      (task?.wind || 0) !==
      0 ||
    ((varianceTaskResult as ITask & { simulation: SimulationTaskResult })
      ?.dailyRainAcc || 0) -
      (task?.dailyRainAcc || 0) !==
      0 ||
    ((varianceTaskResult as ITask & { simulation: SimulationTaskResult })
      ?.hourlyRainAcc || 0) -
      (task?.hourlyRainAcc || 0) !==
      0
  );
};

const hasCalculatedTasks = (
  calculateTasks: (ITask & { simulation: SimulationTaskResult })[],
  c: ITask & { simulation: SimulationTaskResult }
): boolean => {
  const child = calculateTasks.find((t) => t.internalParentId === c.id);
  return child
    ? !child?.WBS
      ? true
      : hasCalculatedTasks(calculateTasks, child)
    : false;
};

export const useVarianceVisibleTasks = (
  allTasks: {
    [id: string]: ITask & { simulation: SimulationTaskResult };
  },
  baseTasks: (ITask & { simulation: SimulationTaskResult })[],
  showOnlyImpactedTasks: boolean,
  titleFilter: string,
  comparisonResultsByBaseTaskId: {
    [id: string]: ITask & { simulation: SimulationTaskResult };
  },
  showOnlyDifferenceTasks: boolean,
  selectedSimLevel: SimulationLevel
) => {
  const visibleTasks = useMemo(() => {
    const allTasksList =
      Object.keys(allTasks).length > 0
        ? Object.keys(allTasks).map((key) => allTasks[key])
        : baseTasks;
    let calculateTasks = !showOnlyImpactedTasks
      ? allTasksList
      : allTasksList.filter(
          ({ riskIntervalResults, id, WBS }: any) =>
            WBS || !!riskIntervalResults || comparisonResultsByBaseTaskId[id]
        );

    const lowerTitleFilter = titleFilter?.toLowerCase();
    calculateTasks = titleFilter
      ? calculateTasks.filter(
          (t) => t.WBS || t.title.toLowerCase().includes(lowerTitleFilter)
        )
      : calculateTasks;
    calculateTasks = showOnlyDifferenceTasks
      ? calculateTasks.filter((c) => {
          const task = baseTasks.find((t) => t.id === c.id) ? c : undefined;
          const varianceTaskResult = task
            ? comparisonResultsByBaseTaskId[task.id]
            : c;
          return task?.WBS
            ? true
            : task &&
                varianceTaskResult &&
                hasDifference(task, varianceTaskResult, selectedSimLevel);
        })
      : calculateTasks;
    calculateTasks = calculateTasks.filter((c) =>
      !c.WBS ? true : hasCalculatedTasks(calculateTasks, c)
    );
    return calculateTasks;
  }, [
    baseTasks,
    comparisonResultsByBaseTaskId,
    titleFilter,
    showOnlyImpactedTasks,
    allTasks,
    showOnlyDifferenceTasks,
    selectedSimLevel,
  ]);
  return { visibleTasks };
};

export const getIsSiteActive = (site: ISite) => {
  let isSiteActive = site.status === "open" && !site.deletedAt;
  if (site.endDate) {
    const startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
    isSiteActive = isSiteActive && site.endDate > startOfToday;
  }
  return isSiteActive;
};

export const useSiteNotificationStatuses = (sites: ISite[]) => {
  const {
    isLoading: loadingNotificationType,
    data: notificationTypeConfig,
    error: errorNotificationType,
  } = useDocQuery<NotificationType>([
    CollectionType.NotificationTypes,
    NotificationTypeEnum.SiteForecast,
  ]);

  const userId = useAppSelector(selectUserId);
  const claims = useAppSelector(selectUserClaims);

  if (!userId || !claims) {
    throw new Error("User not found");
  }

  const { role: sessionUserRole } = claims;

  const {
    isLoading: loadingNotificationConfigs,
    data: notificationConfigs,
    error: errorNotificationConfigs,
  } = useCollectionQuery<NotificationConfig>(
    `${CollectionType.Users}/${userId}/${CollectionType.NotificationConfig}`
  );

  const siteStatuses = useMemo(() => {
    const statuses = new Map<
      string,
      { enabled: boolean; sourceConfig?: NotificationConfig }
    >();
    sites.forEach((site) => {
      const { id, owner, project } = site;
      const userConfig = notificationConfigs?.find(
        (config) => config.siteId === id
      );
      if (userConfig) {
        statuses.set(id, {
          enabled: userConfig.enabled,
          sourceConfig: userConfig,
        });
      } else if (getIsSiteActive(site)) {
        const siteIsPersonal = !project && owner === userId;
        const isDefaultEnabled =
          siteIsPersonal ||
          notificationTypeConfig?.defaultRecipients?.some(
            ({ role: defaultRole }) => sessionUserRole === defaultRole
          );
        statuses.set(id, {
          enabled: !!isDefaultEnabled,
        });
      } else {
        statuses.set(id, {
          enabled: false,
        });
      }
    });
    return statuses;
  }, [
    sites,
    notificationConfigs,
    notificationTypeConfig,
    userId,
    sessionUserRole,
  ]);

  return {
    siteNotificationStatuses: siteStatuses,
    loading: loadingNotificationType || loadingNotificationConfigs,
    error: errorNotificationType || errorNotificationConfigs,
  };
};

export const useSafranMapping = (planId: string, orgId: string) => {
  const GenerateSafranDowntime = firebaseFunction<{
    safranDowntimeXml: string;
  }>("GenerateSafranDowntime");
  const SafranProjectMapping = firebaseFunction<{ message: string }>(
    "SafranProjectMapping"
  );
  const SafranRiskMapping = firebaseFunction<{ message: string }>(
    "SafranRiskMapping"
  );

  const [isMappingProject, setIsMappingProject] = useState(false);
  const [unknownProjectFile, setUnknownProjectFile] = useState<boolean>(false);
  const [projectFile, setprojectFile] = useState<File>();

  const disableProjectFunction =
    !projectFile || isMappingProject || unknownProjectFile;

  const [isMappingRisk, setIsMappingRisk] = useState(false);
  const [unknownRiskFile, setUnknownRiskFile] = useState<boolean>(false);
  const [riskFile, setRiskFile] = useState<File>();
  const disableRiskFunction = !riskFile || isMappingRisk || unknownRiskFile;

  const userId = useAppSelector(selectUserId);

  const [exportingSafran, setExportingSafran] = useState(false);
  const downloadSafranDowntime = (plan: string, site: string) => {
    logExportEvent({
      siteId: site,
      exportType: ExportTypes.SafranRiskCalendar,
    });
    setExportingSafran(true);
    updateDoc(doc(db, "plans", plan), {
      "generatedFiles.safranDowntimeXml": "",
    });
    GenerateSafranDowntime({
      plan,
      site,
    });
    return onSnapshot(doc(db, "plans", planId), async (snapshot) => {
      if (snapshot && snapshot.data()) {
        const downtimeXml = snapshot.data()?.generatedFiles.safranDowntimeXml;
        let siteName: string | undefined = undefined;
        const site = await getDoc(doc(db, 'sites', snapshot.data()!.site))
        const siteData = site.data()
        if(siteData) {
          siteName = siteData.name
        }
        if (!downtimeXml || downtimeXml.length == 0) return;

        // download file
        const storage = getStorage(undefined, `gs://${config.STORAGE_BUCKET}`);
        const fileRef = ref(storage, downtimeXml);

        let fileName = `safran_downtime_${planId}.xml`
        if(siteName) {
          fileName = `safran_downtime_${siteName}.xml`
        }
        if (fileRef) {
          getBlob(fileRef)
            .then((blob) => {
              fileDownload(
                blob,
                fileName,
                "application/xml"
              );
            })
            .catch((error) => {
              console.error(
                error.code,
                `could not download safran downtime for plan ${planId}`
              );
            });
        } else {
          console.error("could not download file");
        }
        setExportingSafran(false);
      }
    });
  };

  const submitFile = (
    type: string,
    file: File,
    disable: boolean,
    setIsMapping: Function,
    logEvent: Function,
    safranFBFunction: Function,
    cleanupFunction?: Function
  ) =>
    useCallback(async () => {
      if (disable) return;
      setIsMapping(true);
      logEvent({ planId, orgId });

      const fileName = `${planId}_${userId}_${type}_${new Date().getTime()}.xlsx`;
      const storage = getStorage(undefined, `gs://${config.CACHE_BUCKET}`);

      await uploadBytes(
        ref(
          storage,
          `client_data/${orgId}/weather_calendars/safran/${fileName}`
        ),
        file!
      );
      const {
        data: { message },
      } = await safranFBFunction({
        userId,
        planId,
        orgId,
        fileName,
      });

      const mappedFileName = JSON.parse(message).mappedFileName;
      const fileRef = ref(
        storage,
        `client_data/${orgId}/weather_calendars/safran/${mappedFileName}`
      );

      if (fileRef) {
        await getBlob(fileRef)
          .then((blob) =>
            fileDownload(blob, `mapped_${file!.name}`, "application/xml")
          )
          .catch((error) =>
            console.error(
              error.code,
              `could not download project file ${mappedFileName} for plan ${planId}: ${error.message}`
            )
          );
      } else {
        console.error("could not download file");
      }

      cleanupFunction && cleanupFunction(true);
    }, [file]);

  const submitProjectFile = submitFile(
    "project",
    projectFile!,
    disableProjectFunction,
    setIsMappingProject,
    logSafranProjectEvent,
    SafranProjectMapping,
    () => {
      setIsMappingProject(false);
      setprojectFile(undefined);
    }
  );
  const submitRiskFile = submitFile(
    "risk",
    riskFile!,
    disableRiskFunction,
    setIsMappingRisk,
    logSafranRiskEvent,
    SafranRiskMapping,
    () => {
      setIsMappingRisk(false);
      setRiskFile(undefined);
    }
  );

  const handleFile = (
    event: ChangeEvent<HTMLInputElement>,
    setUnknown: Function,
    setFile: Function
  ) => {
    if (!event || !event.target || !event.target.files) return; //error?
    const file = event.target.files[0];
    const fileType = file.name.split(".").at(-1)?.toLocaleUpperCase();
    setUnknown(fileType !== "XLSX");
    setFile(file);
  };

  const handleProjectFile = (event: ChangeEvent<HTMLInputElement>) =>
    handleFile(event, setUnknownProjectFile, setprojectFile);
  const handleRiskFile = (event: ChangeEvent<HTMLInputElement>) =>
    handleFile(event, setUnknownRiskFile, setRiskFile);

  return {
    downloadSafranDowntime,
    exportingSafran,

    isMappingProject,
    projectFileSet: !!projectFile,
    handleProjectFile,
    submitProjectFile,

    isMappingRisk,
    riskFileSet: !!riskFile,
    handleRiskFile,
    submitRiskFile,
  };
};

export const useTaskDocs = (siteId: string, planId?: string) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [taskDocs, setTaskDocs] =
    useState<QueryDocumentSnapshot<DocumentData, DocumentData>[]>();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (siteId && planId) {
      setLoading(true);
      setError("");
      setTaskDocs(undefined);
      fetchSiteTaskDocs(planId)
        .then((taskDocs) => {
          if (isMounted()) {
            if (taskDocs?.length) {
              setTaskDocs(taskDocs);
            } else {
              setTaskDocs([]);
            }
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          isMounted() && setError(error.message);
        });
    }
  }, [siteId, planId]);

  return {
    isLoading,
    taskDocs,
    error,
  };
};

export const useProcessedTasks = (
  siteId: string,
  categories?: ITaskCategory[],
  taskDocs?: QueryDocumentSnapshot<DocumentData, DocumentData>[],
  simulationResult?: SimulationResult
) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [tasks, setTasks] =
    useState<(ITask & { simulation: SimulationTaskResult })[]>();
  const [milestones, setMilestones] =
    useState<(ITask & { simulation: SimulationTaskResult })[]>();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (siteId && taskDocs && categories) {
      setLoading(true);
      setError("");
      setTasks(undefined);

      if (isMounted() && taskDocs?.length) {
        processRawTasks(taskDocs, categories, simulationResult?.taskResults)
          .then((tasks) => {
            if (isMounted()) {
              setTasks(tasks.simulatedTasks);
              setMilestones(tasks.milestones);
            }
          })
          .finally(() => {
            if (isMounted()) {
              setLoading(false);
            }
          });
      } else {
        setTasks([]);
        setLoading(false);
      }
    }
  }, [siteId, taskDocs, simulationResult, categories]);

  return {
    isLoading,
    tasks,
    milestones,
    error,
  };
};

export const useComparisonImpactedTasksNotInBase = (
  baseTasks?: (ITask & { simulation: SimulationTaskResult })[],
  comparisonSimulationResults?:
    | (ITask & { simulation: SimulationTaskResult })[]
    | undefined,
  comparisonPlanId?: string,
  basePlanId?: string
) => {
  const [comparisonResultsByBaseTaskId, setComparisonResultsByBaseTaskId] =
    useState<{ [id: string]: ITask & { simulation: SimulationTaskResult } }>(
      {}
    );
  const [comparisonTaskResultsNotInBase, setComparisonTaskResultsNotInBase] =
    useState<{ [id: string]: ITask & { simulation: SimulationTaskResult } }>(
      {}
    );

  const [allTasks, setAllTasks] = useState<{
    [id: string]: ITask & { simulation: SimulationTaskResult };
  }>({});
  const params = useParams() as { "*": keyof typeof pageTitles };

  const { "*": currentPage } = params;
  const taskResultsById = useMemo(() => {
    const taskResults: {
      [id: string]: ITask & { simulation: SimulationTaskResult };
    } = {};
    comparisonSimulationResults &&
      comparisonSimulationResults.forEach((k) => (taskResults[k.id] = k));
    return taskResults;
  }, [comparisonSimulationResults]);
  useEffect(() => {
    setComparisonResultsByBaseTaskId({});
    setComparisonTaskResultsNotInBase({});
    setAllTasks({});
    if (
      baseTasks &&
      comparisonSimulationResults &&
      comparisonPlanId &&
      basePlanId
    ) {
      getComparisonImpactedTasksNotInBase(
        baseTasks,
        taskResultsById,
        comparisonSimulationResults,
        comparisonPlanId,
        basePlanId,
        currentPage === "mitigations"
      ).then(
        ({
          comparisonResultsByBaseTaskId,
          comparisonTaskResultsNotInBase,
          allTasks,
        }: {
          comparisonResultsByBaseTaskId: {
            [id: string]: ITask & { simulation: SimulationTaskResult };
          };
          comparisonTaskResultsNotInBase: {
            [id: string]: ITask & { simulation: SimulationTaskResult };
          };
          allTasks: {
            [id: string]: ITask & { simulation: SimulationTaskResult };
          };
        }) => {
          setComparisonResultsByBaseTaskId(comparisonResultsByBaseTaskId);
          setComparisonTaskResultsNotInBase(comparisonTaskResultsNotInBase);
          setAllTasks(allTasks);
        }
      );
    }
  }, [baseTasks, comparisonSimulationResults, comparisonPlanId, basePlanId]);

  return {
    comparisonResultsByBaseTaskId,
    comparisonTaskResultsNotInBase,
    allTasks,
  };
};
