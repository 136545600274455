import { useState } from "react";
import {
  generatePDF,
  generateWeatherBaselineReport,
  subscribeToReportURL,
} from "./helpers";

interface BaselinePDFProps {
  siteId?: string;
  projectId?: string;
  checkOwnership?: boolean;
  onSuccess?: () => void;
}

export const useDownloadWB = ({
  siteId,
  projectId,
  onSuccess,
}: BaselinePDFProps) => {
  const [pdfGenerating, setPDFGenerating] = useState<boolean>(false);
  const [pdfDownloading, setPDFDownloading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const loadingFailed = () => {
    setPDFDownloading(false);
    setPDFGenerating(false);
    setError(
      "An error occured while generating the weather baseline report. Please try again later."
    );
  };

  const downloadPlan = async (planId: string) => {
    if (!siteId || !projectId || !planId) {
      setError("Unknown failure");
      return;
    }
    setPDFGenerating(true);
    try {
      const WBPlanId = await generateWeatherBaselineReport({
        plan: planId,
        site: siteId,
        project: projectId,
      });
      await generatePDF({
        WBplan: WBPlanId,
        site: siteId,
        project: projectId,
      });
      setPDFGenerating(false);
      setPDFDownloading(true);
      subscribeToReportURL({
        WBPlanId,
        handleSuccess: () => {
          setPDFDownloading(false);
          onSuccess && onSuccess();
        },
        handleError: loadingFailed,
      });
    } catch (err) {
      console.error(err);
      loadingFailed();
    }
  };

  return {
    downloadPlan,
    pdfGenerating,
    pdfDownloading,
    error,
    resetError: () => setError(null),
  };
};
