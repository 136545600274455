//React
import React, { FC } from "react";
//Styles
import { StyledWorkDays } from "./styles";
//Store
import { useAppSelector } from "store";
import { selectWorkDays, selectWorkHoursModalOpen } from "store/calendar";
//Components
import WorkDayRow from "./WorkDayRow";
import ChangeWorkHoursDialog from "./ChangeWorkHoursDialog";

const WorkDays: FC = () => {
  const workDays = useAppSelector(selectWorkDays);

  const workHoursModalOpen = useAppSelector(selectWorkHoursModalOpen);

  return (
    <StyledWorkDays>
      <h2>Work Days</h2>

      <div>
        {workDays.map((day) => (
          <WorkDayRow day={day} key={day.day} />
        ))}
      </div>

      {workHoursModalOpen && (
        <ChangeWorkHoursDialog open={workHoursModalOpen} />
      )}
    </StyledWorkDays>
  );
};

export default WorkDays;
