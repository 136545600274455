import styled from "styled-components";
import { spacing, grays, flex, fontSize, replaceOutline, colors } from "styles";

export const StyledHolidays = styled.div`
  ${flex("row", "flex-start", "flex-start")};
  padding-bottom: ${spacing.s};

  h2 {
    width: 25%;
  }

  > div {
    width: 75%;

    > ul {
      max-height: 30rem;
      overflow-y: auto;

      > li {
        ${flex("row", "flex-start", "center")};
        width: 100%;
        list-style: none;
        padding-bottom: ${spacing.m};

        .date {
          width: 12rem;
        }

        .delete-button {
          ${flex("row", "center", "center")};
          ${replaceOutline()};
          font-size: ${fontSize.m};
          background: none;
          border: none;
          cursor: pointer;
          color: ${colors.major};
        }
      }
    }

    .picker-container {
      width: 30rem;
      padding-top: ${spacing.m};
      border-top: 0.1rem solid ${grays.veryLight};
    }
  }
`;
