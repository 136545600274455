import "main.css";
import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import App from "./App";
import Provider from "store";
import config from "config";
import { init } from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (config.SHOULD_USE_SENTRY) {
  init({
    dsn: config.SENTRY_DSN,
    environment: config.BUILD_ENVIRONMENT,
    integrations: [new BrowserTracing()],
  });
}

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
