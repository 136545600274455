import { Weather } from "@ehabitation/ui";
import { FC } from "react";
import moment from "moment";
import {
  WeatherThresholds,
  IDailyForecast,
} from "@ehabitation/ts-utils/browser";

const dayString = (date: Date) => {
  const withTime = moment(date)
    .startOf("day")
    .calendar({
      nextDay: "ddd",
      nextWeek: "ddd",
      sameElse: function (now) {
        return (this as any).diff(now, "days") >= 13 ? "D MMM" : "[Next] ddd";
      },
    });
  return withTime.split(" at ")[0];
};

export const ForecastDay: FC<{
  data: IDailyForecast;
  weatherMap: Record<string, FC>;
  thresholds?: WeatherThresholds;
}> = ({ data, weatherMap, thresholds }) => {
  const isToday = moment(data.dateReference).isSame(moment(), "day");
  return (
    <div className="sm:p-2 sm:pl-4 sm:pr-8 flex gap-4 items-end bg-white">
      <div className="inline-flex self-center items-center flex-col min-w-[10rem] bg-blue-200">
        <h3>{isToday ? "Today" : dayString(data.dateReference)}</h3>
        <div
          style={{ backgroundImage: `url(${weatherMap[data.weatherCode]})` }}
          className="w-10 h-10 bg-contain"
        />
        <p className="text-xl font-[500] ">{data.weatherCode}</p>
      </div>
      <div
        className="flex gap-8 items-start flex-grow"
        id="usetiful_forecast_day_thresholds"
      >
        <Weather.Temp data={data} thresholds={thresholds} />
        <Weather.Wind data={data} thresholds={thresholds} />
        <Weather.Precipitation data={data} thresholds={thresholds} />
      </div>
    </div>
  );
};
