import React, { useState, FC } from "react";
import { FiAlertCircle, FiX } from "react-icons/fi";

interface TabContainerProps {
  tabMap: Record<string, FC>;
  id: string;
}

export const TabContainer: FC<TabContainerProps> = ({ tabMap, id }) => {
  const tabs = Object.keys(tabMap);
  const [activeTab, setActiveTab] = useState<string>(tabs[0]);
  const Tab = tabMap[activeTab];
  return (
    <div className="overflow-hidden w-full" data-testid={`${id}-settings`}>
      <div
        className="flex flex-row justify-start items-center"
        style={{ marginBottom: "-1px" }}
      >
        {tabs.map((item) => (
          <button
            key={item}
            role="tab"
            onClick={() => setActiveTab(item)}
            className={`transition-colors ${
              activeTab === item
                ? "border border-slate-400 border-b-white cursor-default selected"
                : "hover:bg-gray-100"
            } bg-white px-4 py-8 w-60 text-2xl`}
          >
            <h3>{item}</h3>
          </button>
        ))}
      </div>

      <div className="border border-slate-400 px-4 py-4">
        <Tab />
      </div>
    </div>
  );
};
