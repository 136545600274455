import Initialising from "Components/Initialising/Initialising";
import Plan from "Pages/projects/Plan";
import Settings from "Pages/projects/settings/Settings";
import { useInitProject, useTaskTypes } from "hooks";
import { FC } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { useAppSelector } from "store";
import { selectCurrentSite } from "store/project";

interface ProjectRoutesProps {
  wbModalOpen: boolean;
  setWBModalOpen: (open: boolean) => void;
  categoriseOpen: boolean;
  setCategoriseOpen: (open: boolean) => void;
  planImportOpen: boolean;
  setPlanImportOpen: (open: boolean) => void;
  riskExportOpen: boolean;
  setRiskExportOpen: (open: boolean) => void;
}

const ProjectRoutes: FC<ProjectRoutesProps> = ({
  wbModalOpen,
  setWBModalOpen,
  categoriseOpen,
  setCategoriseOpen,
  planImportOpen,
  setPlanImportOpen,
  riskExportOpen,
  setRiskExportOpen,
}) => {
  const { projectId } = useParams();

  useInitProject(projectId);
  useTaskTypes(projectId);

  const currentLoadedSite = useAppSelector(selectCurrentSite);

  const workspaceReady =
    !!currentLoadedSite && currentLoadedSite.project === projectId;

  return (
    <Routes>
      <Route path="" element={<Navigate to="plan" replace />}></Route>
      <Route path="settings" element={<Settings />} />
      <Route
        path="plan"
        element={
          !workspaceReady ? (
            <Initialising>
              <p>Loading Project Data...</p>
            </Initialising>
          ) : (
            <Plan
              wbModalOpen={wbModalOpen}
              setWBModalOpen={setWBModalOpen}
              planImportOpen={planImportOpen}
              setPlanImportOpen={setPlanImportOpen}
              categoriseOpen={categoriseOpen}
              setCategoriseOpen={setCategoriseOpen}
              riskExportOpen={riskExportOpen}
              setRiskExportOpen={setRiskExportOpen}
            />
          )
        }
      />
    </Routes>
  );
};
export default ProjectRoutes;
