const viteMode = import.meta.env.MODE;
const commitHash = import.meta.env.REACT_APP_COMMIT_HASH;

const buildEnvironment =
  viteMode === "development" && !commitHash ? "local" : viteMode;

window.location.host;

const config = {
  APP_ID: import.meta.env.REACT_APP_APP_ID,
  API_KEY: import.meta.env.REACT_APP_API_KEY,
  AUTH_DOMAIN: import.meta.env.REACT_APP_AUTH_DOMAIN,
  DATABASE_URL: import.meta.env.REACT_APP_DATABASE_URL,
  PROJECT_ID: import.meta.env.REACT_APP_PROJECT_ID,
  STORAGE_BUCKET: import.meta.env.REACT_APP_STORAGE_BUCKET,
  MESSAGING_SENDER_ID: import.meta.env.REACT_APP_MESSAGING_SENDER_ID,
  MEASUREMENT_ID: import.meta.env.REACT_APP_MEASUREMENT_ID,
  CACHE_BUCKET: import.meta.env.REACT_APP_CACHE_BUCKET,
  WEATHER_GRAPH_URL: import.meta.env.REACT_APP_WEATHER_GRAPH_URL,
  BACKEND_REGION: import.meta.env.REACT_APP_BACKEND_REGION,
  COMMIT_HASH: import.meta.env.REACT_APP_COMMIT_HASH,
  BUILD_ENVIRONMENT: buildEnvironment,
  RECAPTCHA_KEY: import.meta.env.REACT_APP_RECAPTCHA_KEY,
  REGISTRATION_RECAPTCHA_KEY: import.meta.env
    .REACT_APP_REGISTRATION_RECAPTCHA_KEY,
  SENTRY_DSN: import.meta.env.REACT_APP_SENTRY_DSN,
  APPCHECK_E2E_OVERIDE: import.meta.env.REACT_APP_APPCHECK_E2E_OVERIDE,
  SHOULD_USE_SENTRY: ["app.ehab.co", "qa.ehab.co"].includes(
    window.location.host
  ),
};

export default config;
