import { FC, useState } from "react";

import {
  IPlan,
  ISite
} from "@ehabitation/ts-utils/browser";
import { Tooltip } from "@material-ui/core";
import DiscardAlert from "Components/Plan/PlanControls/DiscardAlert";
import { PlanSelector } from "Components/Plan/PlanControls/PlanSelector";
import { setAsMainPlan } from "Components/Plan/PlanControls/thunks";
// import { SimulationModal } from "Pages/sites/components/SimulationModal";
import { useSimulation } from "Pages/sites/hooks";
import { discardPlan } from "helpers/firebase/plans";
import { BiHelpCircle, BiPrinter } from "react-icons/bi";
import { BsInfoCircle } from "react-icons/bs";
import { NavLink, Outlet, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { selectRole } from "store/auth";
import { selectOrganisationProjects } from "store/siteManagement";

export const pageTitles = {
  "import-plan": "Import a plan",
  "settings/thresholds": "Set thresholds",
  simulate: "Run simulation",
  plan: "Plan overview",
  "tra-qsra": "Weather days breakdown (TRA)",
  "risk-drivers": "Identify risks",
  mitigations: "Mitigate risks",
  "weather-calendars-qsra": "Weather calendars (QSRA)",
  variance: "Manage change",
  weather: "Forecast weather windows",
  "settings/compensation-events": "Compensation Events",
};

export const MainWrapper: FC<{
  site: ISite;
  selectedPlan?: IPlan;
  selectableBasePlans?: IPlan[];
  setSelectedPlanId: (id: string) => void;
  printableLink?: string;
  helpLink?: string;
  syncThresholdsMain: (projectId: string, planId: string) => void;
  setPlanImportOpen: (isOpen: boolean) => void;
  selectedRiskMatrixType: string;
  setSelectedRiskMatrixType: (rmType: string) => void;
}> = ({
  site,
  selectedPlan,
  selectableBasePlans,
  setSelectedPlanId,
  printableLink,
  helpLink,
}) => {
  const dispatch = useAppDispatch();

  const projects = useAppSelector(selectOrganisationProjects);

  const userRole = useAppSelector(selectRole);

  const siteProject = projects[site.project];

  const [discardPlanId, setDiscardPlanId] = useState("");

  const { simulation, simulationLoading } = useSimulation(
    site.id,
    selectedPlan?.id
  );

  const params = useParams() as { "*": keyof typeof pageTitles };

  const { "*": currentPage } = params;

  return (
    <div className="overflow-y-hidden w-full mb-2 sm:pl-10 md:pl-0 ">
      <div
        className={`w-full min-w-0 h-full px-3 sm:px-4 md:px-6 py-4 sm:py-6 md:py-8 flex flex-col gap-2 sm:gap-4 md:gap-6`}
      >
        <div className="w-full flex justify-between items-start">
          <div className="flex gap-3 items-baseline">
            <h2 className="text-3xl">{pageTitles[currentPage]}</h2>
            {currentPage === "weather" && (
              <Tooltip
                arrow
                title={
                  <span className="text-xl">
                    <h3 className="pb-4">Colour Legend</h3>
                    <div className="flex align-middle gap-2 items-baseline pb-2">
                      <div className="w-4 h-4 bg-rose-300"></div>
                      <div>At least one threshold is broken</div>
                    </div>
                    <div className="flex align-top gap-2 items-baseline pb-2">
                      <div className="w-4 h-4 bg-amber-300"></div>
                      <div>
                        Forecasted wind is within 5 m/sec of the threshold.
                      </div>
                    </div>
                    <div className="flex align-top gap-2 items-baseline pb-2">
                      <div className="w-4 h-4"></div>
                      <div>
                        Temp (min and max) is within 3 degrees from forecasted
                      </div>
                    </div>
                    <div className="flex align-top gap-2 items-baseline pb-2">
                      <div className="w-4 h-4"></div>
                      <div>Precipitation is within 1 mm of threshold</div>
                    </div>
                    <div className="flex align-middle gap-2 items-baseline">
                      <div className="w-4 h-4 bg-green-300"></div>
                      <div>
                        Good, but only for activities that have weather risk
                      </div>
                    </div>
                  </span>
                }
              >
                <div className="flex justify-between">
                  <BsInfoCircle className="mt-1" />
                </div>
              </Tooltip>
            )}
            {currentPage === "mitigations" && (
              <Tooltip
                arrow
                title={
                  <span className="text-xl">
                    Assess weather impact on your project and evaluate risk
                    reduction strategies. Visualise the potential impacts on
                    your completion date and subsequent tasks.
                  </span>
                }
              >
                <div className="flex justify-between">
                  <BsInfoCircle className="mt-1" />
                </div>
              </Tooltip>
            )}
            {/* {currentPage === "settings/thresholds" && (
              <div className="flex flex-row">
                <span className="italic flex flex-row">
                  for 
                  <select
                    className="block border border-black rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 py-1 ml-6 mr-6 pl-4 pr-4"
                    required
                    title="Risk Matrix"
                    value={selectedRiskMatrixType}
                    onChange={(e) => {
                      setSelectedRiskMatrixType(e.target.value);
                    }}
                  >
                    <option value="default">Default</option>
                    {selectedRiskMatrixType === "user" && (
                      <option value="user">User</option>
                    )}
                    {selectedRiskMatrixType != "user" && (
                      <option value="project">Project</option>
                    )}
                    {selectedRiskMatrixType != "user" && (
                      <option value="plan">Plan</option>
                    )}
                  </select>
                  {selectedRiskMatrixType === "project" && (
                    <strong>{siteProject?.name}</strong>
                  )}
                  {selectedRiskMatrixType === "plan" && (
                    <strong>{selectedPlan?.title}</strong>
                  )}
                  <Tooltip
                    arrow
                    title={
                      (selectedRiskMatrixType != "user" && (
                        <span className="text-xl">
                          <p>Select between project and plan risk matrix.</p>
                          <li>
                            The project risk matrix is common for all sites and
                            used for new imports.
                          </li>
                          <li>
                            The plan risk matrix is read-only and represents the
                            values used for simulations.
                          </li>
                          <li>
                            Differences between project and plan will raise
                            alert on simulate button.
                          </li>
                        </span>
                      )) ||
                      (selectedRiskMatrixType === "user" && (
                        <span className="text-xl">
                          The user risk matrix is used for personal sites and
                          email alerts linked to those sites.
                        </span>
                      ))
                    }
                  >
                    <span>
                      <BsInfoCircle className="ml-5" />
                    </span>
                  </Tooltip>
                  <MonochromeButton
                    className="ml-10"
                    level="secondary"
                    onClick={() =>
                      syncThresholdsMain(site.project, selectedPlan?.id || "")
                    }
                  >
                    <BiReset className="" />
                    Reset Plan Thresholds
                  </MonochromeButton>
                </span>
              </div>
            )} */}
            {currentPage === "settings/compensation-events" && (
              <p className="italic">1 to 10 Thresholds</p>
            )}
          </div>
          <div className="flex">
            {helpLink && (
              <NavLink to={helpLink} target="_blank">
                <div className="flex gap-2 items-center py-[0.4rem]">
                  <span>
                    <BiHelpCircle />
                  </span>
                  <span>Help</span>
                </div>
              </NavLink>
            )}
            {!currentPage?.includes("settings") &&
              (printableLink ? (
                <NavLink to={printableLink} target="_blank" className="ml-auto">
                  <div
                    className="flex gap-2 items-center py-[0.4rem] px-8"
                  >
                    <span>
                      <BiPrinter />
                    </span>
                    <span>Print</span>
                  </div>
                </NavLink>
              ) : (
                <div
                  className="flex gap-2 items-center py-[0.4rem] px-8 opacity-50 ml-auto select-none"
                >
                  <span>
                    <BiPrinter />
                  </span>
                  <span>Print</span>
                </div>
              ))}
            {!currentPage?.includes("settings") ? (
              <>
                <div className="hidden sm:inline-block">
                  <PlanSelector
                    isShort={true}
                    plans={selectableBasePlans}
                    currentSite={site}
                    handleSelectPlan={setSelectedPlanId}
                    selectedPlan={selectedPlan?.id}
                    setMainPlan={async (site: ISite, planId: string) => {
                      await dispatch(setAsMainPlan(site, planId));
                    }}
                    setDiscardPlanModal={(planId) => {
                      setDiscardPlanId(planId);
                    }}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
        <Outlet />
      </div>
      {discardPlanId && (
        <DiscardAlert
          message="Are you sure you want to delete the plan?"
          handleCancel={() => setDiscardPlanId("")}
          handleDiscard={async () => {
            await discardPlan(discardPlanId);
            setDiscardPlanId("");
          }}
        ></DiscardAlert>
      )}
    </div>
  );
};
