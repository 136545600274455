import { flex, spacing, colors, fontSize } from "styles";
import styled from "styled-components";

export const StyledForm = styled.form`
  ${flex("column", "center", "flex-start")};
  height: 100%;
  width: 100%;

  > h2 {
    padding-bottom: ${spacing.s};
  }

  .radio-group {
    padding-bottom: ${spacing.m};
  }

  > p {
    padding-bottom: ${spacing.l};
  }

  .radio-group {
    ${flex("row", "flex-start", "center")};
    width: 100%;

    label {
      padding-left: ${spacing.l};
    }
  }

  button {
    margin-top: ${spacing.l};
    align-self: flex-end;
  }

  .saving-message {
    ${flex("column", "center", "center")};
    align-self: center;

    > p {
      font-size: ${fontSize.l};
      margin-top: ${spacing.l};
      margin-left: ${spacing.l};
    }
  }
`;

export const StyledErrorMessage = styled.p`
  height: ${fontSize.s};
  font-size: ${fontSize.s};
  color: ${colors.major};
`;
