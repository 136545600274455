//React
import { useState } from "react";
//Store
import { useAppDispatch, useAppSelector } from "store";
import { changeWorkHours, closeModal } from "./thunks";
import {
  selectInitialEndTime,
  selectInitialStartTime,
  selectSelectedDay,
  selectWorkHoursError,
} from "store/calendar";

export const useChangeWorkHours = () => {
  const dispatch = useAppDispatch();

  const selectedDay = useAppSelector(selectSelectedDay);
  const initialStartTime = useAppSelector(selectInitialStartTime);
  const initialEndTime = useAppSelector(selectInitialEndTime);
  const workHoursError = useAppSelector(selectWorkHoursError);

  const [startTime, setStartTime] = useState(new Date(initialStartTime));

  const [endTime, setEndTime] = useState(new Date(initialEndTime));

  const handleSubmit = async () => {
    dispatch(changeWorkHours(startTime, endTime, selectedDay));
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return {
    selectedDay,
    startTime,
    endTime,
    workHoursError,
    setStartTime,
    setEndTime,
    handleSubmit,
    handleCloseModal,
  };
};
