import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { selectActiveSiteId } from "store/auth";
import { setCalendarInitialised } from "store/calendar";
import { fetchCalendarData } from "./thunks";

export const useFetchCalendarData = () => {
  const dispatch = useAppDispatch();
  const activeSiteId = useSelector(selectActiveSiteId);

  useEffect(() => {
    if (activeSiteId) {
      dispatch(fetchCalendarData());
    }

    //Cleanup
    return () => {
      dispatch(setCalendarInitialised(false));
    };
  }, [dispatch, activeSiteId]);
};
