import { StyledErrorMessage } from "elements/Modals";
import { StyledDownloadSlide } from "./styles";
import { useDownloadWB } from "./useDownloadWB";
import WBImage from "assets/images/WBImage.png";
import Loader from "Components/Loader/Loader";
import { MonochromeButton } from "@ehabitation/ui";

interface DownloadSlideProps {
  handleCloseModal: () => void;
  siteId: string;
  projectId: string;
  planId: string;
}

const DownloadSlide = ({
  handleCloseModal,
  siteId,
  projectId,
  planId,
}: DownloadSlideProps) => {
  const { downloadPlan, pdfGenerating, pdfDownloading, error } = useDownloadWB({
    siteId,
    projectId,
    onSuccess: handleCloseModal,
  });

  if (pdfGenerating || pdfDownloading) {
    return (
      <Loader
        message={
          pdfDownloading
            ? "Your report is being downloaded."
            : "Generating your Weather Baseline, please wait."
        }
        subMessage="This can take a few minutes."
      />
    );
  }

  return (
    <StyledDownloadSlide>
      <div className="image-container">
        <img alt="Weather baseline report screenshot" src={WBImage} />
      </div>

      <div className="copy">
        <h2 data-testid="WB-report-slide-title">Weather Baseline Report</h2>

        <p>
          EHAB's Weather Baseline Report provides a monthly based view of the
          weather impact across your whole plan.
        </p>

        <p>
          Weather days are estimated considering the weather thresholds for each
          individual activity. The result is not under or over estimation, but a
          more accurate assessment of the impact.
        </p>

        <MonochromeButton
          level="primary"
          onClick={async () => {
            await downloadPlan(planId!);
          }}
          width="md"
        >
          Download
        </MonochromeButton>

        <StyledErrorMessage className="error">{error}</StyledErrorMessage>
      </div>
    </StyledDownloadSlide>
  );
};

export default DownloadSlide;
