import { useLayoutEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { ITask } from "@ehabitation/ts-utils/browser";
import { IRiskIntervals, TornadoAction } from "types";
import {
  setupTitleAndAxes,
  setNoDelayDataIndicator,
  createSeries,
} from "./chartSetup";
import { parseTaskArray } from "./helpers";

export const useTornadoChart = (
  tasks: ITask[] | null,
  riskIntervals: IRiskIntervals
) => {
  const [tornadoAction, setTornadoAction] = useState<TornadoAction>("max");
  am4core.useTheme(am4themes_animated);
  am4core.options.autoDispose = true;
  am4core.options.autoSetClassName = true;

  const setTornadoActionHelper = (chartType: TornadoAction) => {
    setTornadoAction(chartType);
  };

  //Build chart on mount
  useLayoutEffect(() => {
    let chart: am4charts.XYChart | null = null;

    chart = am4core.create("tornadochartdiv", am4charts.XYChart);
    chart.responsive.enabled = true;
    chart.padding(40, 40, 40, 40);

    // chart.scrollbarY = new am4core.Scrollbar();
    // chart.scrollbarY.marginLeft = 40;

    const chartData = parseTaskArray(tasks, riskIntervals, tornadoAction);

    setupTitleAndAxes(chart, tornadoAction, setTornadoActionHelper);

    if (chartData.length === 0) return setNoDelayDataIndicator(chart);

    chart.data = chartData;

    if (chart.data.length === 0) return setNoDelayDataIndicator(chart);

    if (tornadoAction === "weight") return createSeries(chart, "percent");

    createSeries(chart, "low");
    createSeries(chart, "medium");
    createSeries(chart, "high");

    // Download
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.container = document.getElementById(
      "tornadocharttoolsdiv"
    )!;
    chart.exporting.menu.align = "left";
    chart.exporting.menu.verticalAlign = "top";

    chart.exporting.menu.items = [
      {
        label: "...",
        menu: [
          {
            label: "Download as Image",
            menu: [
              { type: "pdf", label: "PDF" },
              { type: "png", label: "PNG" },
              { type: "jpg", label: "JPG" },
              { type: "svg", label: "SVG" },
            ],
          },
          {
            label: "Download Data",
            menu: [
              { type: "csv", label: "CSV" },
              { type: "pdfdata", label: "PDF" },
            ],
          },
        ],
      },
    ];
  }, [tasks, tornadoAction, riskIntervals]);
};
