import { FC, forwardRef } from "react";
import { ReactElement } from "react";

interface IStatBox {
  title: string;
  subTitle?: string;
  className?: string;
  subClassName?: string;
  headerRightSlot?: ReactElement;
  id?: string;
}

export const StatBox: FC<IStatBox> = forwardRef<HTMLDivElement, IStatBox>(
  (
    {
      title,
      subTitle = <>&nbsp;</>,
      className = "",
      subClassName = "",
      children,
      headerRightSlot,
      id,
    },
    ref
  ) => {
    return (
      <div
        className={`flex flex-col rounded-sm divide-y-2 ${className}`}
        ref={ref}
        id={id}
      >
        {(title || headerRightSlot) && (
          <div className="flex justify-between pb-2">
            <div className="flex gap-2 items-end">
              <span className="truncate flex-shrink-0 text-3xl">{title}</span>
              <span className="truncate text-lg">{subTitle}</span>
            </div>
            {headerRightSlot}
          </div>
        )}
        <div
          className={`flex-grow p-4 ${
            subClassName ? subClassName : "grid place-items-center"
          }`}
        >
          {children}
        </div>
      </div>
    );
  }
);

StatBox.displayName = "StatBox";
