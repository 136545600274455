import {
  CollectionType,
  IRiskMatrix,
  ISite,
  ITaskCategory,
  UserRole,
  greaterThanEqualRole
} from "@ehabitation/ts-utils/browser";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "store";
import { selectUserClaims, selectUserId } from "store/auth";
import { RiskMatrixFileType } from "./helpers";


import {
  WeatherKey
} from "@ehabitation/ts-utils/browser";
import { doc, updateDoc } from "firebase/firestore";
import { getBlob, ref, uploadBytes } from "firebase/storage";
import { db, storage } from "firebaseConfig";
import { firebaseFunction } from "helpers";
import { getHiearchicalConfig } from "helpers/firebase/firestoreUtils";
import { useIsMounted } from "hooks";
import fileDownload from "js-file-download";
import moment from "moment";
import { useCallback } from "react";
import { useVirtual } from "react-virtual";
import { selectUserOrgId } from "store/auth";
import { ThresholdChanges } from "types";
import { minMaxFetchers } from "./CategoryThresholdsRow/ThresholdInput";
import { updateCategoryThresholds } from "./CategoryThresholdsRow/helpers";
import {
  exportRiskMatrix,
  importRiskMatrix
} from "./helpers";
import { getPlanActiveCategories } from "helpers/firebase/plans";

export const useWindowCategoryList = (
  listLength: number | undefined,
  itemHeight: number
) => {
  const parentRef = useRef<HTMLDivElement | null>(null);

  const rowVirtualizer = useVirtual({
    size: listLength ?? 0,
    parentRef,
    estimateSize: useCallback(() => itemHeight, [itemHeight]),
    paddingStart: 1, //1 px padding at top shows the outline correctly
  });

  return {
    parentRef,
    totalSize: rowVirtualizer.totalSize,
    virtualItems: rowVirtualizer.virtualItems,
  };
};

export const useSaveChanges = (
  changeMap: Record<string, ThresholdChanges>,
  setChangeMap: Function,
  riskMatrix?: IRiskMatrix,
  newActivityCodeGroup?: string
) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const isTemplate = ![CollectionType.Users, CollectionType.Projects].includes(
    riskMatrix?.tier as CollectionType
  );
  const userId = useAppSelector(selectUserId);
  const isMounted = useIsMounted();
  const saveThresholds = async () => {
    if (riskMatrix?.id) {
      try {
        setIsSaving(true);
        if (
          riskMatrix?.tier == CollectionType.Projects &&
          doc(db, CollectionType.Projects, riskMatrix.tierObjectId)
        ) {
          await updateDoc(
            doc(db, CollectionType.Projects, riskMatrix.tierObjectId),
            { activityCodeType: newActivityCodeGroup }
          );
        }
        const formattedChanges: Record<string, Partial<ITaskCategory>> = {};
        for (const [id, changes] of Object.entries(changeMap)) {
          const { activityCode, categoryCode, shift, ...thresholds } = changes;
          formattedChanges[id] = {
            activityCode: activityCode ?? "",
            categoryCode: categoryCode ?? "",
            shift: shift,
            thresholds,
          };
        }
        if (isTemplate) {
          const sourceRiskMatrixId = riskMatrix.id;
          const copyRiskMatrix = firebaseFunction("CopyRiskMatrix");
          const response = (await copyRiskMatrix({
            sourceRiskMatrixId,
            tier: CollectionType.Users,
            tierObjectId: userId,
            updates: formattedChanges,
          })) as {
            data?: {
              status: number;
              message: string;
            };
          };
          if (!response.data?.status || response.data?.status > 399) {
            throw new Error(response?.data?.message);
          }
        } else {
          updateCategoryThresholds(riskMatrix.id, formattedChanges);
        }
        if (isMounted()) {
          setChangeMap({});
          setIsSaving(false);
        }
      } catch (error) {
        isMounted() && setIsSaving(false);
        throw error;
      }
    }
  };
  return { isSaving, saveThresholds };
};

const useDiscardChanges = (
  isBusy: boolean,
  setChangeMap: Function,
  resetActivityCode: Function
) => {
  const discardChanges = useCallback(() => {
    if (!isBusy) {
      setChangeMap({});
      resetActivityCode();
    }
  }, [isBusy]);
  return { discardChanges };
};

export const useImportExportRiskMatrix = () => {
  const [importing, setImporting] = useState<boolean>(false);
  const [exporting, setExporting] = useState<boolean>(false);
  const [importModalOpen, setImportModalOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const userId = useAppSelector(selectUserId);
  const orgId = useAppSelector(selectUserOrgId)!;

  const importNewRiskMatrix = async (
    riskMatrix: IRiskMatrix,
    importFile: File
  ) => {
    setImporting(true);
    setErrors([]);
    // upload to bucket
    const fileExtension = importFile.name.split(".").at(-1);
    const fileName = `${riskMatrix.tierObjectId}_${userId}_${moment().format(
      "X"
    )}.${fileExtension}`;
    const uploadRef = ref(storage, `/riskMatrixImport/${fileName}`);
    await uploadBytes(uploadRef, importFile);

    const isTemplate = ![
      CollectionType.Users,
      CollectionType.Projects,
    ].includes(riskMatrix?.tier as CollectionType);
    // fire import function
    const {
      data: { errors, message, success },
    } = await importRiskMatrix(
      isTemplate ? CollectionType.Users : riskMatrix.tier,
      isTemplate ? userId : riskMatrix.tierObjectId,
      fileName,
      orgId,
      userId
    );
    if (errors && errors.length > 0) setErrors([message, ...errors]);
    if (success) setImportModalOpen(false);
    setImporting(false);
  };

  const exportRiskMatrixFile = async (
    fileType: RiskMatrixFileType,
    riskMatrixId = "default",
    hasVisibility = false
  ) => {
    if (!importModalOpen) setExporting(true);
    setErrors([]);
    // fire export function
    const { data } = await exportRiskMatrix(riskMatrixId, fileType, orgId, hasVisibility);
    const fileMime =
      fileType === RiskMatrixFileType.XLSX
        ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        : "text/csv";
    const { message, error, file } = data;

    if (error) setErrors([message, error]);
    else if (file) {
      const storageRef = ref(storage, file);
      if (storageRef) {
        getBlob(storageRef)
          .then((blob) => {
            fileDownload(
              blob,
              `RiskMatrix_${riskMatrixId}.${fileType.toLowerCase()}`,
              fileMime
            );
          })
          .catch((error) => {
            console.error(
              error.code,
              `could not download RiskMatrix_${riskMatrixId}.${fileType}`
            );
          });
      } else {
        console.error("could not download file");
      }

      if (!importModalOpen) setExporting(false);
    }
  };

  return {
    importing,
    importModalOpen,
    importNewRiskMatrix,
    openImport: () => setImportModalOpen(true),
    closeImport: () => {
      setImportModalOpen(false);
      setErrors([]);
    },
    exporting,
    exportRiskMatrix: exportRiskMatrixFile,
    errors,
  };
};

export const useRiskMatrixChangeMap = (riskMatrix?: IRiskMatrix) => {
  const [changeMap, setChangeMap] = useState<Record<string, ThresholdChanges>>(
    {}
  );
  const [activityCodeGroup, setActivityCodeGroup] = useState<string>();
  const [newActivityCodeGroup, setNewActivityCodeGroup] = useState<string>();

  useEffect(() => {
    setChangeMap({});
  }, [riskMatrix?.id]);

  const numChanged = Object.keys(changeMap)?.length;
  const updateChangeMap = (newChangeMap: Record<string, ThresholdChanges>) =>
    setChangeMap({ ...changeMap, ...newChangeMap });

  const { isSaving, saveThresholds } = useSaveChanges(
    changeMap,
    setChangeMap,
    riskMatrix,
    newActivityCodeGroup
  );

  const { discardChanges } = useDiscardChanges(isSaving, setChangeMap, () => {
    setNewActivityCodeGroup(activityCodeGroup);
  });

  const isInvalid = Object.values(changeMap).some((thresholds) => {
    const hasInvalidPairs =
      (typeof thresholds.minTemp !== "undefined" &&
        typeof thresholds.maxTemp !== "undefined" &&
        thresholds.minTemp >= thresholds.maxTemp!) ||
      (typeof thresholds.hourlyRainAcc !== "undefined" &&
        typeof thresholds.dailyRainAcc !== "undefined" &&
        thresholds.hourlyRainAcc >= thresholds.dailyRainAcc);
    if (hasInvalidPairs) return true;
    for (const key of Object.values(WeatherKey)) {
      const value = thresholds[key];
      if (typeof value !== "undefined" && minMaxFetchers[key]) {
        const { min, max } = minMaxFetchers[key](thresholds);
        return value < min && value > max;
      }
    }
  });

  const nothingToSave =
    Object.keys(changeMap).length === 0 &&
    newActivityCodeGroup === activityCodeGroup;
  const saveDisabled = isSaving || isInvalid || nothingToSave;
  const discardDisabled = isSaving || nothingToSave;

  useEffect(() => {
    if (!riskMatrix) return;
    getHiearchicalConfig(
      riskMatrix.tierObjectId!,
      riskMatrix.tier! as CollectionType,
      "activityCodeType"
    ).then((value) => {
      setActivityCodeGroup(value?.value);
      setNewActivityCodeGroup(value?.value);
    });
  }, [riskMatrix?.id]);

  return {
    changeMap,
    updateChangeMap,
    numChanged,
    isValid: !isInvalid,
    isSaving,
    saveThresholds,
    saveDisabled,
    discardChanges,
    discardDisabled,
    activityCodeGroup,
    setActivityCodeGroup,
    newActivityCodeGroup,
    setNewActivityCodeGroup,
  };
};


export const usePlanActiveCategories = (siteId: string, planId?: string) => {
  const [activeCategories, setActiveCategories] = useState<Set<string>>(
    new Set<string>()
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (planId) {
      setIsLoading(true);
      getPlanActiveCategories(siteId, planId).then((categories) => {
        setActiveCategories(categories);
        setIsLoading(false);
      });
    } else {
      setActiveCategories(new Set<string>());
      setIsLoading(false);
    }
  }, [planId]);

  return { activeCategories, isLoading };
};


export const useUserCanEditRiskMatrix = (site: ISite, riskMatrix?: IRiskMatrix) => {
  const userClaims = useAppSelector(selectUserClaims);
  const userId = useAppSelector(selectUserId);

  return useMemo(() => {
    if (!riskMatrix || !userClaims) {
      return false;
    }

    if (!site.project) {
      // personal site, can create copy
      return true;
    }

    if (riskMatrix.id === "default") {
      return false;
    }

    const isCurrentUserMatrix =
      riskMatrix.tier === CollectionType.Users &&
      riskMatrix.tierObjectId === userId;
    if (isCurrentUserMatrix) {
      return true;
    }

    if (
      riskMatrix.tier === CollectionType.Projects &&
      greaterThanEqualRole(userClaims.role, UserRole.projectAdmin)
    ) {
      return true;
    }

    return false;
  }, [riskMatrix, userClaims, userId]);
};