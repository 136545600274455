import Header from "Components/Header/Header";
import moment from "moment";
import { FC, useCallback, useRef } from "react";
import { BsPrinterFill } from "react-icons/bs";
import ReactToPrint from "react-to-print";
interface PrintableViewProps {
  page: string;
}
export const PrintableView: FC<PrintableViewProps> = ({ page, children }) => {
  const componentRef = useRef(null);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  return (
    <div className="h-full">
      <div className="px-8 absolute top-24 right-0">
        <ReactToPrint
          trigger={() => (
            <button className="flex gap-2 items-center bg-white border border-black py-1 px-6 rounded-full ml-auto">
              <span>
                <BsPrinterFill />
              </span>
              <span>Print</span>
            </button>
          )}
          content={reactToPrintContent}
          documentTitle={`EHAB_Platform-Tab-${page}_${moment().format(
            "DD-MM-YYYY"
          )}`}
        />
      </div>
      <div ref={componentRef}>
        <style>
          {
            "@page { margin: 20px 30px 20px 20px !important; size: A3 landscape; }"
          }
        </style>
        <div className="mx-8">
          <Header />
          {children}
        </div>
      </div>
    </div>
  );
};
