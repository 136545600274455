import {
  IDailyForecast,
  ITask,
  warningPrecipitation,
  warningTemperature,
  warningWind,
  WeatherThresholds,
} from "@ehabitation/ts-utils";
import {
  FC,
  Fragment,
  RefObject,
  forwardRef,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { Tooltip } from "@material-ui/core";
import { BiCaretRight } from "react-icons/bi";

interface IDayDataCells {
  index: number;
  compact: boolean;
  dayData: IDailyForecast;
  monthLabel?: JSX.Element;
  weatherMap: Record<string, FC>;
  isHighlighted: boolean;
  impactedUpcomingCategories:
    | {
        index: number;
        categories:
          | {
              category: {
                id: string;
                thresholds: WeatherThresholds;
                name: string;
              };
              brokenCount: number;
              warningCount: number;
            }[]
          | undefined;
      }
    | undefined;
  impactedUntaskedCategories:
    | {
        index: number;
        categories:
          | {
              category: {
                id: string;
                thresholds: WeatherThresholds;
                name: string;
              };
              brokenCount: number;
              warningCount: number;
            }[]
          | undefined;
      }
    | undefined;
  setSelectedCategoryName: any;
  setSelectedTaskId: any;
  collapsedCategories: Set<string>;
  thresholds?: WeatherThresholds;
  dataRows: typeof dataRows;
  isPrintable?: boolean;
  hasMonthLabelRow?: boolean;
  tasks?: ITask[];
}

interface MappedCategories {
  category: {
    id: string;
    thresholds: WeatherThresholds;
    name: string;
  };
  brokenCount: number;
  warningCount: number;
}

type RowKey = "temp" | "maxWindSpeed" | "dailyRainAcc" | "hourlyRainAcc";

export const indentColours = [
  "border-lime-300",
  "border-teal-300",
  "border-fuchsia-300",
];

export const getLevelBorderColorClass = (task: ITask) =>
  indentColours[
    (task.wbsHierarchyLevel! + (task.WBS ? 1 : 0)) % indentColours.length
  ];

interface ForecastTabParams {
  compact?: boolean;
  weatherCodeIconMap: Record<string, FC>;
  dailyForecasts: IDailyForecast[];
  forecastSiteId: string | null;
  highlightIndex?: number;
  handleDayClick?: Function;
  selectedDayRef?: RefObject<HTMLDivElement>;
  thresholds?: WeatherThresholds;
  excludeRows?: RowKey[];
  error?: string;
  orderedUpcomingCategoryImpactCounts?: {
    category: {
      id: string;
      thresholds: WeatherThresholds;
      name: string;
    };
    count: number;
  }[];
  orderedUntaskedCategoryImpactCounts?: {
    category: {
      id: string;
      thresholds: WeatherThresholds;
      name: string;
    };
    count: number;
  }[];
  selectedCategoryName?: string;
  setSelectedCategoryName?: (id?: string) => void;
  selectedTaskId?: string;
  setSelectedTaskId?: (id?: string) => void;
  isPrintable?: boolean;
  className?: string;
  hideMonthLabels?: boolean;
  tasks?: ITask[];
}

export const threeDigitsMax = (value: number, decimal = false): string => {
  if (value >= 1000) {
    if (value >= 10000) {
      return Math.round(value / 1000) + "k";
    }
    return (value / 1000).toFixed(1) + "k";
  }
  if (decimal && value < 10 && value > -10) {
    return value.toFixed(1);
  }
  return String(Math.round(value));
};

export const fourDigitsMax = (value: number, decimal = false): string => {
  if (value >= 1000) {
    if (value >= 10000) {
      return Math.round(value / 1000) + "k";
    }
    return (value / 1000).toFixed(1) + "k";
  }
  if (decimal && value < 10 && value > -10) {
    return value.toFixed(1);
  }
  return String(Math.round(value));
};

export const calculateBrokens = (
  thresholds: WeatherThresholds,
  dayData: IDailyForecast
) => {
  const minTempValue = dayData.minTemp.value;
  const hasMinTempValue = typeof minTempValue === "number";
  const maxTempValue = dayData.maxTemp.value;
  const hasMaxTempValue = typeof maxTempValue === "number";
  const maxWindSpeedValue = dayData.maxWindSpeed.value;
  const hasMaxWindSpeedValue = typeof maxWindSpeedValue === "number";
  const dailyRainAccValue = dayData.dailyRainAcc.value;
  const hasDailyRainAccValue = typeof dailyRainAccValue === "number";
  const hourlyRainAccValue = dayData.hourlyRainAcc.value;
  const hasHourlyRainAccValue = typeof hourlyRainAccValue === "number";
  const isMinTempBroken =
    hasMinTempValue && typeof thresholds.minTemp === "number"
      ? minTempValue < thresholds.minTemp
      : undefined;
  const isMaxTempBroken =
    hasMaxTempValue && typeof thresholds.maxTemp === "number"
      ? maxTempValue > thresholds.maxTemp
      : undefined;
  const isMaxWindBroken =
    hasMaxWindSpeedValue && typeof thresholds.wind === "number"
      ? maxWindSpeedValue > thresholds.wind
      : undefined;
  const isDailyRainBroken =
    hasDailyRainAccValue && typeof thresholds.dailyRainAcc === "number"
      ? dailyRainAccValue > thresholds.dailyRainAcc
      : undefined;
  const isHourlyRainBroken =
    hasHourlyRainAccValue && typeof thresholds.hourlyRainAcc === "number"
      ? hourlyRainAccValue > thresholds.hourlyRainAcc
      : undefined;
  const isMinTempWarning = isMinTempBroken
    ? false
    : hasMinTempValue && typeof thresholds.minTemp === "number"
    ? minTempValue < thresholds.minTemp + warningTemperature
    : undefined;
  const isMaxTempWarning = isMaxTempBroken
    ? false
    : hasMaxTempValue && typeof thresholds.maxTemp === "number"
    ? maxTempValue >
      (thresholds?.maxTemp > warningTemperature
        ? thresholds?.maxTemp - warningTemperature
        : 0)
    : undefined;
  const isMaxWindWarning = isMaxWindBroken
    ? false
    : hasMaxWindSpeedValue && typeof thresholds.wind === "number"
    ? maxWindSpeedValue >
      (thresholds?.wind > warningWind ? thresholds?.wind - warningWind : 0)
    : undefined;
  const isDailyRainWarning = isDailyRainBroken
    ? false
    : hasDailyRainAccValue && typeof thresholds.dailyRainAcc === "number"
    ? dailyRainAccValue >
      (thresholds?.dailyRainAcc > warningPrecipitation
        ? thresholds?.dailyRainAcc - warningPrecipitation
        : 0)
    : undefined;
  const isHourlyRainWarning =
    hasHourlyRainAccValue && typeof thresholds.hourlyRainAcc === "number"
      ? hourlyRainAccValue >
        (thresholds?.hourlyRainAcc > warningPrecipitation
          ? thresholds?.hourlyRainAcc - warningPrecipitation
          : 0)
      : undefined;
  const weatherRisk = [
    isMinTempBroken,
    isMaxTempBroken,
    isMaxWindBroken,
    isDailyRainBroken,
    isHourlyRainBroken,
  ].filter((b) => b !== undefined);
  if (weatherRisk.length === 0) return { brokenCount: -1, warningCount: -1 };
  const brokens = [
    isMinTempBroken,
    isMaxTempBroken,
    isMaxWindBroken,
    isDailyRainBroken,
    isHourlyRainBroken,
  ].filter((b) => b);
  const warnings = [
    isMinTempWarning,
    isMaxTempWarning,
    isMaxWindWarning,
    isDailyRainWarning,
    isHourlyRainWarning,
  ].filter((w) => w);
  return { brokenCount: brokens.length, warningCount: warnings.length };
};
const getDailyCategoryImpactCounts = ({
  orderedCategories,
  dailyForecasts,
}: {
  orderedCategories: {
    id: string;
    thresholds: WeatherThresholds;
    name: string;
  }[];
  dailyForecasts: IDailyForecast[];
}) => {
  {
    if (orderedCategories) {
      return Object.values(dailyForecasts).map((dayData, i) => {
        const mappedCategories = orderedCategories.map((category) => {
          const { brokenCount, warningCount } = calculateBrokens(
            category.thresholds,
            dayData
          );
          return { category: category, brokenCount, warningCount };
        });
        return {
          index: i,
          categories: mappedCategories.filter(
            (c) => c !== null
          ) as MappedCategories[],
        };
      });
    }
  }
};

const monthFormatter = new Intl.DateTimeFormat("en", { month: "long" });

const getDateISO = (date: Date) => date.toISOString().split("T")[0];

const DayDataCells = ({
  index,
  compact,
  monthLabel,
  dayData,
  weatherMap,
  isHighlighted,
  thresholds,
  impactedUpcomingCategories,
  impactedUntaskedCategories,
  setSelectedCategoryName,
  setSelectedTaskId,
  collapsedCategories,
  dataRows,
  isPrintable,
  hasMonthLabelRow = true,
  tasks,
}: IDayDataCells) => {
  const isFirstColumn = index === 0;

  const bgHeatClasses = isPrintable
    ? {
        ok: "border-b border-green-300",
        warn: "border-b border-amber-300",
        alert: "border-b border-rose-300",
        none: "border-b border-gray-100",
      }
    : {
        ok: "bg-green-300 group-hover/chip:bg-green-400",
        warn: "bg-amber-300 group-hover/chip:bg-amber-400",
        alert: "bg-rose-300 group-hover/chip:bg-rose-400",
        none: "bg-gray-100 group-hover/chip:bg-gray-300",
      };
  const dayOfMonth = dayData.dateReference.getDate();
  const dateISO = getDateISO(dayData.dateReference);
  const renderChip = (
    c: {
      category: {
        id: string;
        thresholds: WeatherThresholds;
        name: string;
      };
      brokenCount: number;
      warningCount: number;
    },
    collapsed = false
  ) => {
    const getBgClass = (brokenCount: number, warningCount: number) => {
      if (brokenCount === -1) return bgHeatClasses.none;
      return brokenCount > 0
        ? bgHeatClasses.alert
        : warningCount > 0
        ? bgHeatClasses.warn
        : bgHeatClasses.ok;
    };
    const categoryTasks = tasks?.filter(
      ({ taskType }) => c.category.name === taskType
    );
    return (
      <Fragment key={c.category.id}>
        <Tooltip
          arrow
          title={
            <span className="text-xl">
              {c.brokenCount ? (
                c.brokenCount === -1 ? (
                  <div>No relevant forecast data.</div>
                ) : (
                  <div>
                    {c.brokenCount} forecast{c.brokenCount === 1 ? "" : "ed"}{" "}
                    threshold break{c.brokenCount === 1 ? "" : "s"}.
                  </div>
                )
              ) : c.warningCount ? (
                <div>
                  {c.warningCount} forecast{c.warningCount === 1 ? "" : "ed"}{" "}
                  threshold warning{c.warningCount === 1 ? "" : "s"}.
                </div>
              ) : (
                <div>No forecasted weather impacts.</div>
              )}
            </span>
          }
        >
          <div
            onClick={() => {
              setSelectedCategoryName(c.category.name);
              setSelectedTaskId();
            }}
            className="group/chip hover:cursor-pointer flex flex-col justify-around h-12"
          >
            <div
              className={`h-2 rounded-md ${getBgClass(
                c.brokenCount,
                c.warningCount
              )}`}
            />
          </div>
        </Tooltip>
        {collapsed
          ? null
          : categoryTasks?.map((task) => {
              const isTaskOnDay =
                getDateISO(task.start) <= dateISO &&
                getDateISO(task.end) >= dateISO;
              return (
                <Tooltip
                  arrow
                  title={
                    <span className="text-xl">
                      {isTaskOnDay ? (
                        c.brokenCount ? (
                          <div>
                            {c.brokenCount} forecast
                            {c.brokenCount === 1 ? "" : "ed"} threshold break
                            {c.brokenCount === 1 ? "" : "s"}.
                          </div>
                        ) : c.warningCount ? (
                          <div>
                            {c.warningCount} forecast
                            {c.warningCount === 1 ? "" : "ed"} threshold warning
                            {c.warningCount === 1 ? "" : "s"}.
                          </div>
                        ) : (
                          <div>No forecasted weather impacts.</div>
                        )
                      ) : (
                        <div>No task activity.</div>
                      )}
                    </span>
                  }
                  key={task.id}
                >
                  <div
                    onClick={() => {
                      setSelectedTaskId(task.id);
                      setSelectedCategoryName();
                    }}
                    className={`h-12 group/chip hover:cursor-pointer flex flex-col justify-around`}
                  >
                    <div
                      className={`h-2 rounded-md ${
                        isTaskOnDay
                          ? getBgClass(c.brokenCount, c.warningCount)
                          : bgHeatClasses.none
                      }`}
                    />
                  </div>
                </Tooltip>
              );
            })}
      </Fragment>
    );
  };
  return (
    <>
      <div
        className={`sticky pb-2 bg-white ${
          hasMonthLabelRow ? "top-11" : "top-0"
        }`}
      >
        {monthLabel}
        <div className={compact ? "" : "space-y-2"}>
          <div
            className={`px-4 grid place-content-center h-12 font-m ${
              isHighlighted ? "bg-blue-200" : "group-hover/root:bg-blue-100"
            }`}
          >
            {dayOfMonth}
          </div>
          {dataRows.map(({ render, key }, i) => (
            <div
              key={key}
              className="h-12 grid place-content-stretch relative"
              data-testid={`forecast-data-${dayData.dateReference.getDate()}-${key}`}
            >
              <div className="absolute inset-0 -left-2 -right-2 -z-10" />
              {render(dayData, weatherMap, thresholds)}
            </div>
          ))}
        </div>
      </div>
      {tasks ? (
        <>
          <div className="h-6" />
          {impactedUpcomingCategories?.categories?.length ? (
            <>
              <div className="h-12" />
              {impactedUpcomingCategories?.categories?.map((c) => {
                return renderChip(c, collapsedCategories.has(c.category.id));
              })}
            </>
          ) : isFirstColumn ? (
            <div className="h-12 whitespace-nowrap overflow-x-visible max-w-[3rem] pl-4 text-left flex items-center">
              No Upcoming Tasks
            </div>
          ) : (
            <div className="h-12" />
          )}
        </>
      ) : null}
      {impactedUntaskedCategories ? (
        <>
          <div className="h-4" />
          {impactedUntaskedCategories?.categories?.length ? (
            <>
              <div className="h-12" />
              {impactedUntaskedCategories?.categories?.map((c, i) =>
                renderChip(c)
              )}
            </>
          ) : isFirstColumn ? (
            <div className="h-12 whitespace-nowrap overflow-x-visible max-w-[3rem] pl-4 text-left flex items-center">
              No Other Categories
            </div>
          ) : (
            <div className="h-12" />
          )}
        </>
      ) : null}
    </>
  );
};

const bgClasses = {
  ok: "bg-green-200",
  alert: "bg-rose-200",
  warning: "bg-amber-200",
  off: "bg-gray-100",
};

const getBgClass = (isBroken?: boolean, isWarning?: boolean) => {
  if (typeof isBroken === "boolean" && typeof isWarning === "boolean") {
    if (isBroken) {
      return bgClasses.alert;
    } else if (isWarning) {
      return bgClasses.warning;
    } else {
      return bgClasses.ok;
    }
  } else {
    return bgClasses.off;
  }
};

const dataRows = [
  {
    title: " ",
    key: "icon",
    unit: "",
    render: (
      dayData: IDailyForecast,
      weatherMap: Record<string, FC>,
      thresholds?: WeatherThresholds
    ) => (
      <div className="px-2 py-1 grid place-content-center bg-white">
        <div
          style={{ backgroundImage: `url(${weatherMap[dayData.weatherCode]})` }}
          className="w-8 h-8 bg-contain"
        ></div>
      </div>
    ),
  },
  {
    title: "Temp",
    key: "temp",
    unit: "C",
    render: (
      dayData: IDailyForecast,
      weatherMap: Record<string, FC>,
      thresholds?: WeatherThresholds
    ) => {
      const minTempValue = dayData.minTemp.value;
      const maxTempValue = dayData.maxTemp.value;
      const hasMinTempValue = typeof minTempValue === "number";
      const hasMaxTempValue = typeof maxTempValue === "number";
      const isMinTempBroken =
        hasMinTempValue && typeof thresholds?.minTemp === "number"
          ? minTempValue < thresholds?.minTemp
          : undefined;
      const isMaxTempBroken =
        hasMaxTempValue && typeof thresholds?.maxTemp === "number"
          ? maxTempValue > thresholds?.maxTemp
          : undefined;
      const isMinTempWarning = isMinTempBroken
        ? false
        : hasMinTempValue && typeof thresholds?.minTemp === "number"
        ? minTempValue < thresholds?.minTemp + warningTemperature
        : undefined;
      const isMaxTempWarning = isMaxTempBroken
        ? false
        : hasMaxTempValue && typeof thresholds?.maxTemp === "number"
        ? maxTempValue >
          (thresholds?.maxTemp > warningTemperature
            ? thresholds?.maxTemp - warningTemperature
            : 0)
        : undefined;
      return (
        <div
          className={`${getBgClass(
            isMinTempBroken || isMaxTempBroken,
            isMinTempWarning || isMaxTempWarning
          )} grid place-content-center`}
        >
          <div className="px-2">
            <span className="align-super text-base mr-5">
              {hasMinTempValue ? threeDigitsMax(minTempValue) : "-"}
            </span>
            <span className="align-sub text-base">
              {hasMaxTempValue ? threeDigitsMax(maxTempValue) : "-"}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    title: "Wind",
    key: "maxWindSpeed",
    unit: "m/s",
    render: (
      dayData: IDailyForecast,
      weatherMap: Record<string, FC>,
      thresholds?: WeatherThresholds
    ) => {
      const maxWindValue = dayData.maxWindSpeed.value;
      const hasValue = typeof maxWindValue === "number";
      const isWindBroken =
        hasValue && typeof thresholds?.wind === "number"
          ? maxWindValue > thresholds?.wind
          : undefined;
      const isWindWarning =
        hasValue && typeof thresholds?.wind === "number"
          ? maxWindValue >
            (thresholds?.wind > warningWind
              ? thresholds?.wind - warningWind
              : 0)
          : undefined;
      return (
        <div
          className={`px-2 text-xl grid place-content-center ${getBgClass(
            isWindBroken,
            isWindWarning
          )}`}
        >
          {hasValue ? threeDigitsMax(maxWindValue) : "-"}
        </div>
      );
    },
  },
  {
    title: "24hr Rain",
    key: "dailyRainAcc",
    unit: "mm",
    render: (
      dayData: IDailyForecast,
      weatherMap: Record<string, FC>,
      thresholds?: WeatherThresholds
    ) => {
      const dailyRainValue = dayData.dailyRainAcc.value;
      const hasValue = typeof dailyRainValue === "number";
      const isDailyRainBroken =
        hasValue && typeof thresholds?.dailyRainAcc === "number"
          ? dailyRainValue > thresholds?.dailyRainAcc
          : undefined;
      const isDailyRainWarning =
        hasValue && typeof thresholds?.dailyRainAcc === "number"
          ? dailyRainValue >
            (thresholds?.dailyRainAcc > warningPrecipitation
              ? thresholds?.dailyRainAcc - warningPrecipitation
              : 0)
          : undefined;
      return (
        <div
          className={`px-2 text-xl grid place-content-center ${getBgClass(
            isDailyRainBroken,
            isDailyRainWarning
          )}`}
        >
          {hasValue ? threeDigitsMax(dailyRainValue, true) : "-"}
        </div>
      );
    },
  },
  {
    title: "Hourly Rain",
    key: "hourlyRainAcc",
    unit: "mm/hr",
    render: (
      dayData: IDailyForecast,
      weatherMap: Record<string, FC>,
      thresholds?: WeatherThresholds
    ) => {
      const hourlyRainValue = dayData.hourlyRainAcc.value;
      const hasValue = typeof hourlyRainValue === "number";
      const isHourlyRainBroken =
        hasValue && typeof thresholds?.hourlyRainAcc === "number"
          ? hourlyRainValue > thresholds?.hourlyRainAcc
          : undefined;
      const isHourlyRainWarning =
        hasValue && typeof thresholds?.hourlyRainAcc === "number"
          ? hourlyRainValue >
            (thresholds?.hourlyRainAcc > warningPrecipitation
              ? thresholds?.hourlyRainAcc - warningPrecipitation
              : 0)
          : undefined;
      return (
        <div
          className={`px-2 text-xl grid place-content-center ${getBgClass(
            isHourlyRainBroken,
            isHourlyRainWarning
          )}`}
        >
          {hasValue ? threeDigitsMax(hourlyRainValue, true) : "-"}
        </div>
      );
    },
  },
];

const MonthLabel = forwardRef<HTMLDivElement, { date: Date }>(
  ({ date }, forwardedRef) => {
    return (
      <div ref={forwardedRef} className="absolute -top-11 flex pl-2">
        <span className="tracking-widest font-[500]">
          {monthFormatter.format(date)}
        </span>
      </div>
    );
  }
);

MonthLabel.displayName = "MonthLabel";

const ForecastTableContainer: FC<{
  monthLabelRef: RefObject<HTMLDivElement>;
  nextMonthLabelRef: RefObject<HTMLDivElement>;
  className: string;
  children: JSX.Element | JSX.Element[];
}> = ({ monthLabelRef, nextMonthLabelRef, ...props }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const updateLabelPositions = () => {
    const monthLabel = monthLabelRef.current;
    if (!monthLabel || !containerRef.current) {
      return;
    }
    const nextMonthLabel = nextMonthLabelRef.current;
    const currentScrollAmount = containerRef.current.scrollLeft;
    if (!nextMonthLabel?.parentElement) {
      monthLabel.style.setProperty("left", `${currentScrollAmount}px`);
      return;
    }
    const nextMonthLabelPosition =
      nextMonthLabel.parentElement?.offsetLeft - currentScrollAmount;
    if (nextMonthLabelPosition < 80) {
      if (nextMonthLabelPosition < 0) {
        nextMonthLabel.style.setProperty(
          "left",
          `${nextMonthLabelPosition * -1}px`
        );
      } else {
        monthLabel.style.setProperty(
          "left",
          `${currentScrollAmount - (80 - nextMonthLabelPosition)}px`
        );
        nextMonthLabel.style.setProperty("left", "0");
      }
    } else {
      monthLabel.style.setProperty("left", `${currentScrollAmount}px`);
      monthLabel.style.setProperty("display", "block");
    }
  };
  useLayoutEffect(updateLabelPositions, []);
  return <div ref={containerRef} onScroll={updateLabelPositions} {...props} />;
};

export const ForecastTable: FC<ForecastTabParams> = ({
  compact = false,
  weatherCodeIconMap,
  dailyForecasts,
  forecastSiteId,
  highlightIndex,
  handleDayClick,
  excludeRows,
  thresholds,
  error,
  orderedUpcomingCategoryImpactCounts: upcomingCategoryImpactCounts,
  orderedUntaskedCategoryImpactCounts: untaskedCategoryImpactCounts,
  selectedCategoryName,
  setSelectedCategoryName,
  selectedTaskId,
  setSelectedTaskId,
  isPrintable = false,
  selectedDayRef,
  className,
  hideMonthLabels,
  tasks,
}) => {
  const monthLabelRef = useRef<HTMLDivElement>(null);
  const nextMonthLabelRef = useRef<HTMLDivElement>(null);
  const filteredDataRows = excludeRows
    ? dataRows.filter(({ key }: any) => !excludeRows.includes(key))
    : dataRows;
  if (error) {
    return (
      <div
        data-testid="forecast-table"
        className="text-center w-full h-full text-[#bc2c1a] flex flex-col justify-center items-center"
      >
        {error}
      </div>
    );
  }
  if (forecastSiteId === null) {
    return (
      <div
        className="flex flex-col justify-center items-center text-center w-full h-full"
        data-testid="forecast-table"
      >
        Loading site forecast...
      </div>
    );
  }

  const upcomingCategoriesToDisplay = upcomingCategoryImpactCounts?.map(
    (c) => c.category
  );
  const untaskedCategoriesToDisplay = untaskedCategoryImpactCounts?.map(
    (c) => c.category
  );

  const [collapsedCategories, setCollapsedCategories] = useState<Set<string>>(
    new Set()
  );

  const dailyUpcomingImpactCounts =
    upcomingCategoriesToDisplay &&
    getDailyCategoryImpactCounts({
      orderedCategories: upcomingCategoriesToDisplay,
      dailyForecasts,
    });
  const dailyUntaskedImpactCounts =
    untaskedCategoriesToDisplay &&
    getDailyCategoryImpactCounts({
      orderedCategories: untaskedCategoriesToDisplay,
      dailyForecasts,
    });

  return (
    <ForecastTableContainer
      monthLabelRef={monthLabelRef}
      nextMonthLabelRef={nextMonthLabelRef}
      className={`flex-grow flex-auto overflow-auto ${
        dailyUntaskedImpactCounts?.length ? "min-h-[28rem]" : ""
      } max-w-[98rem] ${className || ""}`}
    >
      <div
        className="flex p-0 text-center gap-2 justify-between px-1 sm:px-2"
        id="usetiful_forecast_daily_date"
      >
        <div className="bg-white sticky left-0 z-10 grow min-w-[15rem]">
          <div
            className="sticky pb-2 top-0 z-10 bg-white"
            id="usetiful_forecast_daily_thresholds"
          >
            {hideMonthLabels ? null : (
              <div className={`py-2 px-4 h-12 text-xl font-medium`} />
            )}
            <div className={compact ? "" : "space-y-2"}>
              <div className={`py-2 px-4 h-12 text-xl font-medium`}></div>
              {filteredDataRows.map(({ title, unit, key }, i) => {
                const isFirstRow = i === 0;
                return (
                  <div
                    id={isFirstRow ? "usetiful_forecast_daily_categories" : ""}
                    key={key}
                    className="py-2 px-4 h-12 text-xl font-medium"
                  >
                    <div>
                      {title}
                      <span className="text-base text-gray-600 pl-2 align-middle">
                        {`${unit ? "(" + unit + ")" : ""}`}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {tasks ? (
            <>
              <div className="h-6" />
              <div className="py-2 px-4 text-2xl break-inside-avoid text-left flex items-center gap-1 relative font-medium italic h-12">
                Upcoming
              </div>
            </>
          ) : null}
          {upcomingCategoriesToDisplay?.map((category, i) => {
            const categoryTasks = tasks?.filter(
              ({ taskType }) => category.name === taskType
            );
            return (
              <Fragment key={category.id}>
                <div
                  className={`py-2 px-4 text-2xl hover:cursor-pointer break-inside-avoid text-left flex items-center gap-1 relative h-12 pl-3 ${
                    category.name === selectedCategoryName
                      ? "bg-blue-200"
                      : "hover:bg-blue-100"
                  }`}
                  onClick={() => {
                    setSelectedCategoryName?.(category.name);
                    setSelectedTaskId?.();
                  }}
                >
                  {categoryTasks?.length ? (
                    <div>
                      <label
                        className="sr-only"
                        htmlFor={`${category.id}-toggle`}
                      >
                        Expand/Collapse {category.name} tasks
                      </label>
                      <div
                        role="button"
                        id={`${category.id}-toggle`}
                        className="absolute left-0 top-0 bottom-0 w-10 z-10"
                        onClick={() => {
                          const updated = new Set(collapsedCategories);
                          collapsedCategories.has(category.id)
                            ? updated.delete(category.id)
                            : updated.add(category.id);
                          setCollapsedCategories(updated);
                        }}
                      />
                      <BiCaretRight
                        className={`transition-transform ${
                          collapsedCategories.has(category.id)
                            ? "rotate-0"
                            : "rotate-90"
                        }`}
                      />
                    </div>
                  ) : null}
                  <div className="text-ellipsis whitespace-nowrap overflow-hidden pr-3">
                    {category.name}
                  </div>
                </div>
                {collapsedCategories.has(category.id)
                  ? null
                  : categoryTasks?.map((task) => (
                      <div
                        key={task.id}
                        className={`text-left py-2 pl-2 pr-4 h-12 text-xl hover:cursor-pointer break-inside-avoid ${
                          task.id === selectedTaskId
                            ? "bg-blue-200"
                            : "bg-white hover:bg-blue-100"
                        } ml-4 border-l-2 ${getLevelBorderColorClass(task)}`}
                        onClick={() => {
                          setSelectedCategoryName?.();
                          setSelectedTaskId?.(task.id);
                        }}
                      >
                        <div className="text-ellipsis whitespace-nowrap overflow-hidden">
                          {task.title}
                        </div>
                      </div>
                    ))}
              </Fragment>
            );
          })}
          {untaskedCategoriesToDisplay ? (
            <>
              <div className="h-4" />
              <div className="py-2 px-4 text-2xl break-inside-avoid text-left flex items-center gap-1 relative font-medium italic h-12">
                {tasks ? "Other" : "Activities"}
              </div>
              {untaskedCategoriesToDisplay?.map((category, i) => {
                return (
                  <div
                    key={category.id}
                    className={`py-2 px-4 text-xl hover:cursor-pointer break-inside-avoid text-left flex items-center h-12 pl-5 ${
                      category.name === selectedCategoryName
                        ? "bg-blue-200"
                        : "hover:bg-blue-100"
                    }`}
                    onClick={() => {
                      setSelectedCategoryName?.(category.name);
                      setSelectedTaskId?.();
                    }}
                  >
                    <div className="text-ellipsis whitespace-nowrap overflow-hidden pr-3">
                      {category.name}
                    </div>
                  </div>
                );
              })}
            </>
          ) : null}
        </div>
        <div className="relative">
          {hideMonthLabels ? null : (
            <div className="h-12 w-full bg-white sticky top-0" />
          )}
          <div className="grid grid-flow-col-dense p-0 text-center gap-2 bg-white">
            {Object.values(dailyForecasts).map((dayData, i) => {
              const isFirstDay = i === 0;
              const isHighlighted = highlightIndex === i;
              const isClickable = handleDayClick && !isHighlighted;
              const monthLabel = hideMonthLabels ? undefined : isFirstDay ? (
                <MonthLabel ref={monthLabelRef} date={dayData.dateReference} />
              ) : dayData.dateReference.getDate() === 1 ? (
                <MonthLabel
                  ref={nextMonthLabelRef}
                  date={dayData.dateReference}
                />
              ) : undefined;
              return (
                <div
                  key={dayData?.dateReference?.toISOString()}
                  role={handleDayClick && "button"}
                  onClick={isClickable ? () => handleDayClick(i) : undefined}
                  className={`group/root flex flex-col items-stretch ${
                    isClickable ? "cursor-pointer" : "cursor-default"
                  }`}
                  ref={isHighlighted ? selectedDayRef : undefined}
                >
                  <DayDataCells
                    index={i}
                    compact={compact}
                    hasMonthLabelRow={!hideMonthLabels}
                    monthLabel={monthLabel}
                    isHighlighted={isHighlighted}
                    dayData={dayData}
                    weatherMap={weatherCodeIconMap}
                    thresholds={thresholds}
                    impactedUpcomingCategories={dailyUpcomingImpactCounts?.[i]}
                    impactedUntaskedCategories={dailyUntaskedImpactCounts?.[i]}
                    collapsedCategories={collapsedCategories}
                    setSelectedCategoryName={setSelectedCategoryName}
                    setSelectedTaskId={setSelectedTaskId}
                    dataRows={filteredDataRows}
                    isPrintable={isPrintable}
                    tasks={tasks}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </ForecastTableContainer>
  );
};
