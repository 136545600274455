import "theme/styles.css";
import "theme/variables.css";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MuiThemeProvider } from "@material-ui/core";

import { materialTheme } from "theme/materialTheme";
import MainRoutes from "Routers/Main";
import { useAppSelector } from "store";
import { selectUserId } from "store/auth";
import Initialising from "Components/Initialising/Initialising";
import { useSetAnalyticsUserId, useAuthStatus } from "hooks";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { ErrorComponent } from "Components/ErrorComponent";
import { User } from "firebase/auth";

const router = (mfaUser?: User) =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="*"
        element={
          <div className="min-h-[85rem] h-full overflow-hidden flex flex-col flex-nowrap">
            <MainRoutes mfaUser={mfaUser} />
          </div>
        }
        errorElement={ErrorComponent}
      />
    )
  );

const App = () => {
  const { appInitialised, mfaUser } = useAuthStatus();
  const userId = useAppSelector(selectUserId);

  useSetAnalyticsUserId(userId!);

  if (appInitialised) {
    return (
      <MuiThemeProvider theme={materialTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <RouterProvider router={router(mfaUser)} />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }

  return (
    <Initialising>
      <p>Initialising app...</p>
    </Initialising>
  );
};

export default App;
