import { FC } from "react";
import {
  IDailyForecast,
  IForecastReading,
  warningPrecipitation,
  warningTemperature,
  warningWind,
  WeatherThresholds,
} from "@ehabitation/ts-utils/browser";

const threeDigitsMax = (value: number, decimal = false): string => {
  if (value >= 1000) {
    if (value >= 10000) {
      return Math.round(value / 1000) + "k";
    }
    return (value / 1000).toFixed(1) + "k";
  }
  if (decimal && value < 10) {
    return value.toFixed(1);
  }
  return String(Math.round(value));
};

const Value = ({
  data: { unit, value },
  className = "",
  decimal = false,
}: {
  data: IForecastReading;
  className?: string;
  decimal?: boolean;
}) => {
  return (
    <div className={`flex gap-1 ${className}`}>
      <div className="text-gray-900">
        {typeof value === "number" ? threeDigitsMax(value, decimal) : "-"}{" "}
      </div>
      <div className="text-[0.7em] align-super w-8 invisible relative text-gray-900">
        &nbsp;
        <span className="visible absolute left-0 top-0">{unit}</span>
      </div>
    </div>
  );
};

const Precipitation: FC<{
  data: IDailyForecast;
  thresholds?: WeatherThresholds;
}> = ({ data, thresholds }) => {
  const title =
    data.precipitationType?.value != "none" && data.precipitationType?.value;
  const dailyRainValue = data.dailyRainAcc.value;
  const hasDailyValue = typeof dailyRainValue === "number";
  const isDailyRainBroken =
    hasDailyValue && typeof thresholds?.dailyRainAcc === "number"
      ? dailyRainValue > thresholds?.dailyRainAcc
      : undefined;
  const isDailyRainWarning =
    hasDailyValue && typeof thresholds?.dailyRainAcc === "number"
      ? dailyRainValue >
        (thresholds?.dailyRainAcc > warningPrecipitation
          ? thresholds?.dailyRainAcc - warningPrecipitation
          : 0)
      : undefined;

  const hourlyRainValue = data.hourlyRainAcc.value;
  const hasHourlyValue = typeof hourlyRainValue === "number";
  const isHourlyRainBroken =
    hasHourlyValue && typeof thresholds?.hourlyRainAcc === "number"
      ? hourlyRainValue > thresholds?.hourlyRainAcc
      : undefined;
  const isHourlyRainWarning =
    hasHourlyValue && typeof thresholds?.hourlyRainAcc === "number"
      ? hourlyRainValue >
        (thresholds?.hourlyRainAcc > warningPrecipitation
          ? thresholds?.hourlyRainAcc - warningPrecipitation
          : 0)
      : undefined;

  return (
    <Container
      title={title || "Rain"}
      thresholdBroken={isDailyRainBroken || isHourlyRainBroken}
      upper={
        <>
          <span className="text-base align-baseline text-gray-600">Day</span>
          <Value
            className={
              isDailyRainBroken
                ? "bg-rose-100"
                : isDailyRainWarning
                ? "bg-amber-100"
                : ""
            }
            data={data.dailyRainAcc!}
            decimal={true}
          />
        </>
      }
      lower={
        <>
          <span className="text-base align-baseline text-gray-600">Max</span>
          <Value
            className={
              isHourlyRainBroken
                ? "bg-rose-100"
                : isHourlyRainWarning
                ? "bg-amber-100"
                : ""
            }
            data={data.hourlyRainAcc!}
            decimal={true}
          />
        </>
      }
    />
  );
};
const Wind: FC<{ data: IDailyForecast; thresholds?: WeatherThresholds }> = ({
  data,
  thresholds,
}) => {
  const maxWindValue = data.maxWindSpeed.value;
  const hasValue = typeof maxWindValue === "number";
  const isWindBroken =
    hasValue && typeof thresholds?.wind === "number"
      ? maxWindValue > thresholds?.wind
      : undefined;
  const isWindWarning =
    hasValue && typeof thresholds?.wind === "number"
      ? maxWindValue >
        (thresholds?.wind > warningWind ? thresholds?.wind - warningWind : 0)
      : undefined;
  return (
    <Container
      title="Wind"
      thresholdBroken={isWindBroken}
      upper={
        <>
          <span className="text-base align-baseline text-gray-600">Min</span>
          <Value data={data.minWindSpeed!} />
        </>
      }
      lower={
        <>
          <span className="text-base align-baseline text-gray-600">Max</span>
          <Value
            className={
              isWindBroken ? "bg-rose-100" : isWindWarning ? "bg-amber-200" : ""
            }
            data={data.maxWindSpeed!}
          />
        </>
      }
    />
  );
};
const Temp: FC<{ data: IDailyForecast; thresholds?: WeatherThresholds }> = ({
  data,
  thresholds,
}) => {
  const minTempValue = data.minTemp.value;
  const maxTempValue = data.maxTemp.value;
  const hasMinTempValue = typeof minTempValue === "number";
  const hasMaxTempValue = typeof maxTempValue === "number";
  const isMinTempBroken =
    hasMinTempValue && typeof thresholds?.minTemp === "number"
      ? minTempValue < thresholds?.minTemp
      : undefined;
  const isMaxTempBroken =
    hasMaxTempValue && typeof thresholds?.maxTemp === "number"
      ? maxTempValue > thresholds?.maxTemp
      : undefined;
  const isMinTempWarning = isMinTempBroken
    ? false
    : hasMinTempValue && typeof thresholds?.minTemp === "number"
    ? minTempValue < thresholds?.minTemp + warningTemperature
    : undefined;
  const isMaxTempWarning = isMaxTempBroken
    ? false
    : hasMaxTempValue && typeof thresholds?.maxTemp === "number"
    ? maxTempValue >
      (thresholds?.maxTemp > warningTemperature
        ? thresholds?.maxTemp - warningTemperature
        : 0)
    : undefined;
  return (
    <Container
      title="Temp"
      thresholdBroken={isMinTempBroken || isMaxTempBroken}
      upper={
        <>
          <span className="text-base align-baseline text-gray-600">Min</span>
          <Value
            className={
              isMinTempBroken
                ? "bg-rose-100"
                : isMinTempWarning
                ? "bg-amber-200"
                : ""
            }
            data={data.minTemp!}
          />
        </>
      }
      lower={
        <>
          <span className="text-base align-baseline text-gray-600">Max</span>
          <Value
            className={
              isMaxTempBroken
                ? "bg-rose-100"
                : isMaxTempWarning
                ? "bg-amber-200"
                : ""
            }
            data={data.maxTemp!}
          />
        </>
      }
    />
  );
};

const Container: FC<{
  upper?: any;
  lower?: any;
  title: string;
  thresholdBroken?: boolean;
}> = ({ upper, lower, title, thresholdBroken }) => {
  return (
    <div className="flex flex-col items-stretch">
      <h3
        className={`tracking-widest text-xl capitalize font-[500] border-b-2 border-solid ${
          thresholdBroken ? "border-rose-200" : "border-[#1CEAA0]"
        }`}
      >
        {title}
      </h3>
      {upper ? <div className="flex items-center gap-2">{upper}</div> : null}
      {lower ? <div className="flex items-center gap-2">{lower}</div> : null}
    </div>
  );
};

export const Weather = Object.assign(() => <div>WEATHER!</div>, {
  Precipitation,
  Wind,
  Temp,
  Container,
});
