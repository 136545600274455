import { doc, updateDoc } from "firebase/firestore";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";

import { AppThunk } from "store";
import { addHoliday, deleteHoliday } from "store/calendar";
import { selectActiveSiteId } from "store/auth";
import { db } from "firebaseConfig";

export const addHolidayDay: AppThunk =
  (date: MaterialUiPickersDate) => (dispatch, getState) => {
    const state = getState();
    const siteId = selectActiveSiteId(state);
    const holidays = state.calendar.holidays;

    const formattedDate = moment(date).startOf("day").format("DD/MM/YYYY");

    const newHolidays = Array.from(new Set([...holidays, formattedDate])); //set removes dupes

    updateDoc(doc(db, "calendar", siteId!), {
      holidays: newHolidays,
    });

    dispatch(addHoliday(formattedDate));
  };

export const removeHolidayDay: AppThunk =
  (date: string) => (dispatch, getState) => {
    const state = getState();
    const siteId = selectActiveSiteId(state);
    const holidays = state.calendar.holidays;

    const newHolidays = holidays.filter((holiday) =>
      date === holiday ? false : true
    );

    updateDoc(doc(db, "calendar", siteId!), {
      holidays: newHolidays,
    });

    dispatch(deleteHoliday(date));
  };
