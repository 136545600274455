import { FC } from "react";
import { KeyboardTimePicker } from "@material-ui/pickers";
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { StyledStartTimePicker, StyledWorkHoursError } from "./styles";
import { useChangeWorkHours } from "./useChangeWorkHours";
import { MonochromeButton } from "@ehabitation/ui";

interface IProps {
  open: boolean;
}

const ChangeWorkHoursDialog: FC<IProps> = ({ open }) => {
  const {
    selectedDay,
    startTime,
    endTime,
    workHoursError,
    setStartTime,
    setEndTime,
    handleSubmit,
    handleCloseModal,
  } = useChangeWorkHours();

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      onClose={handleCloseModal}
    >
      <DialogTitle id="form-dialog-title">
        Change {selectedDay} Work Hours
      </DialogTitle>

      <DialogContent>
        <StyledStartTimePicker
          label="Start Time"
          placeholder="08:00"
          mask="__:__"
          ampm={false}
          value={startTime}
          onChange={(date: Date | null) =>
            date && setStartTime(new Date(date!))
          }
          data-testid="edit-start-time"
        />

        <KeyboardTimePicker
          label="End Time"
          placeholder="18:00"
          mask="__:__"
          ampm={false}
          value={endTime}
          onChange={(date) => setEndTime(new Date(date!))}
          data-testid="edit-end-time"
        />

        <StyledWorkHoursError>{workHoursError}</StyledWorkHoursError>
      </DialogContent>

      <DialogActions>
        <MonochromeButton
          onClick={handleCloseModal}
          level="secondary"
          width="lg"
        >
          Cancel
        </MonochromeButton>

        <MonochromeButton onClick={handleSubmit} level="primary" width="lg">
          Save
        </MonochromeButton>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeWorkHoursDialog;
