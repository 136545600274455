import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import { TornadoRisk, TornadoAction } from "types";
import { determineBarColor } from "./helpers";

const BUTTON_ACTIVE_FILL = am4core.color("#a3a3a3");
const BUTTON_INACTIVE_FILL = am4core.color("#d9d9d9");

export const createSeries = (chart: am4charts.XYChart, field: TornadoRisk) => {
  // Setup
  const series = chart!.series.push(new am4charts.ColumnSeries());
  series.dataFields.categoryY = "activity";
  series.dataFields.valueX = field;
  series.sequencedInterpolation = true;

  series.id = `series-${field}`;

  // Styling
  addSeriesStyling(series, field);

  // onClick data
  series.dummyData = field;

  // Tooltip
  addSeriesTooltip(series, field);

  // Bar labels + show & hide
  addSeriesLabels(series);
};

export const setupTitleAndAxes = (
  chart: am4charts.XYChart,
  chartType: TornadoAction,
  setTornadoAction: (chartType: TornadoAction) => void
) => {
  // CategoryAxis setup
  let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.dataFields.category = "activity";
  categoryAxis.renderer.minGridDistance = 20;
  categoryAxis.renderer.inversed = true;
  categoryAxis.renderer.grid.template.disabled = true;
  categoryAxis.renderer.labels.template.truncate = true;
  categoryAxis.renderer.labels.template.maxWidth = 450;
  categoryAxis.renderer.minHeight = 10;
  categoryAxis.id = "tornado-category";

  // ValueAxis setup
  let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
  valueAxis.min = 0;
  valueAxis.calculateTotals = true;
  valueAxis.title.text = chartType === "max" ? "Days" : "Percent";
  valueAxis.title.marginTop = 40;
  valueAxis.title.id = "valueAxis-title";
  valueAxis.renderer.relativeHeight = 50;
  valueAxis.renderer.minHeight = 10;

  // Title setup
  let title = chart.titles.create();
  title.text =
    chartType === "max"
      ? "Total Weather Downtime Exposure"
      : "Proportional Weather Downtime Exposure";
  title.fontSize = 17;
  title.marginBottom = 30;
  title.id = "tornado-title";

  let buttonContainer = chart.createChild(am4core.Container);
  buttonContainer.layout = "horizontal";
  buttonContainer.align = "right";

  // Proportional button setup
  let proportionalButton = buttonContainer.createChild(am4core.Button);
  proportionalButton.width = 150;
  proportionalButton.marginTop = 30;
  proportionalButton.marginRight = 20;
  proportionalButton.label!.text = "Proportional";
  proportionalButton.events.on("hit", () => {
    setTornadoAction("weight");
  });
  proportionalButton.id = "tornado-button-proportional";
  proportionalButton.background.defaultState.properties.fill =
    chartType === "max" ? BUTTON_INACTIVE_FILL : BUTTON_ACTIVE_FILL;

  // Total button setup
  let totalButton = buttonContainer.createChild(am4core.Button);
  totalButton.width = 150;
  totalButton.marginTop = 30;
  totalButton.label!.text = "Total";
  totalButton.events.on("hit", () => {
    setTornadoAction("max");
  });
  totalButton.id = "tornado-button-total";
  totalButton.background.defaultState.properties.fill =
    chartType === "weight" ? BUTTON_INACTIVE_FILL : BUTTON_ACTIVE_FILL;
};

export const setNoDelayDataIndicator = (chart: am4charts.XYChart) => {
  let loaderIndicator = chart.tooltipContainer!.createChild(am4core.Container);
  loaderIndicator.background.fill = am4core.color("#fff");
  loaderIndicator.background.fillOpacity = 1;
  loaderIndicator.width = am4core.percent(100);
  loaderIndicator.height = am4core.percent(100);

  let indicatorLabel = loaderIndicator.createChild(am4core.Label);
  indicatorLabel.text = "No delay data for this plan";
  indicatorLabel.align = "center";
  indicatorLabel.valign = "middle";
  indicatorLabel.fontSize = 18;
};

const formatTornadoTooltip = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const addSeriesTooltip = (
  series: am4charts.ColumnSeries,
  field: TornadoRisk
) => {
  series.columns.template.adapter.add("tooltipText", (text, target) => {
    if (field === "percent") {
      return `${target.dataItem?.values.valueX.value || 0} %`;
    }
    return `${text}: ${
      (target.dataItem?.values?.valueX?.stack || 0) +
      (target.dataItem?.values.valueX.value || 0)
    } day(s)`;
  });

  series.columns.template.tooltipText = formatTornadoTooltip(field);
  series.columns.template.showTooltipOn = "hover";
};

const addSeriesLabels = (series: am4charts.ColumnSeries) => {
  let labelBullet = series.bullets.push(new am4charts.LabelBullet());
  labelBullet.label.horizontalCenter = "left";
  labelBullet.label.dx = 10;
  labelBullet.label.text = "{values.valueX.workingValue.formatNumber('#')}";
  labelBullet.locationX = 1;
  labelBullet.interactionsEnabled = false;
  labelBullet.id = "series-value-label";

  series.columns.template.events.on("sizechanged", (ev: any) => {
    if (ev.target.dataItem && ev.target.dataItem.bullets) {
      const width = ev.target.pixelWidth;
      ev.target.dataItem.bullets.each((id: string, bullet: any) => {
        if (width > 50) return bullet.show();
        return bullet.hide();
      });
    }
  });
};

const addSeriesStyling = (
  series: am4charts.ColumnSeries,
  field: TornadoRisk
) => {
  let hoverState = series.columns.template.states.create("hover");
  hoverState.properties.fill = am4core.color(determineBarColor(field));
  series.stacked = true;
  series.columns.template.height = 30;
  series.columns.template.stroke = am4core.color(determineBarColor(field));
  series.columns.template.fill = am4core.color("white");
  series.columns.template.strokeOpacity = 1;
};
