import Tooltip from "@material-ui/core/Tooltip";
import { isEmpty } from "lodash";
import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { BsInfoCircle } from "react-icons/bs";
import { FiAlertTriangle, FiX } from "react-icons/fi";
import { RiPlayLine } from "react-icons/ri";
import { useVirtual } from "react-virtual";

import {
  IPlan,
  ISimulation,
  ISite,
  ITask,
  WeatherKey,
} from "@ehabitation/ts-utils/browser";
import {
  Button,
  Filter,
  FilterButton,
  FilterIndicator,
  Input,
  LoadingType,
  LoadingWrapper,
} from "@ehabitation/ui";
import { simulateConfirmation } from "Components/Plan/TaskList/constants";
import StayOnPageAlert from "Components/StayOnPageAlert/StayOnPageAlert";
import {
  SimulationLevel,
  SimulationPlanResult,
  SimulationResult,
  SimulationTaskResult,
} from "helpers";
import { logVarianceDiff } from "helpers/analytics";
import { useSiteOpenPageEvent } from "hooks";
import { VscFilterFilled } from "react-icons/vsc";
import { EndDatesTable } from "../components/EndDatesTable";
import {
  getRowHeight,
  impactMitigationLevels,
  isOldFormatId,
} from "../constants";
import { wbsPaddingLevel } from "../helper";
import { useVarianceVisibleTasks } from "../hooks";
import { VarianceRow } from "./VarianceRow";
import VarianceSelector from "./VarianceSelector";

export interface VarianceViewProps {
  baseTasks: (ITask & { simulation: SimulationTaskResult })[];
  basePlan: IPlan;
  currentSite: ISite;
  baseSimulationPlanResult?: SimulationPlanResult;
  selectedVariancePlanId?: string;
  setSelectedVariancePlanId: (id: string) => void;
  varianceSimulation?: ISimulation;
  varianceSimulationResult?: SimulationResult;
  comparisonResultsByBaseTaskId: {
    [id: string]: ITask & { simulation: SimulationTaskResult };
  };
  comparisonTaskResultsNotInBase: {
    [id: string]: ITask & { simulation: SimulationTaskResult };
  };
  requestVarianceSimulation: () => void;
  loadingVarianceSimulation?: boolean;
  loadingVarianceSimulationResult?: boolean;
  plans: IPlan[];
  setPrintableLink: (id: string) => void;
  setHelpLink: (id: string) => void;
  allTasks: { [id: string]: ITask & { simulation: SimulationTaskResult } };
}

export const varianceSelectableColumns = [
  {
    key: "proposedStartDate",
    label: "Proposed Start Date",
  },
  { key: "plannedStartDate", label: "Planned Start Date" },
  { key: "proposedEndDate", label: "Proposed End Date" },
  { key: "plannedEndDate", label: "Planned End Date" },
  { key: "weatherDays", label: "Weather Days" },
  { key: "taskType", label: "Category" },
  {
    label: "Min Temp",
    key: WeatherKey.minTemp,
  },
  {
    label: "Max Temp",
    key: WeatherKey.maxTemp,
  },
  {
    label: "Wind",
    key: WeatherKey.wind,
  },
  {
    label: "24hr Rain",
    key: WeatherKey.dailyRainAcc,
  },
  {
    label: "Hourly Rain",
    key: WeatherKey.hourlyRainAcc,
  },
  {
    label: "Wave Ht.",
    key: WeatherKey.waveHeight,
  },
  {
    label: "Gust",
    key: WeatherKey.windGusts,
  },
  {
    label: "24hr Snow",
    key: WeatherKey.snowfall24Hour,
  },
  {
    label: "Hourly Snow",
    key: WeatherKey.snowfall,
  },
  {
    label: "Visibility",
    key: WeatherKey.visibility,
  },
];

export const getMitigatedTaskId = (oldId: string, planId: string | undefined) =>
  isOldFormatId(oldId) ? oldId : planId + "$$" + oldId.split("$$")[1];

const LoadingIsland: FC<{
  simulationLoading?: boolean;
  simulationFailed?: boolean;
  simulation?: ISimulation;
  simulationResultLoading?: boolean;
  isLoadingTasks?: boolean;
  setOpenSimulateConfirm: (isOpen: boolean) => void;
  variancePlan?: IPlan;
}> = ({
  simulationLoading,
  simulationFailed,
  simulation,
  simulationResultLoading,
  isLoadingTasks,
  setOpenSimulateConfirm,
  variancePlan,
}) => {
  const variancePlanReady =
    !!variancePlan &&
    ["imported", "complete", "categorised"].includes(
      variancePlan?.status as string
    );

  const loadingWrappers = (
    <LoadingWrapper
      message="Preparing plan..."
      type={LoadingType.subscriptions}
      loading={!variancePlanReady}
      white
    >
      <LoadingWrapper
        message="Loading tasks..."
        type={LoadingType.subscriptions}
        loading={simulationLoading || isLoadingTasks}
        white
      >
        {simulationFailed || !simulation?.status ? (
          <div className="flex-grow grid place-content-center gap-2">
            <p className="font-bold text-white">
              {simulationFailed
                ? "Simulation failed, click below to try again or contact support@ehab.co if error persists."
                : "No Simulation Found For Selected Plan."}
            </p>
            <Button
              className="mr-0 py-2"
              type="button"
              aria-label="Simulate"
              disabled={simulationLoading}
              onClick={async () => {
                setOpenSimulateConfirm(true);
              }}
            >
              <RiPlayLine className="inline" /> Simulate
            </Button>
          </div>
        ) : (
          <LoadingWrapper
            message={"Running simulation..."}
            type={LoadingType.subscriptions}
            loading={[
              "pending",
              "site_pending",
              "partial",
              "prepared",
            ].includes(simulation.status)}
            white
          >
            {simulation?.status === "complete" &&
            simulation.simulatedTasks &&
            simulation.simulatedTasks > 0 ? (
              <LoadingWrapper
                message={"Loading simulation..."}
                type={LoadingType.subscriptions}
                loading={simulationResultLoading}
                white
              >
                {null}
              </LoadingWrapper>
            ) : (
              <div className="flex-grow grid place-content-center gap-2">
                <p className="font-bold text-white">
                  Simulation found no weather risk for selected plan.
                </p>
              </div>
            )}
          </LoadingWrapper>
        )}
      </LoadingWrapper>
    </LoadingWrapper>
  );

  return simulationFailed ||
    !simulation?.status ||
    simulationLoading ||
    isLoadingTasks ||
    ["pending", "partial", "prepared"].includes(simulation?.status) ||
    simulationResultLoading ? (
    <div className="grid place-content-center absolute h-[calc(100%-5.7rem)] w-[40%] right-0 top-[5.7rem] z-[6]">
      <div className="opacity-90 bg-gray-700 py-6 px-8 max-w-md">
        {variancePlan ? (
          loadingWrappers
        ) : (
          <div className="flex-grow grid place-content-center gap-2">
            <p className="font-bold text-white">Select a variance plan.</p>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export const VarianceView: FC<VarianceViewProps> = ({
  baseTasks,
  basePlan,
  currentSite,
  baseSimulationPlanResult,
  selectedVariancePlanId,
  setSelectedVariancePlanId,
  varianceSimulation,
  varianceSimulationResult,
  comparisonResultsByBaseTaskId,
  comparisonTaskResultsNotInBase,
  requestVarianceSimulation,
  loadingVarianceSimulation,
  loadingVarianceSimulationResult,
  plans,
  setPrintableLink,
  setHelpLink,
  allTasks,
}) => {
  const [showOnlyImpactedTasks, setShowOnlyImpactedTasks] =
    useState<boolean>(true);
  const [showOnlyDifferenceTasks, setShowOnlyDifferenceTasks] =
    useState<boolean>(false);
  const tasksContainerRef = useRef<HTMLTableSectionElement>(null);

  const variancePlan = plans.find((p) => p.id === selectedVariancePlanId);

  const [selectedSimLevel, setSelectedSimLevel] =
    useState<SimulationLevel>("80");
  const [isOpenSimulateConfirm, setOpenSimulateConfirm] = useState(false);

  const [titleFilter, setTitleFilter] = useState("");

  const varianceFirstColumn = localStorage.getItem("variance:firstColumn");
  const varianceSecondColumn = localStorage.getItem("variance:secondColumn");
  const varianceThirdColumn = localStorage.getItem("variance:thirdColumn");
  const [selectedColumnValues, setSelectedColumnValues] = useState<{
    firstColumn: string;
    secondColumn: string;
    thirdColumn: string;
  }>({
    firstColumn: varianceFirstColumn || "proposedStartDate",
    secondColumn: varianceSecondColumn || "proposedEndDate",
    thirdColumn: varianceThirdColumn || "weatherDays",
  });

  const filterButtonRefs = {
    title: useRef<HTMLButtonElement>(null),
  };

  useSiteOpenPageEvent("variance", currentSite.id, basePlan?.id);

  const baseDelayDays = useMemo(() => {
    const days: { [key: string]: number } = {};
    if (baseSimulationPlanResult)
      for (const [keyTask, value] of Object.entries(baseTasks)) {
        impactMitigationLevels.forEach(({ key }) => {
          days[key] = days[key]
            ? days[key] +
              (value.simulation.riskIntervalResults?.[key].startDelay || 0)
            : value.simulation.riskIntervalResults?.[key].startDelay || 0;
        });
      }
    return days;
  }, [varianceSimulationResult]);

  const varianceDelayDays = useMemo(() => {
    const days: { [key: string]: number } = {};
    if (varianceSimulationResult?.taskResults)
      for (const [keyTask, value] of Object.entries(
        varianceSimulationResult?.taskResults
      )) {
        impactMitigationLevels.forEach(({ key }) => {
          days[key] = days[key]
            ? days[key] + (value.riskIntervalResults?.[key].startDelay || 0)
            : value.riskIntervalResults?.[key].startDelay || 0;
        });
      }
    return days;
  }, [varianceSimulationResult]);

  const { visibleTasks } = useVarianceVisibleTasks(
    allTasks,
    baseTasks,
    showOnlyImpactedTasks,
    titleFilter,
    comparisonResultsByBaseTaskId,
    showOnlyDifferenceTasks,
    selectedSimLevel
  );

  const { virtualItems: virtualRows, totalSize } = useVirtual({
    size: visibleTasks.length,
    parentRef: tasksContainerRef,
    estimateSize: useCallback(getRowHeight, []),
    overscan: 20,
  });

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  const varianceSimulationInProgress =
    loadingVarianceSimulation ||
    loadingVarianceSimulationResult ||
    (!!varianceSimulation &&
      !["error", "complete"].includes(varianceSimulation?.status)) ||
    (varianceSimulation?.status === "complete" &&
      varianceSimulation?.simulatedTasks > 0 &&
      !varianceSimulationResult);
  const varianceSimulationErrored =
    !!varianceSimulation && (varianceSimulation?.status as string) === "error";

  const varianceSimulationPending =
    varianceSimulationErrored || varianceSimulationInProgress;

  useEffect(() => {
    selectedVariancePlanId
      ? setPrintableLink(
          `/print/${currentSite.id}/${basePlan.id}/${selectedVariancePlanId}/variance`
        )
      : setPrintableLink("");
    return () => setPrintableLink("");
  }, [selectedVariancePlanId]);
  const storeImpactedTasksFilter = (value: boolean) => {
    if (selectedVariancePlanId) {
      value
        ? localStorage.removeItem(
            `variance_impactedTasks_${currentSite.id}_${basePlan.id}_${selectedVariancePlanId}`
          )
        : localStorage.setItem(
            `variance_impactedTasks_${currentSite.id}_${basePlan.id}_${selectedVariancePlanId}`,
            `${value}`
          );
    }
    setShowOnlyImpactedTasks(value);
  };

  const storeDifferenceTasksFilter = (value: boolean) => {
    if (selectedVariancePlanId) {
      value
        ? localStorage.removeItem(
            `variance_differenceTasks_${currentSite.id}_${basePlan.id}_${selectedVariancePlanId}`
          )
        : localStorage.setItem(
            `variance_differenceTasks_${currentSite.id}_${basePlan.id}_${selectedVariancePlanId}`,
            `${value}`
          );
    }
    setShowOnlyDifferenceTasks(value);
  };

  const storeTitleFilter = (value: string) => {
    if (selectedVariancePlanId) {
      value === ""
        ? localStorage.removeItem(
            `variance_title_${currentSite.id}_${basePlan.id}_${selectedVariancePlanId}`
          )
        : localStorage.setItem(
            `variance_title_${currentSite.id}_${basePlan.id}_${selectedVariancePlanId}`,
            value
          );
    }
    setTitleFilter(value);
  };
  useEffect(() => {
    localStorage.setItem(
      `variance_selectedSimLevel_${currentSite.id}_${basePlan.id}_${selectedVariancePlanId}`,
      `${selectedSimLevel}`
    );
  }, [selectedSimLevel, currentSite, basePlan, selectedVariancePlanId]);

  useEffect(() => {
    basePlan?.id &&
      selectedVariancePlanId &&
      logVarianceDiff(currentSite?.id, basePlan?.id, selectedVariancePlanId);
  }, [basePlan?.id, currentSite?.id, selectedVariancePlanId]);

  useEffect(() => {
    storeImpactedTasksFilter(true);
    storeTitleFilter("");
  }, []);

  useEffect(() => {
    setHelpLink(
      "https://ehab.co/docs/how-to-use-the-variation-analysis-dashboard/"
    );
    return () => setHelpLink("");
  }, []);

  return (
    <>
      {
        <>
          <div className="gap-4 pt-14 pb-14">
            <EndDatesTable
              plan={basePlan}
              selectedSimLevel={selectedSimLevel}
              setSelectedSimLevel={setSelectedSimLevel}
              simulationPlanResult={baseSimulationPlanResult}
              mitigationSimulationResult={varianceSimulationResult}
              chartTitle="Baseline vs Variation"
              subColumn1Title="Baseline"
              subColumn2Title="Variation"
            />
          </div>
          <div className="bg-gray-50 border border-gray-200 rounded-md gap-12 col-span-3 flex justify-between items-center px-4 z-20 mb-2">
            <div className="flex gap-4 px-4 py-2 items-center">
              <div className="text-3xl text-gray-500">
                <VscFilterFilled />
              </div>
              <Filter
                enabledText={titleFilter}
                title="Task Name"
                ref={filterButtonRefs.title}
              >
                {({ close }) => (
                  <>
                    <Input
                      autoFocus
                      autoComplete="off"
                      value={titleFilter}
                      onChange={(e) => storeTitleFilter(e.target.value)}
                      className="pt-0 pb-0 pl-4 pr-20 mr-2"
                    />
                    <button
                      className="absolute right-8 top-6 enabled:hover:opacity-80 disabled:text-gray-400"
                      aria-label="clear search"
                      disabled={!titleFilter}
                      onClick={() => {
                        close();
                        storeTitleFilter("");
                      }}
                    >
                      <FiX className="w-6 h-6" />
                    </button>
                  </>
                )}
              </Filter>
              <FilterButton
                isEnabled={showOnlyImpactedTasks}
                onClick={() => storeImpactedTasksFilter(!showOnlyImpactedTasks)}
                className="text-xl py-[0.35rem]"
              >
                Only impacted tasks
              </FilterButton>
              <FilterButton
                isEnabled={showOnlyDifferenceTasks}
                onClick={() =>
                  storeDifferenceTasksFilter(!showOnlyDifferenceTasks)
                }
                className="text-xl py-[0.35rem]"
              >
                Only tasks with differences
              </FilterButton>
            </div>
          </div>
          <div
            className={`min-h-[36rem] flex-auto overflow-auto border-b relative z-10`}
            ref={tasksContainerRef}
          >
            <div style={{ height: totalSize + 60 }}>
              <LoadingIsland
                simulationLoading={loadingVarianceSimulation}
                simulationFailed={
                  (varianceSimulation?.status as string) === "error"
                }
                simulation={varianceSimulation}
                simulationResultLoading={loadingVarianceSimulation}
                setOpenSimulateConfirm={setOpenSimulateConfirm}
                variancePlan={variancePlan}
              />
              <table className="mb-8 divide-y w-full table-fixed border-separate border-spacing-0">
                <thead className="bg-gray-50 sticky top-0 z-[6]">
                  <tr className="">
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-2xl font-semibold text-gray-900 sm:pl-6 w-[15rem] bg-white"
                    ></th>
                    <th
                      colSpan={3}
                      scope="colgroup"
                      className="bg-[#afffe2] bg-opacity-25 whitespace-nowrap py-5 text-center text-2xl font-semibold border text-gray-900 w-[35rem]"
                    >
                      <span className="p-2">Baseline - {basePlan.title}</span>
                    </th>
                    <th
                      colSpan={3}
                      scope="colgroup"
                      className="bg-[#1d2bdd] bg-opacity-10 whitespace-nowrap py-5 text-center text-2xl font-semibold border text-gray-900 w-[35rem]"
                    >
                      <div className="flex justify-center items-center">
                        <span className="p-2">Variation</span>
                        <VarianceSelector
                          currentSite={currentSite}
                          isShort={true}
                          plans={plans}
                          basePlan={basePlan}
                          handleSelectPlan={setSelectedVariancePlanId}
                          currentVariancePlan={variancePlan}
                        />
                        <Tooltip
                          arrow
                          title={
                            <span className="text-2xl">
                              {isEmpty(comparisonTaskResultsNotInBase)
                                ? "All impacted variation plan tasks found in base plan"
                                : `${
                                    Object.keys(
                                      comparisonTaskResultsNotInBase
                                    ).filter(
                                      (t) =>
                                        !(
                                          comparisonTaskResultsNotInBase[
                                            t
                                          ] as ITask & {
                                            simulation: SimulationTaskResult;
                                          }
                                        ).WBS
                                    ).length
                                  } impacted variation plan task${
                                    Object.keys(comparisonTaskResultsNotInBase)
                                      .length > 1
                                      ? "s"
                                      : ""
                                  } not found in base plan.`}
                            </span>
                          }
                        >
                          <span className="p-2 text-3xl">
                            {isEmpty(comparisonTaskResultsNotInBase) ? (
                              <BsInfoCircle className="text-gray-400" />
                            ) : (
                              <FiAlertTriangle className="text-amber-600" />
                            )}
                          </span>
                        </Tooltip>
                      </div>
                    </th>
                  </tr>
                  <tr className="border">
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-2xl font-semibold text-gray-900 sm:pl-6 border w-[15rem]"
                    >
                      Task Name{" "}
                      <FilterIndicator
                        isEnabled={!!titleFilter}
                        handleClick={() =>
                          filterButtonRefs.title.current?.click()
                        }
                        label="Open Task Name Filter"
                      />
                    </th>
                    <th
                      scope="col"
                      className="bg-[#afffe2] bg-opacity-25 py-3.5 pl-4 pr-3 text-2xl font-semibold text-gray-900 sm:pl-6 border w-[11rem] text-center"
                    >
                      <select
                        value={selectedColumnValues.firstColumn}
                        onChange={(e) => {
                          setSelectedColumnValues({
                            ...selectedColumnValues,
                            firstColumn: e.target.value,
                          });
                          localStorage.setItem(
                            "variance:firstColumn",
                            e.target.value
                          );
                        }}
                        className="text-xl py-2 pl-2 rounded-full border border-gray-800"
                      >
                        {varianceSelectableColumns.map((column) => (
                          <option
                            disabled={
                              column.key === selectedColumnValues.firstColumn ||
                              column.key ===
                                selectedColumnValues.secondColumn ||
                              column.key === selectedColumnValues.thirdColumn
                            }
                            key={column.key}
                            value={column.key}
                          >
                            {column.label}
                          </option>
                        ))}
                      </select>
                    </th>
                    <th
                      scope="col"
                      className="bg-[#afffe2] bg-opacity-25 py-3.5 pl-4 pr-3 text-2xl font-semibold text-gray-900 sm:pl-6 border w-[11rem] text-center"
                    >
                      <select
                        value={selectedColumnValues.secondColumn}
                        onChange={(e) => {
                          setSelectedColumnValues({
                            ...selectedColumnValues,
                            secondColumn: e.target.value,
                          });
                          localStorage.setItem(
                            "variance:secondColumn",
                            e.target.value
                          );
                        }}
                        className="text-xl py-2 pl-2 rounded-full border border-gray-800"
                      >
                        {varianceSelectableColumns.map((column) => (
                          <option
                            disabled={
                              column.key === selectedColumnValues.firstColumn ||
                              column.key ===
                                selectedColumnValues.secondColumn ||
                              column.key === selectedColumnValues.thirdColumn
                            }
                            key={column.key}
                            value={column.key}
                          >
                            {column.label}
                          </option>
                        ))}
                      </select>
                    </th>
                    <th
                      scope="col"
                      className="bg-[#afffe2] bg-opacity-25 py-3.5 pl-4 pr-3 text-2xl font-semibold text-gray-900 sm:pl-6 border w-[11rem] text-center"
                    >
                      <select
                        value={selectedColumnValues.thirdColumn}
                        onChange={(e) => {
                          setSelectedColumnValues({
                            ...selectedColumnValues,
                            thirdColumn: e.target.value,
                          });
                          localStorage.setItem(
                            "variance:thirdColumn",
                            e.target.value
                          );
                        }}
                        className="text-xl py-2 pl-2 rounded-full border border-gray-800"
                      >
                        {varianceSelectableColumns.map((column) => (
                          <option
                            disabled={
                              column.key === selectedColumnValues.firstColumn ||
                              column.key ===
                                selectedColumnValues.secondColumn ||
                              column.key === selectedColumnValues.thirdColumn
                            }
                            key={column.key}
                            value={column.key}
                          >
                            {column.label}
                          </option>
                        ))}
                      </select>
                    </th>
                    <th
                      scope="col"
                      className="bg-[#1d2bdd] bg-opacity-10 py-3.5 pl-4 pr-3 text-2xl font-semibold text-gray-900 sm:pl-6 border w-[11rem] text-center"
                    >
                      <span className="px-2 text-xl ">
                        {
                          varianceSelectableColumns.find(
                            (c) => c.key === selectedColumnValues.firstColumn
                          )?.label
                        }
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="bg-[#1d2bdd] bg-opacity-10 py-3.5 pl-4 pr-3 text-2xl font-semibold text-gray-900 sm:pl-6 border w-[11rem] text-center"
                    >
                      <span className="px-2 text-xl ">
                        {
                          varianceSelectableColumns.find(
                            (c) => c.key === selectedColumnValues.secondColumn
                          )?.label
                        }
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="bg-[#1d2bdd] bg-opacity-10 py-3.5 pl-4 pr-3 text-2xl font-semibold text-gray-900 sm:pl-6 border w-[11rem] text-center"
                    >
                      <span className="px-2 text-xl ">
                        {
                          varianceSelectableColumns.find(
                            (c) => c.key === selectedColumnValues.thirdColumn
                          )?.label
                        }
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody className="relative">
                  {paddingTop > 0 && (
                    <tr>
                      <td style={{ height: `${paddingTop}px` }} />
                    </tr>
                  )}
                  {virtualRows.map((virtualRow) => {
                    const visibleTask = visibleTasks[virtualRow.index];
                    const task = baseTasks.find((t) => t.id === visibleTask.id)
                      ? visibleTask
                      : undefined;
                    const varianceTaskResult: ITask & {
                      simulation: SimulationTaskResult;
                    } = task
                      ? comparisonResultsByBaseTaskId[task.id]
                      : visibleTask;

                    let wbsTask = undefined;
                    let wbsInBase = false;
                    if (task) {
                      task.WBS ? (wbsTask = task) : undefined;
                    } else {
                      visibleTask.WBS
                        ? baseTasks.find((t) => t.title === visibleTask.title)
                          ? (wbsInBase = true)
                          : (wbsTask = visibleTask)
                        : undefined;
                    }
                    if (wbsInBase) return null;
                    return wbsTask && wbsTask.WBS ? (
                      <tr
                        key={wbsTask.id}
                        className="sticky top-[10.5rem] z-[5] bg-white"
                      >
                        <td
                          style={wbsPaddingLevel(wbsTask.wbsHierarchyLevel)}
                          className={`px-4 truncate border-x border-b relative font-bold`}
                          colSpan={7}
                        >
                          {wbsTask.title}
                        </td>
                      </tr>
                    ) : (
                      <VarianceRow
                        selectedSimLevel={selectedSimLevel}
                        key={visibleTask.id}
                        task={task!}
                        varianceTaskResult={varianceTaskResult}
                        variancePlan={variancePlan}
                        varianceSimulationInProgress={
                          varianceSimulationInProgress
                        }
                        varianceSimulationPending={varianceSimulationPending}
                        simulation={!!varianceSimulation}
                        selectedColumnValues={selectedColumnValues}
                      />
                    );
                  })}
                  {paddingBottom > 0 && (
                    <tr>
                      <td style={{ height: `${paddingBottom}px` }} />
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {isOpenSimulateConfirm && (
            <StayOnPageAlert
              message={simulateConfirmation}
              handleStayOnPage={() => setOpenSimulateConfirm(false)}
              handleSave={() => {
                setOpenSimulateConfirm(false);
                requestVarianceSimulation();
              }}
              handleLeavePage={() => {
                setOpenSimulateConfirm(false);
              }}
              saveButtonText={"Simulate"}
              dontSaveButtonText="Cancel"
            />
          )}
        </>
      }
    </>
  );
};
