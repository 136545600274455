import Tooltip from "@material-ui/core/Tooltip";
import { isEmpty } from "lodash";
import moment from "moment";
import {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { BsInfoCircle } from "react-icons/bs";
import { FiAlertTriangle } from "react-icons/fi";
import { useVirtual } from "react-virtual";

import {
  CollectionType,
  IPlan,
  ISimulation,
  ISite,
  ITask,
  transformPlanDoc,
} from "@ehabitation/ts-utils/browser";
import {
  Filter,
  FilterButton,
  FilterIndicator,
  LoadingType,
  LoadingWrapper,
} from "@ehabitation/ui";
import Initialising from "Components/Initialising/Initialising";
import { useComparisonImpactedTasksNotInBase, useProcessedTasks, useSimulation, useSimulationResult, useTaskDocs } from "Pages/sites/hooks";
import { where } from "firebase/firestore";
import { SimulationLevel, SimulationTaskResult } from "helpers";
import {
  useCollectionQuery,
  usePersonalSites,
  usePlanRiskMatrix,
  useRiskMatrixTopLevelCategories,
  useSetupSubscriptions,
  useSiteOpenPrintPageEvent,
} from "hooks";
import { VscFilterFilled } from "react-icons/vsc";
import { useParams } from "react-router-dom";
import { useAppSelector } from "store";
import {
  selectOrganisationProjects,
  selectOrganisationSites,
} from "store/siteManagement";
import { IOrganisationSite } from "types";
import {
  DifferenceCell,
  ShortDifferenceDateCell,
} from "../components/DifferenceCell";
import { EndDatesTable } from "../components/EndDatesTable";
import { PrintableView } from "../components/PrintableView";
import {
  getRowHeight,
  impactMitigationLevels,
  isOldFormatId,
} from "../constants";
import { wbsPaddingLevel } from "../helper";
import {
  useVarianceVisibleTasks
} from "../hooks";
import { VarianceRow } from "./VarianceRow";
import { varianceSelectableColumns } from "./VarianceView";

export const getMitigatedTaskId = (oldId: string, planId: string | undefined) =>
  isOldFormatId(oldId) ? oldId : planId + "$$" + oldId.split("$$")[1];

const LoadingIsland: FC<{
  simulationLoading?: boolean;
  simulationFailed?: boolean;
  simulation?: ISimulation;
  simulationResultLoading?: boolean;
  isLoadingTasks?: boolean;
}> = ({
  simulationLoading,
  simulationFailed,
  simulation,
  simulationResultLoading,
  isLoadingTasks,
}) => {
  const loadingWrappers = (
    <LoadingWrapper
      message="Loading tasks..."
      type={LoadingType.subscriptions}
      loading={simulationLoading || isLoadingTasks}
    >
      {simulationFailed || !simulation?.status ? (
        <div className="flex-grow grid place-content-center gap-2">
          <p className="font-bold">
            {simulationFailed
              ? "Simulation failed, click below to try again or contact support@ehab.co if error persists."
              : "No Simulation Found For Selected Plan."}
          </p>
        </div>
      ) : (
        <LoadingWrapper
          message={"Running simulation..."}
          type={LoadingType.subscriptions}
          loading={["pending", "site_pending", "partial", "prepared"].includes(
            simulation.status
          )}
        >
          {simulation?.status === "complete" &&
          simulation.simulatedTasks &&
          simulation.simulatedTasks > 0 ? (
            <LoadingWrapper
              message={"Loading simulation..."}
              type={LoadingType.subscriptions}
              loading={simulationResultLoading}
            >
              {null}
            </LoadingWrapper>
          ) : (
            <div className="flex-grow grid place-content-center gap-2">
              <p className="font-bold">
                Simulation found no weather risk for selected plan.
              </p>
            </div>
          )}
        </LoadingWrapper>
      )}
    </LoadingWrapper>
  );

  return simulationFailed ||
    !simulation?.status ||
    simulationLoading ||
    isLoadingTasks ||
    ["pending", "site_pending", "partial", "prepared"].includes(
      simulation?.status
    ) ||
    simulationResultLoading ? (
    <div className="grid place-content-center absolute h-[calc(100%-5.7rem)] w-[40%] right-0 top-[5.7rem] z-[6]">
      <div className="opacity-90 bg-gray-700 text-white py-6 px-8 max-w-md">
        {loadingWrappers}
      </div>
    </div>
  ) : null;
};

const useLocalStorageValue = (key: string) =>
  useMemo(() => localStorage.getItem(key) || "", [key]);

export const VarianceViewPrintable: FC = () => {
  const subscriptionsReady = useSetupSubscriptions();

  const [currentSite, setSite] = useState<IOrganisationSite>();
  const [siteProject, setSiteProject] = useState<string>();
  const organisationSites = useAppSelector(selectOrganisationSites);

  const tasksContainerRef = useRef<HTMLTableSectionElement>(null);
  const projects = useAppSelector(selectOrganisationProjects);
  const {
    siteId = "",
    planId: selectedPlanId,
    variancePlanId,
  } = useParams<{
    siteId: string;
    planId: string;
    variancePlanId: string;
  }>();
  const { sites: personalSites = [] } = usePersonalSites();
  const { data: rawPlans = [] } = useCollectionQuery<IPlan>(
    CollectionType.Plans,
    where("site", "==", siteId)
  );

  const plans = useMemo(() => {
    return (
      rawPlans &&
      rawPlans
        .map((plan) => transformPlanDoc(plan.id, plan))
        .filter(({ status }) => status !== "discarded")
    );
  }, [rawPlans]);

  useEffect(() => {
    setSite(
      organisationSites[siteId] ||
        personalSites?.find(({ id }: ISite) => id === siteId)
    );
  }, [organisationSites, personalSites]);

  useEffect(() => {
    currentSite && setSiteProject(projects[currentSite.project]?.name);
  }, [projects, currentSite]);

  const basePlan = useMemo(() => {
    if (!plans || !selectedPlanId) {
      return undefined;
    }
    return plans?.find(({ id }) => selectedPlanId === id);
  }, [plans, selectedPlanId]);

  const variancePlan = useMemo(() => {
    if (!plans || !variancePlanId) {
      return undefined;
    }
    return plans.find(({ id }) => id === variancePlanId);
  }, [plans, selectedPlanId]);

  const { simulation: baseSimulation } = useSimulation(siteId, selectedPlanId);
  const { simulationResult: baseSimulationResult } =
    useSimulationResult(baseSimulation);

  useSiteOpenPrintPageEvent("variance", siteId, selectedPlanId);

  const {
    simulation: varianceSimulation,
    simulationLoading: loadingVarianceSimulation,
  } = useSimulation(siteId, variancePlanId);
  const {
    simulationResultLoading: loadingVarianceSimulationResult,
    simulationResult: varianceSimulationResult,
  } = useSimulationResult(baseSimulation);

  const { riskMatrix: baseRiskMatrix } = usePlanRiskMatrix(selectedPlanId);
  const { categories: baseCategories } = useRiskMatrixTopLevelCategories(
    baseRiskMatrix?.id
  );

  const { riskMatrix: varianceRiskMatrix } = usePlanRiskMatrix(variancePlanId);
  const { categories: varianceCategories } = useRiskMatrixTopLevelCategories(
    varianceRiskMatrix?.id
  );

  const { taskDocs: baseTaskDocs } = useTaskDocs(siteId!, selectedPlanId);

  const { taskDocs: varianceTaskDocs } = useTaskDocs(siteId!, variancePlanId);

  const { tasks: baseTasks = [] } = useProcessedTasks(
    siteId!,
    baseCategories,
    baseTaskDocs,
    baseSimulationResult
  );

  const { tasks: varianceTasks } = useProcessedTasks(
    siteId!,
    varianceCategories,
    varianceTaskDocs,
    varianceSimulationResult
  );

  const {
    comparisonResultsByBaseTaskId,
    comparisonTaskResultsNotInBase,
    allTasks,
  } = useComparisonImpactedTasksNotInBase(
    baseTasks,
    varianceTasks,
    variancePlanId,
    selectedPlanId
  );

  const varianceFirstColumn = localStorage.getItem("variance:firstColumn");
  const varianceSecondColumn = localStorage.getItem("variance:secondColumn");
  const varianceThirdColumn = localStorage.getItem("variance:thirdColumn");
  const selectedColumnValues: {
    firstColumn: string;
    secondColumn: string;
    thirdColumn: string;
  } = {
    firstColumn: varianceFirstColumn || "proposedStartDate",
    secondColumn: varianceSecondColumn || "proposedEndDate",
    thirdColumn: varianceThirdColumn || "weatherDays",
  };

  const titleFilter = useLocalStorageValue(
    `variance_title_${siteId}_${selectedPlanId}_${variancePlanId}`
  );
  const showOnlyImpactedTasks =
    useLocalStorageValue(
      `variance_impactedTasks_${siteId}_${selectedPlanId}_${variancePlanId}`
    ) === "false"
      ? false
      : true;
  const showOnlyDifferenceTasks =
    useLocalStorageValue(
      `variance_differenceTasks_${siteId}_${selectedPlanId}_${variancePlanId}`
    ) === "false"
      ? false
      : true;

  const selectedSimLevel = useLocalStorageValue(
    `variance_selectedSimLevel_${siteId}_${selectedPlanId}_${variancePlanId}`
  ) as SimulationLevel;

  const baseDelayDays = useMemo(() => {
    const days: { [key: string]: number } = {};
    if (baseSimulationResult?.planResults && baseTasks)
      for (const value of Object.values(baseTasks)) {
        impactMitigationLevels.forEach(({ key }) => {
          days[key] = days[key]
            ? days[key] +
              (value.simulation.riskIntervalResults?.[key].startDelay || 0)
            : value.simulation.riskIntervalResults?.[key].startDelay || 0;
        });
      }
    return days;
  }, [varianceSimulationResult]);

  const varianceDelayDays = useMemo(() => {
    const days: { [key: string]: number } = {};
    if (varianceSimulationResult?.taskResults)
      for (const value of Object.values(
        varianceSimulationResult?.taskResults
      )) {
        impactMitigationLevels.forEach(({ key }) => {
          days[key] = days[key]
            ? days[key] + (value.riskIntervalResults?.[key].startDelay || 0)
            : value.riskIntervalResults?.[key].startDelay || 0;
        });
      }
    return days;
  }, [varianceSimulationResult]);

  const { visibleTasks } = useVarianceVisibleTasks(
    allTasks,
    baseTasks,
    showOnlyImpactedTasks,
    titleFilter,
    comparisonResultsByBaseTaskId,
    showOnlyDifferenceTasks,
    selectedSimLevel
  );

  const { virtualItems: virtualRows, totalSize } = useVirtual({
    size: visibleTasks.length,
    parentRef: tasksContainerRef,
    estimateSize: useCallback(getRowHeight, []),
    overscan: 20,
  });

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  const varianceSimulationInProgress =
    loadingVarianceSimulation ||
    loadingVarianceSimulationResult ||
    (!!varianceSimulation &&
      !["error", "complete"].includes(varianceSimulation?.status)) ||
    (varianceSimulation?.status === "complete" &&
      varianceSimulation?.simulatedTasks > 0 &&
      !varianceSimulationResult);
  const varianceSimulationErrored =
    !!varianceSimulation && (varianceSimulation?.status as string) === "error";

  const varianceSimulationPending =
    varianceSimulationErrored || varianceSimulationInProgress;
  if (!subscriptionsReady)
    return (
      <Initialising>
        <p>Preparing your workspace...</p>
      </Initialising>
    );

  return (
    <PrintableView page="Variance">
      <div>
        <div className="px-8 flex">
          <h2 className="text-4xl">Variance</h2>
          <p className="italic pl-4 pt-1">
            for site <strong>{currentSite?.name}</strong> in project{" "}
            <strong>{siteProject}</strong>, plan{" "}
            <strong>{basePlan?.title}</strong> (
            {basePlan?.id === currentSite?.mainPlanId ? "main" : "draft"})
          </p>
        </div>
        {
          <div className="px-8 pt-8">
            <div className="gap-4 pt-14 pb-14">
            <EndDatesTable
              plan={basePlan}
              simulationPlanResult={baseSimulationResult?.planResults}
              mitigationSimulationResult={varianceSimulationResult}
              chartTitle="Baseline vs Variation"
              subColumn1Title="Baseline"
              subColumn2Title="Variation"
            />
          </div>
            <div className="p-4">
              <div className="flex justify-between items-center">
                <div>
                  <div>Displaying</div>
                  <div className="text-7xl font-thin whitespace-nowrap">
                    {
                      impactMitigationLevels.find(
                        (level) => level.key === selectedSimLevel
                      )?.label
                    }
                  </div>
                </div>
                <table className="mb-8 w-full !border-0">
                  <thead className="z-10">
                    <tr>
                      <th className="text-end px-4">Risk level</th>
                      {impactMitigationLevels.map(({ key, label }) => (
                        <th
                          key={key}
                          className={`border hover:font-bold ${
                            selectedSimLevel === key && "font-bold bg-[#86EFAC]"
                          }`}
                          colSpan={2}
                        >
                          <input
                            readOnly
                            className="mr-2 align-middle accent-black"
                            type="radio"
                            value={key}
                            checked={selectedSimLevel === key}
                            name={key}
                          />
                          <span className="align-middle">{label}</span>
                        </th>
                      ))}
                    </tr>
                    <tr>
                      <th></th>
                      {impactMitigationLevels.map(({ key }) => (
                        <Fragment key={`header_${key}`}>
                          <th
                            key={`header_${key}_pre`}
                            className="bg-gray-50 border text-xl font-normal"
                          >
                            Pre
                          </th>
                          <th
                            key={`header_${key}_post`}
                            className="bg-gray-50 border text-xl font-normal"
                          >
                            Post
                          </th>
                        </Fragment>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    <tr>
                      <td className="text-end px-4">Start date</td>
                      <td colSpan={10} className="border">
                        <span>
                          {basePlan?.start || currentSite?.startDate
                            ? moment(
                                basePlan?.start || currentSite?.startDate
                              ).format("D/M/YY")
                            : "-"}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-end px-4">End date</td>
                      {impactMitigationLevels.map(({ key }) => (
                        <Fragment key={`date_${key}`}>
                          <td
                            key={`date_${key}_pre`}
                            className={`border ${
                              selectedSimLevel === key ? "font-bold" : ""
                            }`}
                          >
                            {moment(
                              baseSimulationResult?.planResults
                                ?.riskIntervalResults?.[key]?.finishDate
                            ).format("D/M/YY")}
                          </td>
                          <td
                            key={`date_${key}_post`}
                            className={`border ${
                              selectedSimLevel === key ? "font-bold" : ""
                            }`}
                          >
                            {varianceSimulationResult &&
                            baseSimulationResult?.planResults
                              ?.riskIntervalResults?.[key]?.finishDate ? (
                              <span className="relative">
                                <ShortDifferenceDateCell
                                  base={
                                    baseSimulationResult?.planResults
                                      ?.riskIntervalResults?.[key]?.finishDate
                                  }
                                  mitigated={
                                    varianceSimulationResult?.planResults
                                      .riskIntervalResults?.[key]?.finishDate
                                  }
                                />
                              </span>
                            ) : null}
                          </td>
                        </Fragment>
                      ))}
                    </tr>
                    <tr>
                      <td className="text-end px-4"># Weather Days</td>
                      {impactMitigationLevels.map(({ key }) => (
                        <Fragment key={`Weather_${key}`}>
                          <td
                            key={`Weather_${key}_pre`}
                            className={`border relative ${
                              selectedSimLevel === key ? "font-bold" : ""
                            }`}
                          >
                            {baseSimulationResult?.planResults
                              ?.riskIntervalResults?.[key]?.tasksImpacted || 0}
                          </td>
                          <td
                            key={`Weather_${key}_post`}
                            className={`border relative ${
                              selectedSimLevel === key ? "font-bold" : ""
                            }`}
                          >
                            <DifferenceCell
                              base={
                                baseSimulationResult?.planResults
                                  ?.riskIntervalResults?.[key]?.tasksImpacted ||
                                0
                              }
                              mitigated={
                                varianceSimulationResult?.planResults
                                  .riskIntervalResults?.[key]?.tasksImpacted
                              }
                            />
                          </td>
                        </Fragment>
                      ))}
                    </tr>
                    <tr>
                      <td className="text-end px-4"># Dep. Delay Days</td>
                      {impactMitigationLevels.map(({ key }) => (
                        <Fragment key={`dep_delay_${key}`}>
                          <td
                            key={`dep_delay_${key}_pre`}
                            className={`border relative ${
                              selectedSimLevel === key ? "font-bold" : ""
                            }`}
                          >
                            <span>
                              {varianceSimulation?.simulatedTasks &&
                              varianceSimulation?.simulatedTasks > 0
                                ? baseDelayDays[key]
                                : 0}
                            </span>
                          </td>
                          <td
                            key={`dep_delay_${key}_post`}
                            className={`border relative ${
                              selectedSimLevel === key ? "font-bold" : ""
                            }`}
                          >
                            {varianceSimulationPending ||
                            !varianceSimulation ? (
                              "?"
                            ) : (
                              <DifferenceCell
                                base={
                                  varianceSimulation?.simulatedTasks &&
                                  varianceSimulation?.simulatedTasks > 0
                                    ? baseDelayDays[key]
                                    : 0
                                }
                                mitigated={
                                  varianceSimulation?.simulatedTasks &&
                                  varianceSimulation?.simulatedTasks > 0
                                    ? varianceDelayDays[key]
                                    : 0
                                }
                              />
                            )}
                          </td>
                        </Fragment>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="bg-gray-50 border border-gray-200 rounded-md gap-12 col-span-3 flex justify-between items-center px-4 z-20 mb-2">
              <div className="flex gap-4 px-4 py-2 items-center">
                <div className="text-3xl text-gray-500">
                  <VscFilterFilled />
                </div>
                <Filter
                  enabledText={titleFilter}
                  title="Task Name"
                  disabled={true}
                >
                  {() => <></>}
                </Filter>
                <FilterButton
                  isEnabled={showOnlyImpactedTasks}
                  disabled={true}
                  className="text-xl py-[0.35rem]"
                >
                  Only impacted tasks
                </FilterButton>
                <FilterButton
                  isEnabled={showOnlyDifferenceTasks}
                  disabled={true}
                  className="text-xl py-[0.35rem]"
                >
                  Only tasks with differences
                </FilterButton>
              </div>
            </div>
            <div
              className={`pl-4 min-h-[36rem] flex-auto relative`}
              ref={tasksContainerRef}
            >
              <div>
                <LoadingIsland
                  simulationLoading={false}
                  simulationFailed={
                    (varianceSimulation?.status as string) === "error"
                  }
                  simulation={varianceSimulation}
                  simulationResultLoading={false}
                />
                <table className="mb-8 divide-y w-full table-fixed border-separate border-spacing-0">
                  <thead className="bg-gray-50 sticky top-0 z-[6]">
                    <tr className="">
                      <th
                        scope="col"
                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-2xl font-semibold text-gray-900 sm:pl-6 w-[15rem] bg-white"
                      ></th>
                      <th
                        colSpan={3}
                        scope="colgroup"
                        className="bg-[#afffe2] bg-opacity-25 whitespace-nowrap py-5 text-center text-2xl font-semibold border text-gray-900 w-[35rem]"
                      >
                        <span className="p-2">
                          Main Plan - {basePlan?.title}
                        </span>
                      </th>
                      <th
                        colSpan={3}
                        scope="colgroup"
                        className="bg-[#1d2bdd] bg-opacity-10 whitespace-nowrap py-5 text-center text-2xl font-semibold border text-gray-900 w-[35rem]"
                      >
                        <div className="flex justify-center items-center">
                          <span className="p-2">Variation:</span>{" "}
                          <span>{variancePlan?.title}</span>
                          <Tooltip
                            arrow
                            title={
                              <span className="text-2xl">
                                {isEmpty(comparisonTaskResultsNotInBase)
                                  ? "All variation plan tasks found in base plan"
                                  : `${
                                      Object.keys(
                                        comparisonTaskResultsNotInBase
                                      ).filter(
                                        (t) =>
                                          !(
                                            comparisonTaskResultsNotInBase[
                                              t
                                            ] as ITask & {
                                              simulation: SimulationTaskResult;
                                            }
                                          ).WBS
                                      ).length
                                    } impacted variation plan task${
                                      Object.keys(
                                        comparisonTaskResultsNotInBase
                                      ).length > 1
                                        ? "s"
                                        : ""
                                    } not found in base plan.`}
                              </span>
                            }
                          >
                            <span className="p-2 text-3xl">
                              {isEmpty(comparisonTaskResultsNotInBase) ? (
                                <BsInfoCircle className="text-gray-400" />
                              ) : (
                                <FiAlertTriangle className="text-amber-600" />
                              )}
                            </span>
                          </Tooltip>
                        </div>
                      </th>
                    </tr>
                    <tr className="border">
                      <th
                        scope="col"
                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-2xl font-semibold text-gray-900 sm:pl-6 border w-[15rem]"
                      >
                        Task Name{" "}
                        <FilterIndicator
                          isEnabled={!!titleFilter}
                          disabled={true}
                          label="Open Task Name Filter"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-[#afffe2] bg-opacity-25 py-3.5 pl-4 pr-3 text-2xl font-semibold text-gray-900 sm:pl-6 border w-[11rem] text-center"
                      >
                        <span className="px-2 text-xl">
                          {
                            varianceSelectableColumns.find(
                              (c) => c.key === selectedColumnValues.firstColumn
                            )?.label
                          }
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="bg-[#afffe2] bg-opacity-25 py-3.5 pl-4 pr-3 text-2xl font-semibold text-gray-900 sm:pl-6 border w-[11rem] text-center"
                      >
                        <span className="px-2 text-xl">
                          {
                            varianceSelectableColumns.find(
                              (c) => c.key === selectedColumnValues.secondColumn
                            )?.label
                          }
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="bg-[#afffe2] bg-opacity-25 py-3.5 pl-4 pr-3 text-2xl font-semibold text-gray-900 sm:pl-6 border w-[11rem] text-center"
                      >
                        <span className="px-2 text-xl">
                          {
                            varianceSelectableColumns.find(
                              (c) => c.key === selectedColumnValues.thirdColumn
                            )?.label
                          }
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="bg-[#1d2bdd] bg-opacity-10 py-3.5 pl-4 pr-3 text-2xl font-semibold text-gray-900 sm:pl-6 border w-[11rem] text-center"
                      >
                        <span className="px-2 text-xl">
                          {
                            varianceSelectableColumns.find(
                              (c) => c.key === selectedColumnValues.firstColumn
                            )?.label
                          }
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="bg-[#1d2bdd] bg-opacity-10 py-3.5 pl-4 pr-3 text-2xl font-semibold text-gray-900 sm:pl-6 border w-[11rem] text-center"
                      >
                        <span className="px-2 text-xl">
                          {
                            varianceSelectableColumns.find(
                              (c) => c.key === selectedColumnValues.secondColumn
                            )?.label
                          }
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="bg-[#1d2bdd] bg-opacity-10 py-3.5 pl-4 pr-3 text-2xl font-semibold text-gray-900 sm:pl-6 border w-[11rem] text-center"
                      >
                        <span className="px-2 text-xl ">
                          {
                            varianceSelectableColumns.find(
                              (c) => c.key === selectedColumnValues.thirdColumn
                            )?.label
                          }
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="relative">
                    {paddingTop > 0 && (
                      <tr>
                        <td style={{ height: `${paddingTop}px` }} />
                      </tr>
                    )}
                    {virtualRows.map((virtualRow) => {
                      const visibleTask = visibleTasks[virtualRow.index];
                      const task = baseTasks.find(
                        (t) => t.id === visibleTask.id
                      )
                        ? visibleTask
                        : undefined;
                      const varianceTaskResult: ITask & {
                        simulation: SimulationTaskResult;
                      } = task
                        ? comparisonResultsByBaseTaskId[task.id]
                        : visibleTask;

                      let wbsTask = undefined;
                      let wbsInBase = false;
                      if (task) {
                        task.WBS ? (wbsTask = task) : undefined;
                      } else {
                        visibleTask.WBS
                          ? baseTasks.find((t) => t.title === visibleTask.title)
                            ? (wbsInBase = true)
                            : (wbsTask = visibleTask)
                          : undefined;
                      }
                      if (wbsInBase) return null;
                      return wbsTask && wbsTask.WBS ? (
                        <tr
                          key={wbsTask.id}
                          className="sticky top-[10.5rem] z-[5] bg-white"
                        >
                          <td
                            style={wbsPaddingLevel(wbsTask.wbsHierarchyLevel)}
                            className={`px-4 truncate border-x border-b relative font-bold`}
                            colSpan={7}
                          >
                            {wbsTask.title}
                          </td>
                        </tr>
                      ) : (
                        <VarianceRow
                          selectedSimLevel={selectedSimLevel}
                          key={visibleTask.id}
                          task={task!}
                          varianceTaskResult={varianceTaskResult}
                          variancePlan={variancePlan}
                          varianceSimulationInProgress={
                            varianceSimulationInProgress
                          }
                          simulation={!!varianceSimulation}
                          varianceSimulationPending={varianceSimulationPending}
                          selectedColumnValues={selectedColumnValues}
                        />
                      );
                    })}
                    {paddingBottom > 0 && (
                      <tr>
                        <td style={{ height: `${paddingBottom}px` }} />
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        }
        <div className="pt-4">
          <div className="sticky print:static bg-gray-200 text-center bottom-0 w-full z-10">
            <span className="px-6">
              Don&apos;t have an ehab account? Sign up{" "}
              <a target="_blak" href="/" className="underline">
                here
              </a>{" "}
              for free weather insights
            </span>
          </div>
        </div>
      </div>
    </PrintableView>
  );
};
