import { db } from "firebaseConfig";
import {
  CollectionType,
  ITask,
  ITaskCategory,
  SimulationTrigger,
  SimulationType,
  WeatherKey,
} from "@ehabitation/ts-utils/browser";
import {
  updateDoc,
  doc,
  collection,
  writeBatch,
  onSnapshot,
  query,
  where,
  orderBy,
  getDocs,
  Timestamp,
  addDoc,
  deleteField,
  DocumentChange,
  DocumentData,
  WriteBatch,
  CollectionReference,
  limit,
} from "firebase/firestore";
import { getPlanRiskMatrixQuery } from "helpers";
import _ from "lodash";
import { firebaseFunction } from "helpers";

export const sanitizePlan = async (planId: string, siteId: string) => {
  const sanitize = firebaseFunction<{
    status: string;
    error: string;
  }>("SanitizeTasks", {timeout: 540000});
  const resp = await sanitize({ plan: planId, site: siteId });
  return resp.data;
};

const deleteWeatherKeys = Object.values(WeatherKey).reduce((acc, key) => {
  acc[key] = deleteField();
  return acc;
}, {} as any);

export const activateModel = async (
  site: string,
  plan: string,
  userId: string
) => {
  const simulation = {
    plan: plan,
    site: site,
    createdAt: Timestamp.now(),
    version: 2,
    status: "pending",
    trigger: {
      type: SimulationType.User,
      userId,
    } as SimulationTrigger,
  };

  await addDoc(collection(db, `plans/${plan}/simulations`), simulation);
};

export const completePlan = async (
  planId: string,
  siteId: string,
  userId: string
) => {
  await updateDoc(doc(db, CollectionType.Plans, planId), {
    status: "imported",
    complete: true,
  });
  await activateModel(siteId, planId, userId);
};

const updateTask = async (
  task: ITask,
  category: { id?: string; name: string; thresholds?: object },
  destructive: boolean,
  tasksCollection: CollectionReference<DocumentData>,
  batch: WriteBatch
) => {
  if (
    !(
      category.name == task.taskType ||
      (!destructive && task.taskCategory?.selectedName)
    )
  ) {
    batch.update(
      doc(tasksCollection, task.id),
      {
        taskType: category.name,
        "taskCategory.selectedId": category.id!,
        "taskCategory.selectedName": category.name,
        "taskCategory.selectedSource": "userInput",
        ...deleteWeatherKeys,
        ...category.thresholds,
      },
      { merge: true }
    );
  }
};

const updateMitigations = async (
  mitigationPlanId: string,
  changedObjectIds: string[],
  category: { id?: string; name: string; thresholds?: object }
) => {
  const mitigationTasksCollection = collection(
    db,
    CollectionType.Plans,
    mitigationPlanId,
    CollectionType.Tasks
  );
  const mitigationTasks = (
    await getDocs(query(mitigationTasksCollection))
  ).docs.reduce(
    (acc, doc) => acc.set((doc.data() as ITask).objectId!, doc.id),
    new Map<string, string>()
  );
  const updateMitigation = _.chunk(changedObjectIds, 300).map(
    (changedObjIdsChunk) => {
      const mitBatch = writeBatch(db);
      changedObjIdsChunk.forEach((objectId) => {
        const mitigationTaskId = mitigationTasks.get(objectId!);
        mitBatch.update(
          doc(mitigationTasksCollection, mitigationTaskId),
          {
            taskType: category.name,
            "taskCategory.selectedId": category.id,
            "taskCategory.selectedName": category.name,
            "taskCategory.selectedSource": "userInput",
            isMitigated: deleteField(),
            ...deleteWeatherKeys,
            ...category.thresholds,
          },
          { merge: true }
        );
      });
      return mitBatch;
    }
  );
  await Promise.all(updateMitigation.map((batch) => batch.commit()));
};

export const updateSelectedTasksCategories = async (
  tasks: Map<string, ITask>,
  selectedTaskIds: Set<string>,
  destructive: boolean,
  category: { id?: string; name: string; thresholds?: object },
  planId: string,
  mitigationPlanId: string | undefined,
  setIsLoading: (isLoading: boolean) => void,
  setError: (error: string) => void
) => {
  const changedObjectIds = [] as string[];
  try {
    const tasksCollection = collection(
      db,
      CollectionType.Plans,
      planId,
      CollectionType.Tasks
    );
    setIsLoading(true);
    const updateTasksBatches = _.chunk(Array.from(selectedTaskIds), 300).map(
      (selectedTaskIdsChunk) => {
        const batch = writeBatch(db);
        selectedTaskIdsChunk.forEach((id) => {
          const task = tasks.get(id);
          if (!task) throw new Error("Task not found");
          updateTask(task, category, destructive, tasksCollection, batch);
          changedObjectIds.push(task.objectId!);
        });
        return batch;
      }
    );

    await Promise.all(updateTasksBatches.map((batch) => batch.commit()));
    await updateDoc(doc(db, "plans", planId), { tasksUpdatedAt: new Date() });
    if (mitigationPlanId)
      await updateMitigations(mitigationPlanId, changedObjectIds, category);
    setIsLoading(false);
  } catch (error) {
    setError(
      "Failed to save progress, please re-open the import and try again. Contact support@ehab.co if this error persists."
    );
    console.error(error);
  }
};

export const updateTaskListCategories = async (
  tasks: Map<string, ITask>,
  orderedTaskIds: string[],
  startingPoint: number,
  destructive: boolean,
  category: { id?: string; name: string; thresholds?: object },
  planId: string,
  mitigationPlanId: string | undefined,
  setIsLoading: (isLoading: boolean) => void,
  setError: (error: string) => void
) => {
  setIsLoading(true);
  const startingTask = tasks.get(orderedTaskIds[startingPoint]);
  if (!startingTask) throw new Error("Starting task not found");
  const { wbsHierarchyLevel: wbsBreakLevel, WBS: startedOnWBS } = startingTask;
  const changedObjectIds = [] as string[];
  try {
    const tasksCollection = collection(
      db,
      CollectionType.Plans,
      planId,
      CollectionType.Tasks
    );
    let count = 0;
    let batch = writeBatch(db);
    for (let i = startingPoint; i < orderedTaskIds.length; i++) {
      if (count >= 500) {
        await batch.commit();
        batch = writeBatch(db);
        count = 0;
      }
      const loopTask = tasks.get(orderedTaskIds[i]);
      if (!loopTask) throw new Error("Loop task not found");
      if (loopTask.wbsHierarchyLevel! <= wbsBreakLevel! && i > startingPoint)
        break;
      if (loopTask.WBS) continue;
      if (!destructive && loopTask.taskCategory?.selectedName) continue;
      batch.update(
        doc(tasksCollection, loopTask.id),
        {
          taskType: category.name,
          "taskCategory.selectedId": category.id,
          "taskCategory.selectedName": category.name,
          "taskCategory.selectedSource": "userInput",
          isMitigated: deleteField(),
          ...deleteWeatherKeys,
          ...category.thresholds,
        },
        { merge: true }
      );
      changedObjectIds.push(loopTask.objectId!);
      if (!startedOnWBS) break;
      count++;
    }
    await batch.commit();
    if (mitigationPlanId)
      await updateMitigations(mitigationPlanId, changedObjectIds, category);
    await updateDoc(doc(db, CollectionType.Plans, planId), {
      tasksUpdatedAt: new Date(),
    });
    setIsLoading(false);
  } catch (error) {
    setError(
      "Failed to save progress, please re-open the import and try again. Contact support@ehab.co if this error persists."
    );
    console.error(error);
  }
};

export const clearTaskListCategories = async (
  tasks: ITask[],
  planId: string,
  setIsLoading: Function,
  setError: Function
) => {
  setIsLoading(true);
  try {
    const tasksCollection = collection(
      db,
      CollectionType.Plans,
      planId,
      CollectionType.Tasks
    );
    let count = 0;
    let batch = writeBatch(db);
    for (let i = 0; i < tasks.length; i++) {
      if (count >= 500) {
        await batch.commit();
        batch = writeBatch(db);
        count = 0;
      }
      batch.set(
        doc(tasksCollection, tasks[i].id),
        {
          taskType: deleteField(),
          // DH TODO: We shouldn't need to delete these values.
          dailyRainAcc: deleteField(),
          hourlyRainAcc: deleteField(),
          maxTemp: deleteField(),
          minTemp: deleteField(),
          wind: deleteField(),
          taskCategory: {
            ...tasks[i].taskCategory,
            selectedId: deleteField(),
            selectedName: deleteField(),
            selectedSource: deleteField(),
          },
        },
        { merge: true }
      );
      count++;
    }
    await batch.commit();
    setIsLoading(false);
  } catch (error) {
    setError(
      "Failed to save progress, please re-open the import and try again. Contact support@ehab.co if this error persists."
    );
    console.error(error);
  }
};

export const applyCategoryToTasks = async (
  category: any,
  targetTasks: ITask[],
  planId: string
) => {
  const tasksCollection = collection(
    db,
    CollectionType.Plans,
    planId,
    CollectionType.Tasks
  );
  let count = 0;
  let batch = writeBatch(db);
  for (let i = 0; i < targetTasks.length; i++) {
    if (count >= 500) {
      await batch.commit();
      batch = writeBatch(db);
      count = 0;
    }
    batch.set(
      doc(tasksCollection, targetTasks[i].id),
      {
        taskType: category.name,
        taskCategory: {
          ...targetTasks[i].taskCategory,
          selectedId: category.id || "",
          selectedName: category.name,
          selectedSource: "userInput",
        },
        ...category.thresholds,
      },
      { merge: true }
    );
    count++;
  }
  await batch.commit();
};

export const getOrderedTasksSubscriptionWS = async (
  planId: string,
  handleEmptyResults: () => Promise<void>,
  handleTaskDocChanges: (
    taskDocChanges: DocumentChange<DocumentData>[]
  ) => Promise<void>,
) => {
  const ref = collection(db, CollectionType.Plans, planId, CollectionType.Tasks);
  const q = query(ref, orderBy("wbsHierarchyOrder", "asc"))
  const result = await getDocs(q)
  console.info("Tasks results -->", result.size)
  if (result.empty) {
    return await handleEmptyResults();
  }
  await handleTaskDocChanges(result.docChanges())
};

export const getOrderedTasksSubscription = (
  planId: string,
  siteId: string,
  handleTaskDocChanges: (
    taskDocChanges: DocumentChange<DocumentData>[]
  ) => Promise<void>,
  handleEmptyResults: () => Promise<void>
) => {
  const unsubscribe = onSnapshot(
    query(
      collection(db, CollectionType.Plans, planId, CollectionType.Tasks),
      orderBy("wbsHierarchyOrder", "asc"),
      limit(10000)
    ),
    async (querySnapshot) => {
      if (!querySnapshot.empty && querySnapshot.docChanges().length) {
        const docChanges = querySnapshot.docChanges();
        await handleTaskDocChanges(docChanges);
      } else {
        await handleEmptyResults();
      }
    }
  );
  return unsubscribe;
};

export const getCategoriesByName = async (planId: string) => {
  const riskMatrixDocs = await getDocs(getPlanRiskMatrixQuery(planId));
  if (riskMatrixDocs.empty) {
    throw new Error("No risk matrix found for plan");
  }

  const riskMatrixCategorySnap = await getDocs(
    query(
      collection(
        db,
        riskMatrixDocs.docs[0].ref.path,
        CollectionType.Categories
      ),
      where("level", "==", "category")
    )
  );

  const categories = riskMatrixCategorySnap.docs.reduce((acc, catDoc) => {
    const cat = { id: catDoc.id, ...catDoc.data() } as ITaskCategory;
    acc.set(cat.name, cat);
    return acc;
  }, new Map<string, ITaskCategory>());

  const sortedCategories = new Map([
    [
      "No Weather Impact",
      {
        name: "No Weather Impact",
        id: "noWeatherImpact",
        thresholds: {},
        code: "noWeatherImpact",
        level: "category",
        children: [],
      },
    ],
    ...[...categories.entries()].sort((a, b) =>
      a[1].name.localeCompare(b[1].name)
    ),
  ]);

  return sortedCategories;
};
