import { ITask, isGuest } from "@ehabitation/ts-utils/browser";
import {
  Button,
  ButtonIsland,
  ForecastDay,
  ForecastTable,
  LoadingType,
  LoadingWrapper,
  Modal,
} from "@ehabitation/ui";
import { useForecast } from "Components/ForecastTable/useForecast";
import GanttWrapper from "Components/GanttWrapper/GanttWrapper";
import Initialising from "Components/Initialising/Initialising";
import DiscardAlert from "Components/Plan/PlanControls/DiscardAlert";
import PlanControls from "Components/Plan/PlanControls/PlanControls";
import { setAsMainPlan } from "Components/Plan/PlanControls/thunks";
import { useDiscardChanges } from "Components/Plan/PlanControls/useDiscardChanges";
import TabContainer from "Components/Plan/TabContainer/TabContainer";
import TaskList from "Components/Plan/TaskList/TaskList";
import Categorise from "Components/PlanImport/Categorise";
import PlanImport from "Components/PlanImport/PlanImport";
import PlanSaveAsModal from "Components/PlanSaveAsModal/PlanSaveAsModal";
import { Spinner } from "Components/Spinner";
import StayOnPageAlert from "Components/StayOnPageAlert/StayOnPageAlert";
import TornadoChart from "Components/TornadoChart/TornadoChart";
import DownloadSlide from "Components/WBReportModal/DownloadSlide";
import WeatherGraph from "Components/WeatherGraph/WeatherGraph";
import { weatherCodeIconMap } from "Pages/admin/constants";
import { tasksEquivalent } from "helpers";
import {
  useCheckPlanHash,
  useInitPlanner,
  useOpenPlannerEvent,
  useSelectPlan,
} from "hooks";
import { useProcessTaskUpdates } from "hooks/useProcessTaskUpdates";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { RiDeleteBinLine, RiSaveLine } from "react-icons/ri";
import { unstable_useBlocker as useBlocker } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { selectRole } from "store/auth";
import {
  selectAvailablePlans,
  selectCurrentPlanId,
  selectCurrentSite,
  selectProject,
  setSaveAsModalIsOpen,
} from "store/project";
import {
  selectAllTasksWithResultsEntries,
  selectCurrentTab,
  selectCurrentTaskId,
  selectLatestCurrentPlanSimulation,
  tasksSnapshotSelector,
} from "store/tasks";
import { selectTornadoModalIsOpen, setTornadoModalIsOpen } from "store/tornado";
import {
  selectSelectedChart,
  selectShowDependencies,
  selectTasksUpdating,
  setTaskModified,
} from "store/ui";
import { ISessionMetadata, ViewMode } from "types";
import { ChartsToggleButtonsContainer } from "./ChartsToggleButtonsContainer";
import { StyledPlanPage } from "./styles";
import { handleGanttSafetyEndChange } from "./thunks";
import useInitPlan from "./useInitPlan";
import { useLoadingMessage } from "./useLoadingMessage";
import { usePlanStateManager } from "./usePlanStateManager";
import { usePromptUserBeforeUnload } from "./usePromptUserBeforeUnload";
import { useScrollLocation } from "./useScrollLocation";
import { firebaseFunction } from "helpers";

interface PlanProps {
  wbModalOpen: boolean;
  setWBModalOpen: (open: boolean) => void;
  categoriseOpen: boolean;
  setCategoriseOpen: (open: boolean) => void;
  planImportOpen: boolean;
  setPlanImportOpen: (open: boolean) => void;
  riskExportOpen: boolean;
  setRiskExportOpen: (open: boolean) => void;
}

const ReduxInitialTaskWrappedCategorise: typeof Categorise = ({ ...props }) => {
  // temporary fudge to prevent gantt re-rendering when currentTask changes =( see issue ehabitation/web-app#814
  const initialOpenTaskId = useAppSelector(selectCurrentTaskId) || undefined;

  return <Categorise {...props} initialOpenTaskId={initialOpenTaskId} />;
};

const Plan: FC<PlanProps> = ({
  wbModalOpen,
  setWBModalOpen,
  categoriseOpen,
  setCategoriseOpen,
  planImportOpen,
  setPlanImportOpen,
}) => {
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectRole);
  const { tasksHaveBeenModified } = usePromptUserBeforeUnload();
  const {
    promptIsOpen,
    handleCancelPlanSwitch,
    handleConfirm,
    handleOpenSavePlanModal,
    handleSelectPlan,
  } = useSelectPlan();

  const { dailyForecasts, forecastSiteId, error } = useForecast();

  /* When navigating to the page, clear whether 
  there have been modifications to any tasks */
  useEffect(() => {
    dispatch(setTaskModified(false));
  }, [dispatch]);

  const currentTab = useAppSelector(selectCurrentTab);
  const tornadoModalIsOpen = useAppSelector(selectTornadoModalIsOpen);
  const showDependencies = useAppSelector(selectShowDependencies);
  const selectedChart = useAppSelector(selectSelectedChart);
  const tasksSnapshot = useAppSelector(tasksSnapshotSelector);
  const { handleTaskUpdate } = useProcessTaskUpdates();
  const plans = useAppSelector(selectAvailablePlans);
  const currentPlanId = useAppSelector(selectCurrentPlanId);
  const currentPlan = plans && currentPlanId ? plans[currentPlanId] : null;

  const [selectedForecastIndex, setSelectedForecastIndex] = useState(0);

  const {
    ganttTasks,
    allTasks,
    siteStartDate,
    siteEndDate,
    workDays,
    holidays,
    metadata,
  } = usePlanStateManager();

  const project = useAppSelector(selectProject);
  const projectId = project?.id;

  const site = useAppSelector(selectCurrentSite);
  const tasksUpdating = useAppSelector(selectTasksUpdating);

  const { isLoading: isLoadingInitPlan } = useInitPlan(
    handleSelectPlan,
    site,
    project
  );

  const latestSimulation = useAppSelector(selectLatestCurrentPlanSimulation);
  const isSimulating =
    !!latestSimulation &&
    !["error", "complete"].includes(latestSimulation?.status || "");

  const { loadingMessage, isLoading } = useLoadingMessage();

  useOpenPlannerEvent(site?.id);

  const { handleGraphSafetyEndChange } = useInitPlanner();

  const { handleUpdateScrollPosition, ganttScrollLocation } =
    useScrollLocation();

  const userIsGuest = isGuest(userRole!);

  const blocker = useBlocker(tasksHaveBeenModified && !userIsGuest);

  const allTasksWithResults = useAppSelector(selectAllTasksWithResultsEntries);

  const {
    isLoading: checkingMatrix,
    minorHashDrift,
    majorHashDrift,
  } = useCheckPlanHash(projectId!, currentPlanId!);

  const matrixDrift = minorHashDrift || majorHashDrift;

  const {
    handleRevertToSnapshot,
    handleCancel,
    handleClickDiscard,
    alertIsOpen,
  } = useDiscardChanges();

  const isSiteActive = site?.status === "open";

  if (!isLoadingInitPlan && !checkingMatrix) {
    const saveMessage = tasksHaveBeenModified
      ? "Unsaved Changes"
      : tasksUpdating
      ? "Saving"
      : (currentPlan as any)?.tasksUpdatedAt
      ? `Saved ${moment((currentPlan as any)?.tasksUpdatedAt).format(
          "Do MMM [at] kk:mm"
        )}`
      : "";
    return (
      <StyledPlanPage className="px-2">
        <PlanControls
          handleSelectPlan={handleSelectPlan}
          handleImportClick={
            isSiteActive ? () => setPlanImportOpen(true) : undefined
          }
        />

        <div className="chart-container relative">
          <TaskList
            handleOpenPlanImport={
              isSiteActive ? () => setPlanImportOpen(true) : undefined
            }
          />

          {!currentPlanId ? null : (
            <>
              <TabContainer
                handleEditCategory={() => setCategoriseOpen(true)}
              />
              {/* CONVERT THESE EVENT HANDLERS INTO THUNKS */}
              <GanttWrapper
                tasks={ganttTasks}
                allTasks={allTasks}
                isSimulating={isSimulating}
                showDeps={showDependencies}
                startDate={siteStartDate}
                endDate={siteEndDate}
                workDays={workDays}
                holidays={holidays}
                metadata={metadata}
                xPos={ganttScrollLocation.current}
                viewMode={ViewMode.DAY}
                onSafetyEndChange={(
                  tasks: ITask[],
                  wbs: ITask,
                  safeEnd: Date,
                  accuracy: number
                ) => {
                  const selectedTask = tasks[0];
                  if (!selectedTask.milestone) {
                    handleGraphSafetyEndChange(tasks, safeEnd, accuracy);
                    dispatch(
                      handleGanttSafetyEndChange(
                        selectedTask,
                        safeEnd,
                        accuracy,
                        wbs
                      )
                    );
                  }
                }}
                onUpdateRisks={(
                  metadata: ISessionMetadata,
                  tasks: ITask[],
                  wbs: ITask[]
                ) => {
                  const modifiedTasks = tasks.filter((task) =>
                    tasksEquivalent(tasksSnapshot?.[task.id], task)
                  );

                  if (modifiedTasks) {
                    handleTaskUpdate(tasks);
                  }
                }}
                onScrollChange={handleUpdateScrollPosition}
              />
              <ButtonIsland
                id="task-categories"
                message={saveMessage}
                className="z-10 absolute bottom-20 right-16"
                isAbsolute={false}
                buttons={[
                  <Button
                    key="save"
                    className="mr-2 whitespace-nowrap"
                    type="submit"
                    aria-label="Save"
                    isCompact
                    onClick={() => dispatch(setSaveAsModalIsOpen(true))}
                    disabled={
                      !currentPlanId || !tasksHaveBeenModified || userIsGuest
                    }
                    data-testid="save-button"
                  >
                    {tasksUpdating ? (
                      <Spinner />
                    ) : (
                      <>
                        <RiSaveLine className="inline" /> {"Save"}
                      </>
                    )}
                  </Button>,
                  <Button
                    key="discard"
                    className="bg-red-500 whitespace-nowrap"
                    type="submit"
                    aria-label="Discard"
                    data-testid="discard-button"
                    isCompact
                    onClick={handleClickDiscard}
                    disabled={
                      !currentPlanId || !tasksHaveBeenModified || userIsGuest
                    }
                  >
                    {tasksUpdating ? (
                      <Spinner />
                    ) : (
                      <>
                        <RiDeleteBinLine className="inline" /> {"Discard"}
                      </>
                    )}
                  </Button>,
                ]}
              />
            </>
          )}
        </div>

        <div className="bottom-row px-6">
          {currentTab === "Gantt" && (
            <>
              {selectedChart === "weather" && <WeatherGraph />}
              {selectedChart === "forecast" && dailyForecasts && (
                <div className="flex mr-auto -mt-6 items-center">
                  <div className="px-[4rem]">
                    <ForecastDay
                      data={dailyForecasts[selectedForecastIndex]}
                      weatherMap={weatherCodeIconMap}
                    />
                  </div>
                  <ForecastTable
                    hideMonthLabels={true}
                    weatherCodeIconMap={weatherCodeIconMap}
                    highlightIndex={selectedForecastIndex}
                    handleDayClick={(index: number) => {
                      setSelectedForecastIndex(index);
                    }}
                    dailyForecasts={dailyForecasts}
                    error={error}
                    forecastSiteId={forecastSiteId}
                    excludeRows={["hourlyRainAcc"]}
                    compact={true}
                  />
                </div>
              )}
              <ChartsToggleButtonsContainer />
            </>
          )}
        </div>

        {/* MODALS */}
        {categoriseOpen && site && currentPlan && (
          <Modal
            id="categorise"
            handleCloseModal={() => setCategoriseOpen(false)}
            xl={true}
            title="Categorise Tasks"
            subTitle={site?.name}
          >
            <ReduxInitialTaskWrappedCategorise
              site={site}
              plan={currentPlan}
              handleComplete={() => setCategoriseOpen(false)}
            />
          </Modal>
        )}

        {alertIsOpen ? (
          <DiscardAlert
            message="Are you sure you want to discard all of your changes?"
            handleCancel={handleCancel}
            handleDiscard={handleRevertToSnapshot}
          />
        ) : null}

        {promptIsOpen ? (
          <StayOnPageAlert
            message="You may have unsaved data, are you sure you want to change plan?"
            handleStayOnPage={handleCancelPlanSwitch}
            handleSave={handleOpenSavePlanModal}
            handleLeavePage={handleConfirm}
          />
        ) : null}
        {planImportOpen && site && (
          <Modal
            id="plan-import"
            handleCloseModal={() => setPlanImportOpen(false)}
            xl={true}
            title="Plan Import"
            subTitle={site?.name}
          >
            {isSiteActive ? (
              <PlanImport
                site={site}
                project={project}
                handleSuccess={async (planId: string) => { // Make this function async
                  setPlanImportOpen(false);
                  handleSelectPlan(planId);
                  if (!site?.mainPlanId) {
                    dispatch(setAsMainPlan(site, planId));
                  }
                  const SetPlanCategoriesInUse = firebaseFunction("SetPlanCategoriesInUse");
                  await SetPlanCategoriesInUse({ plan_id: planId });
                }}
              />
            ) : (
              <div className="h-full w-full grid place-content-center">
                <h2>Cannot import, site data still building...</h2>
              </div>
            )}
          </Modal>
        )}
        <PlanSaveAsModal />

        {wbModalOpen && site && currentPlanId && projectId && (
          <Modal id="wb-report" handleCloseModal={() => setWBModalOpen(false)}>
            <DownloadSlide
              handleCloseModal={() => setWBModalOpen(false)}
              siteId={site?.id}
              planId={currentPlanId}
              projectId={projectId}
            />
          </Modal>
        )}

        {tornadoModalIsOpen && site ? (
          <Modal
            id="tornado"
            handleCloseModal={() => dispatch(setTornadoModalIsOpen(false))}
          >
            <TornadoChart site={site} tasksWithResults={allTasksWithResults} />
          </Modal>
        ) : null}
        <LoadingWrapper
          message={loadingMessage}
          type={LoadingType.full}
          loading={!!isLoading}
        />
        {/* PROMPTS TO LEAVE PAGE */}
        {blocker.state === "blocked" ? (
          <StayOnPageAlert
            message="You may have unsaved data, are you sure you want to leave?"
            handleStayOnPage={() => blocker.reset()}
            handleLeavePage={() => blocker.proceed()}
            handleSave={() => {
              blocker.reset();
              handleOpenSavePlanModal();
            }}
          />
        ) : null}
      </StyledPlanPage>
    );
  } else {
    return (
      <Initialising>
        <p>Loading Plan Data...</p>
      </Initialising>
    );
  }
};

export default Plan;
