//Store
import { useState } from "react";
import { useAppDispatch } from "store";
import { revertToSnapshots } from "./thunks";

export const useDiscardChanges = () => {
  const dispatch = useAppDispatch();

  const [alertIsOpen, setAlertIsOpen] = useState(false);

  const handleCancel = () => {
    setAlertIsOpen(false);
  };

  const handleClickDiscard = () => {
    setAlertIsOpen(true);
  };

  const handleRevertToSnapshot = () => {
    setAlertIsOpen(false);
    dispatch(revertToSnapshots());
  };

  return {
    handleRevertToSnapshot,
    handleCancel,
    handleClickDiscard,
    alertIsOpen,
  };
};
