//MUI
import { DialogContentText } from "@material-ui/core";
import { KeyboardTimePicker } from "@material-ui/pickers";
//Styles
import styled from "styled-components";
import { spacing, grays, flex, fontSize, replaceOutline, colors } from "styles";

export const StyledWorkDays = styled.div`
  ${flex("row", "flex-start", "flex-start")};
  padding-bottom: ${spacing.s};

  h2 {
    width: 25%;
  }

  > div {
    width: 100%;
    width: 75%;
  }
`;

export const StyledWorkDayRow = styled.div`
  ${flex("row", "flex-start", "center")};
  padding-bottom: ${spacing.m};
  width: 100%;

  .day {
    width: 12rem;
  }

  .work-time {
    width: 14rem;
  }

  .edit-button {
    ${flex("row", "center", "center")};
    ${replaceOutline()};
    font-size: ${fontSize.m};
    background: none;
    border: none;
    cursor: pointer;
    color: ${grays.dark};
  }

  .delete-button {
    ${flex("row", "center", "center")};
    ${replaceOutline()};
    font-size: ${fontSize.m};
    background: none;
    border: none;
    cursor: pointer;
    color: ${colors.major};
    margin-left: 2rem;
  }
`;

export const StyledWorkHoursError = styled(DialogContentText)`
  color: ${colors.major} !important;
  margin-top: 1.2rem !important;
`;

export const StyledStartTimePicker = styled(KeyboardTimePicker)`
  margin-right: ${spacing.l} !important;
`;
