//React
import React, { FC } from "react";
//Styles
import { StyledContent } from "../styles";
//Store
import { useAppSelector } from "store";
import { selectCalendarInitialised } from "store/calendar";
//Components
import WorkDays from "./WorkDays/WorkDays";
import Holidays from "./Holidays/Holidays";

const Calendar: FC = () => {
  const calendarIntialised = useAppSelector(selectCalendarInitialised);

  if (!calendarIntialised) {
    return <div>Loading calendar data...</div>;
  }

  return (
    <StyledContent>
      <section>
        <WorkDays />

        <Holidays />
      </section>
    </StyledContent>
  );
};

export default Calendar;
