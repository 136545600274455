//React
import React, { FC, Fragment } from "react";
//Types
import { IWorkDay } from "types";
//Styles
import { StyledWorkDayRow } from "./styles";
//Icons
import { BiPencil } from "react-icons/bi";
//Hooks
import { useAppDispatch } from "store";
import { openModal, removeWorkDay } from "./thunks";
import { TiDeleteOutline } from "react-icons/ti";

interface IProps {
  day: IWorkDay;
}

const WorkDayRow: FC<IProps> = ({ day }) => {
  const dispatch = useAppDispatch();

  return (
    <Fragment>
      <StyledWorkDayRow>
        <p className="day">{day.day}</p>

        <p className="work-time" data-testid={`${day.day}-work-hours`}>
          {day.startTime && day.endTime
            ? `${day.startTime} to ${day.endTime}`
            : "N/A"}
        </p>

        <button
          className="edit-button"
          onClick={() => {
            dispatch(openModal(day));
          }}
          data-testid={`edit-hours-${day.day}`}
        >
          <BiPencil />
        </button>

        {day.startTime && day.endTime && (
          <button
            data-testid={`delete-${day.day}`}
            className="delete-button"
            onClick={() => {
              dispatch(removeWorkDay(day.day));
            }}
          >
            <TiDeleteOutline />
          </button>
        )}
      </StyledWorkDayRow>
    </Fragment>
  );
};

export default WorkDayRow;
