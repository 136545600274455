import { useCallback, useRef, useState } from "react";
import { batch } from "react-redux";
import { useAppDispatch, useAppSelector } from "store";
import {
  selectCurrentPlanId,
  setPlanGraphSaved,
  setSaveAsModalIsOpen,
} from "store/project";
import {
  selectPlanById,
  updateSnapshots,
} from "Components/Plan/PlanControls/thunks";
import {
  selectAllTasksWithResultsEntries,
  selectTasksSnapshotAsArray,
} from "store/tasks";
import { userMetadataSelector } from "store/p6";
import {
  saveTasksToDB,
  duplicatePlanData,
  applyNewPlanIdToTasks,
} from "helpers";
import { setTaskModified } from "store/ui";

export const useSaveAs = () => {
  const { siteId, userId } = useAppSelector(userMetadataSelector);

  const dispatch = useAppDispatch();

  const allTasks = useAppSelector(selectAllTasksWithResultsEntries);
  const tasksSnapshot = useAppSelector(selectTasksSnapshotAsArray);
  const currentPlanId = useAppSelector(selectCurrentPlanId)!;

  const unsubscribeRef = useRef<() => void>();

  const [saving, setSaving] = useState(false);

  //Save as ... -> available to plan owners + non owners
  const cloneExistingPlan = async (title: string) => {
    setSaving(true);

    const { newPlanId } = await duplicatePlanData(
      currentPlanId,
      userId!,
      title
    );

    const savedTaskSnapshot = tasksSnapshot ? tasksSnapshot : [];

    const convertedSnapshot = await applyNewPlanIdToTasks(
      newPlanId,
      savedTaskSnapshot
    );

    // Local tasks are converted to new plan id so local changes can be saved to new plan
    const convertedTasks = await applyNewPlanIdToTasks(newPlanId, allTasks!);

    await saveTasksToDB(convertedTasks!, convertedSnapshot, newPlanId);

    batch(() => {
      dispatch(setTaskModified(false));
      dispatch(setPlanGraphSaved());
    });

    setSaving(false);

    batch(() => {
      dispatch(setSaveAsModalIsOpen(false));
      dispatch(selectPlanById(newPlanId, unsubscribeRef));
    });

    return newPlanId;
  };

  //Save ... -> availble to owners only
  const overwriteCurrentPlan = useCallback(async () => {
    setSaving(true);

    const savedTaskSnapshot = tasksSnapshot ? tasksSnapshot : [];
    dispatch(updateSnapshots());

    try {
      await saveTasksToDB(allTasks!, savedTaskSnapshot, currentPlanId);
      batch(() => {
        dispatch(setTaskModified(false));
        dispatch(setPlanGraphSaved());
      });
    } catch (e) {
      const error = e as Error;
      console.error(error);
    } finally {
      setSaving(false);
      dispatch(setSaveAsModalIsOpen(false));
    }
  }, [tasksSnapshot, dispatch, allTasks, currentPlanId, siteId]);

  return {
    overwriteCurrentPlan,
    cloneExistingPlan,
    saving,
  };
};
