import { FC, useEffect, useState } from "react";
import { useSimulation } from "../hooks";
import {
  useDocQuery,
  useSetupSubscriptions,
  useSiteOpenPrintPageEvent,
} from "hooks";
import { useAppSelector } from "store";
import {
  selectOrganisationProjects,
  selectOrganisationSites,
} from "store/siteManagement";
import { IOrganisationSite } from "types";
import { useParams } from "react-router-dom";
import Initialising from "Components/Initialising/Initialising";
import { Spinner } from "Components/Spinner";
import { TraView } from "./TraView";
import {
  CollectionType,
  IPlan,
  transformPlanDoc,
} from "@ehabitation/ts-utils/browser";
import { PrintableView } from "../components/PrintableView";

export const TraViewPrintable: FC = () => {
  const subscriptionsReady = useSetupSubscriptions();

  const [site, setSite] = useState<IOrganisationSite>();
  const [siteProject, setSiteProject] = useState<string>();

  const organisationSites = useAppSelector(selectOrganisationSites);
  const projects = useAppSelector(selectOrganisationProjects);

  const { siteId = "", planId } = useParams<{
    siteId: string;
    planId: string;
  }>();

  const { data: plan } = useDocQuery<IPlan>(
    planId ? [CollectionType.Plans, planId] : [],
    (doc) => transformPlanDoc(doc.id, doc.data())
  );

  const { simulation } = useSimulation(siteId, planId);

  useEffect(() => {
    setSite(organisationSites[siteId]);
  }, [organisationSites]);

  useEffect(() => {
    site && setSiteProject(projects[site.project]?.name);
  }, [projects, site]);

  useSiteOpenPrintPageEvent("tra", siteId, planId);

  if (!subscriptionsReady)
    return (
      <Initialising>
        <p>Preparing your workspace...</p>
      </Initialising>
    );

  return (
    <div>
      {!simulation ? (
        <div className="relative h-full">
          <div className="w-full grid place-items-center h-72">
            <Spinner />
          </div>
        </div>
      ) : (
        <PrintableView page="Tra-QSRA">
          <div>
            <div className="px-8 flex">
              <h2 className="text-4xl">TRA & QSRA</h2>
              <p className="italic pl-4 pt-1">
                for site <strong>{site?.name}</strong> in project{" "}
                <strong>{siteProject}</strong>, plan{" "}
                <strong>{plan?.title}</strong> (
                {plan?.id === site?.mainPlanId ? "main" : "draft"})
              </p>
            </div>
            <div className="min-h-[50rem] p-8">
              <TraView simulation={simulation} />
            </div>
            <div className="sticky print:static bg-gray-200 text-center bottom-0 w-full z-10">
              <span className="px-6">
                Don&apos;t have an ehab account? Sign up{" "}
                <a target="_blak" href="/" className="underline">
                  here
                </a>{" "}
                for free weather insights
              </span>
            </div>
          </div>
        </PrintableView>
      )}
    </div>
  );
};
