import { ITask, Risks } from "@ehabitation/ts-utils/browser";
import { ITornadoTask, TornadoRisk, IRiskIntervals } from "types";
import { colors } from "styles";

export const determineBarColor = (key: TornadoRisk) => {
  const refObjColor: { [risk in TornadoRisk]: string } = {
    percent: colors.ganttGray,
    original: colors.ganttGray,
    low: colors.ganttRed,
    medium: colors.ganttYellow,
    high: colors.ganttGreen,
  };

  return refObjColor[key];
};

const reverseSortMaxTaskArray = (arr: ITornadoTask[]) => {
  return arr
    .sort((a, b) => {
      return a.cumulative - b.cumulative;
    })
    .reverse();
};

const reverseSortWeightTaskArray = (arr: ITornadoTask[]) => {
  return arr
    .sort((a, b) => {
      return a.percent - b.percent;
    })
    .reverse();
};

const getOriginalTaskSpan = (task: ITask) => {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  const taskStart = new Date(task.start);
  const taskEnd = new Date(task.end);
  return Math.floor((taskEnd.getTime() - taskStart.getTime()) / MS_PER_DAY) + 1;
};

export const parseTaskArray = (
  tasks: ITask[] | null,
  riskIntervals: IRiskIntervals,
  action: "max" | "weight"
) => {
  const outputData: ITornadoTask[] = [];

  if (!tasks) return [];

  tasks.forEach((task) => {
    const originalSpan = getOriginalTaskSpan(task);

    const taskRiskData = task.risks
      ? transformTaskRiskForTornado(
          task.risks,
          task.accuracy,
          originalSpan,
          riskIntervals
        )
      : {};

    if (!("cumulative" in taskRiskData)) return;

    const tornadoTask: ITornadoTask = {
      activity: task.title,
      cumulative: 0,
      original: originalSpan,
      ...taskRiskData,
    };

    outputData.push(tornadoTask);
  });

  if (outputData.length === 0) return outputData;

  if (action === "max") return reverseSortMaxTaskArray(outputData);
  return reverseSortWeightTaskArray(outputData);
};

/*
  Parses task risk array into object which correctly presents cumulative delay across risk levels.
  Also calculates proportional delay within tasks.
*/
const transformTaskRiskForTornado = (
  risks: Risks[],
  accuracy: number,
  originalSpan: number,
  riskIntervals: IRiskIntervals
) => {
  const riskObject = Object.assign({}, ...risks);
  const riskOut: any = {};

  if ("label" in riskObject) return riskOut;

  if (accuracy in riskObject) {
    for (const key of Object.keys(riskObject)) {
      if (Number(key) <= accuracy) delete riskObject[key];
    }
  }

  if (riskIntervals.low in riskObject)
    riskOut["low"] = riskObject[riskIntervals.low].width / 24;
  if (riskIntervals.medium in riskObject)
    riskOut["medium"] =
      riskObject[riskIntervals.medium].width / 24 - (riskOut["low"] || 0);
  if (riskIntervals.high in riskObject)
    riskOut["high"] =
      riskObject[riskIntervals.high].width / 24 -
      (riskOut["low"] || 0) -
      (riskOut["medium"] || 0);

  riskOut["cumulative"] = Object.values(riskOut).reduce(
    (a: any, b: any) => a + b,
    0
  );

  if (riskOut["cumulative"] === 0) return {};

  riskOut["percent"] = Math.round((originalSpan / riskOut["cumulative"]) * 100);

  return riskOut;
};
