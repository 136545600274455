import { ChangeEvent, FC } from "react";
import { CircularProgress } from "@material-ui/core";
import { StyledTextInput } from "elements";
import { StyledForm, StyledErrorMessage } from "./styles";
import { useSaveAsForm } from "./useSaveAsForm";
import { MonochromeButton } from "@ehabitation/ui";

const SaveAsForm: FC = () => {
  const {
    saving,
    error,
    behaviourSelection,
    newPlanName,
    handleSubmit,
    handleRadioInputChange,
    handleTextInputChange,
  } = useSaveAsForm();

  return (
    <StyledForm onSubmit={handleSubmit}>
      {!saving ? (
        <>
          {" "}
          <h2>Save as...</h2>
          <div className="radio-group">
            <input
              type="radio"
              value="overwrite"
              checked={behaviourSelection === "overwrite"}
              name="overwrite"
              id="choice-overwrite"
              onChange={handleRadioInputChange}
            />
            <label htmlFor="choice-overwrite">Update The Current Plan</label>
          </div>
          <div className="radio-group">
            <>
              <input
                className="radio-new-plan"
                type="radio"
                value="new"
                checked={behaviourSelection === "new"}
                name="new"
                id="choice-new"
                onChange={handleRadioInputChange}
              />
              <label htmlFor="choice-new" className="mr-4">
                Save As A New Plan
              </label>
            </>

            <StyledTextInput
              type="text"
              value={newPlanName}
              placeholder="Plan Name"
              onChange={handleTextInputChange}
              disabled={behaviourSelection !== "new"}
              onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                e.target.placeholder = "";
              }}
              onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                e.target.placeholder = "Plan Name";
              }}
              data-testid="save-as-name-input"
            />
          </div>
          <MonochromeButton level="primary" width="md" type="submit">
            Save
          </MonochromeButton>
          <StyledErrorMessage>{error}</StyledErrorMessage>
        </>
      ) : (
        <div className="saving-message">
          <CircularProgress color="inherit" />
          <p>Saving...</p>
        </div>
      )}
    </StyledForm>
  );
};

export default SaveAsForm;
