import { useState, useEffect } from "react";
import { useAppDispatch } from "store";
import { auth } from "firebaseConfig";
import * as Sentry from "@sentry/react";
import { setRole, setUserUID, setUserClaims } from "store/auth";
import { CollectionType, ICustomClaims } from "@ehabitation/ts-utils/browser";
import { setAnalyticsUserProperties } from "helpers/analytics";
import { onAuthStateChanged, getIdTokenResult, User } from "firebase/auth";
import config from "config";
import { fetchUserMetadata } from "Routers/thunks";
import { getHiearchicalConfig } from "helpers/firebase/firestoreUtils";

import { logSignupEvent } from "helpers/analytics";

import { fetchSingleUser, firebaseFunction } from "helpers";

export const useAuthStatus = () => {
  const dispatch = useAppDispatch();

  const [appInitialised, setAppInitialised] = useState(false);
  const [mfaUser, setMfaUser] = useState<User>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (!currentUser) {
        if (config.SHOULD_USE_SENTRY) {
          Sentry.setUser(null);
          setAnalyticsUserProperties();
        }
        setAppInitialised(true);
        return;
      } else {
        try {
          const userExists = await fetchSingleUser(currentUser.uid);
          if (!userExists) {
            const registerUser = firebaseFunction("registerUser");
            await registerUser({
              email: currentUser.email,
              firstName: currentUser.displayName ? currentUser.displayName.split(" ")[0] : "",
              lastName: currentUser.displayName ? currentUser.displayName.split(" ").pop() : "",
              tenantId: currentUser.tenantId || "",
            });
            logSignupEvent();
            await currentUser.getIdToken(true);
            // Re-authenticate the user to ensure the token is updated
            if (currentUser.tenantId) {
              auth.tenantId = currentUser.tenantId;
            }
            await auth.updateCurrentUser(currentUser);
          }
        } catch (error) {
          console.error("Error registering user:", error);
        }

        const tokenResult = await getIdTokenResult(currentUser);
        const claims = tokenResult?.claims as unknown as ICustomClaims;

        if (claims) {
          if (claims.organisation) {
            // Check MFA requirements
            const mfaType = await getHiearchicalConfig(
              claims.organisation,
              CollectionType.Organisations,
              "mfaType"
            );

            if (mfaType.value === "SMS" && !tokenResult.signInSecondFactor) {
              setMfaUser(currentUser);
              setAppInitialised(true);
              return;
            }
          }

          dispatch(setUserClaims(claims));
          if (claims?.role) dispatch(setRole(claims?.role));

          setAnalyticsUserProperties({
            role: claims.role,
            organisation: claims.organisation,
            division: claims.division,
            project: claims.project,
          });

          if (config.SHOULD_USE_SENTRY) {
            Sentry.setUser({
              id: currentUser.uid,
              email: currentUser.email || "",
              ip_address: "{{auto}}",
              organisation: claims.organisation,
              division: claims.division,
              project: claims.project,
              role: claims.role,
            });
          }
        }

        dispatch(setUserUID(currentUser.uid));
        await dispatch(fetchUserMetadata());
        setAppInitialised(true);
      }
    });

    return () => {
      unsubscribe(); //Cleanup
    };
  }, [dispatch]);

  return { appInitialised, mfaUser };
};
