import { ForecastTable, ForecastDay, ForecastHourly } from "@ehabitation/ui";
import { FC, useEffect, useMemo, useState } from "react";
import IBMLogo from "assets/images/The_Weather_Company_IBM.svg";
import ECMWFLogo from "assets/images/ECMWF_logo.svg";

import { ISite } from "@ehabitation/ts-utils/browser";
import { Spinner } from "Components/Spinner";
import {
  usePersonalSites,
  usePlanRiskMatrix,
  useRiskMatrixTopLevelCategories,
  useSetupSubscriptions,
  useSiteOpenPrintPageEvent,
  useSiteRiskMatrix,
} from "hooks";
import { weatherCodeIconMap } from "Pages/admin/constants";
import { useAppSelector } from "store";
import { useParams } from "react-router-dom";
import {
  selectOrganisationProjects,
  selectOrganisationSites,
} from "store/siteManagement";
import Initialising from "Components/Initialising/Initialising";
import {
  useForecastData,
  useTaskCategories,
  useTasksInWindow,
} from "./WeatherView";
import { Tooltip } from "@material-ui/core";
import { PrintableView } from "../components/PrintableView";
import { useProcessedTasks, useTaskDocs } from "../hooks";


const todayMidnight = new Date();
todayMidnight.setHours(0, 0, 0, 0);

const ForecastPrintable: FC = () => {
  const [siteProject, setSiteProject] = useState<string>();

  const subscriptionsReady = useSetupSubscriptions();

  const organisationSites = useAppSelector(selectOrganisationSites);

  const projects = useAppSelector(selectOrganisationProjects);
  const siteId = useParams<{ siteId: string }>().siteId || "";
  const planId = useParams<{ planId: string }>().planId || "";
  const selectedCategoryId =
    localStorage.getItem(
      `forecast_selectedCategoryName_${siteId}_${planId || 0}}`
    ) || "";
  const selectedTaskId =
    localStorage.getItem(`forecast_selectedTaskId_${siteId}_${planId || 0}}`) ||
    "";

  const {
    sites: personalSites = [],
    initialisingSites: initialisingUserSites,
  } = usePersonalSites();

  useSiteOpenPrintPageEvent("weather", siteId);

  const site = siteId
    ? organisationSites[siteId] ||
      personalSites?.find(({ id }: ISite) => id === siteId)
    : undefined;
  useEffect(() => {
    site && setSiteProject(projects[site.project]?.name);
  }, [site, projects]);

  const forecastSourceIds = site?.forecastInUse;
  const { forecast, dailyForecasts, hourlyForecasts, sourceType } =
    useForecastData(forecastSourceIds);

  const { riskMatrix: siteRiskMatrix } = useSiteRiskMatrix(site);
  const { riskMatrix: planRiskMatrix } = usePlanRiskMatrix(planId);

  const { categories, isLoading: isLoadingCategories } =
    useRiskMatrixTopLevelCategories(planRiskMatrix?.id || siteRiskMatrix?.id);

  const { taskDocs } = useTaskDocs(siteId, planId);

  const { tasks } = useProcessedTasks(siteId!, categories, taskDocs);

  const selectedTask = useMemo(() => {
    return tasks?.find(({ id }) => id === selectedTaskId);
  }, [tasks, selectedTaskId]);

  const { tasksInWindow, leafTasks } = useTasksInWindow(tasks, dailyForecasts);

  const {
    orderedUpcomingCategoryImpactCounts,
    orderedUntaskedCategoryImpactCounts,
    upcomingCategories,
    untaskedCategories,
  } = useTaskCategories(
    categories || [],
    dailyForecasts,
    leafTasks,
    tasksInWindow
  );

  const activeCategory = useMemo(() => {
    const activeCategoryId =
      selectedCategoryId || selectedTask?.taskCategory?.selectedId;
    return [...upcomingCategories, ...untaskedCategories].find(
      (category) => category.id === activeCategoryId
    );
  }, [
    upcomingCategories,
    untaskedCategories,
    selectedCategoryId,
    selectedTask,
  ]);

  if (!subscriptionsReady || initialisingUserSites)
    return (
      <Initialising>
        <p>Preparing your workspace...</p>
      </Initialising>
    );

  return dailyForecasts && hourlyForecasts && !isLoadingCategories ? (
    <PrintableView page="Forecast">
      <div>
        <div className="px-8 flex">
          <h2 className="text-4xl">Forecast</h2>
          <p className="italic pl-4 pt-1">
            for site <strong>{site?.name}</strong> in project{" "}
            <strong>{siteProject}</strong>
          </p>
        </div>
        <ForecastTable
          dailyForecasts={dailyForecasts!}
          highlightIndex={0}
          handleDayClick={() => ({})}
          thresholds={activeCategory?.thresholds}
          error=""
          forecastSiteId={forecast!.id}
          weatherCodeIconMap={weatherCodeIconMap}
          orderedUpcomingCategoryImpactCounts={
            orderedUpcomingCategoryImpactCounts
          }
          orderedUntaskedCategoryImpactCounts={
            orderedUntaskedCategoryImpactCounts
          }
          selectedCategoryName={selectedCategoryId}
          setSelectedCategoryName={() => ({})}
          selectedTaskId={selectedTaskId}
          setSelectedTaskId={() => ({})}
          tasks={tasksInWindow}
        />
        {Array.from(
          { length: hourlyForecasts!.length - 1 },
          (_, index) => index + 1
        ).map((i) => (
          <div key={i} className="px-4 py-6 break-inside-avoid">
            <div className="w-[38rem] grid place-content-center">
              <ForecastDay
                data={dailyForecasts![i]}
                weatherMap={weatherCodeIconMap}
                thresholds={activeCategory?.thresholds}
              />
            </div>
            <div className="min-w-0 px-4 pt-2">
              <ForecastHourly
                hourlyForecasts={hourlyForecasts!}
                selectedForecastIndex={i}
                thresholds={activeCategory?.thresholds}
                allowScroll={false}
              />
            </div>
          </div>
        ))}
        <div className="flex-grow grid place-content-center">
          <div className="flex justify-between gap-10 items-center">
            {sourceType && sourceType.length > 0 ? (
              sourceType?.map((type) =>
                type === "IBM" ? (
                  <Tooltip
                    key={type}
                    arrow
                    title={
                      <span className="text-xl">
                        Data sourced from IBM Weather Company
                      </span>
                    }
                  >
                    <img src={IBMLogo} className="h-32 ml-auto" />
                  </Tooltip>
                ) : type === "ECMWF" ? (
                  <Tooltip
                    key={type}
                    arrow
                    title={
                      <span className="text-xl">
                        Data sourced from ECMWF (European Centre for
                        Medium-Range Weather Forecasts)
                      </span>
                    }
                  >
                    <img src={ECMWFLogo} className="h-16 ml-auto" />
                  </Tooltip>
                ) : (
                  <></>
                )
              )
            ) : (
              <div>Not Available</div>
            )}
          </div>
        </div>
        <div className="pt-16">
          <div className="absolute print:static bg-gray-200 text-center bottom-0 w-full z-10">
            <span className="px-6">
              Don&apos;t have an ehab account? Sign up{" "}
              <a target="_blak" href="/" className="underline">
                here
              </a>{" "}
              for free weather insights
            </span>
          </div>
        </div>
      </div>
    </PrintableView>
  ) : (
    <div className="w-full grid place-items-center h-72">
      <Spinner />
    </div>
  );
};

export const WeatherViewPrintable: FC = () => {
  return <ForecastPrintable />;
};
