// TODO: Make this generic, convert to a utility for averaging number arrays

const tooltipClasses =
  "relative before:absolute before:border before:border-black before:z-10 before:content-tip before:-left-[200%] before:top-[150%] before:mb-2 before:bg-green-50 before:rounded-md before:p-2 hover:before:flex before:hidden";

type HeatmapRowProps = {
  data: Record<number, number>; // A dictionary where keys are week numbers and values are probabilities
  className?: string;
};

export const HeatmapRow: React.FC<HeatmapRowProps> = ({ data, className }) => {
  const totalWeeks = 52; // Assuming each year has 52 weeks

  return (
    <div className={`flex ${className}`}>
      {Array.from({ length: totalWeeks }).map((_, index) => {
        const weekNumber = index + 1;
        const probability = data[weekNumber];

        const backgroundColor =
          probability !== undefined ? getHeatmapColor(probability) : "bg-white";
        const borderColor =
          probability !== undefined ? "border-black" : "border-gray-300";

        return (
          <div
            key={weekNumber}
            className={`w-[7.7%] h-8 grid place-items-center ${backgroundColor} border border-[0.5px] ${borderColor} mr-0.5`}
            title={`Week ${weekNumber}: ${
              probability !== undefined ? probability * 100 : 0
            }%`}
          >
            {probability !== undefined && (
              <span className={`text-sm ${probability >= 0.8 ? 'text-red-100' : ''}`}>
                {probability < 1
                  ? probability.toFixed(2).replace(/^0/, "")
                  : "1"}
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};

function getHeatmapColor(ratio: number): string {
  if (ratio >= 0.8) return "bg-red-600";
  if (ratio >= 0.6) return "bg-red-200";
  if (ratio >= 0.4) return "bg-yellow-400";
  if (ratio >= 0.2) return "bg-green-200";
  if (ratio >= 0.0) return "bg-green-400";
  return "bg-gray-300";
}
