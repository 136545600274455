import { batch } from "react-redux";
import { doc, updateDoc } from "firebase/firestore";
import moment from "moment";

import { AppThunk } from "store";
import {
  clearWorkHoursForm,
  setWorkHoursError,
  setWorkHoursFormInit,
  setWorkHoursModal,
  updateWorkDay,
} from "store/calendar";
import { selectActiveSiteId } from "store/auth";
import { IWorkDay, Weekday } from "types";
import { db } from "firebaseConfig";
import { convert24HourStartAndEndTime } from "./helpers";

export const openModal: AppThunk =
  ({ startTime, endTime, day }: IWorkDay) =>
  (dispatch) => {
    if (startTime?.length !== 4 || endTime?.length !== 4) {
      startTime = "0800";
      endTime = "1800";
    }
    const { startTimeDate, endTimeDate } = convert24HourStartAndEndTime(
      startTime,
      endTime
    );

    batch(() => {
      dispatch(
        setWorkHoursFormInit({
          startTime: startTimeDate.getTime(),
          endTime: endTimeDate.getTime(),
          day,
        })
      );
      dispatch(setWorkHoursModal(true));
    });
  };

export const closeModal: AppThunk = () => (dispatch) => {
  batch(() => {
    dispatch(clearWorkHoursForm());
    dispatch(setWorkHoursModal(false));
  });
};

export const removeWorkDay: AppThunk =
  (day: Weekday) => (dispatch, getState) => {
    dispatch(updateWorkDay({ day: day, startTime: null, endTime: null }));

    const state = getState();
    const siteId = selectActiveSiteId(state);
    const workDays = state.calendar.workDays;

    updateDoc(doc(db, "calendar", siteId!), {
      workDays: [...workDays],
    });
  };

export const changeWorkHours: AppThunk =
  (startTime: Date, endTime: Date, day: Weekday) =>
  async (dispatch, getState) => {
    const state = getState();
    const siteId = selectActiveSiteId(state);
    const workDays = state.calendar.workDays;

    if (startTime > endTime) {
      dispatch(setWorkHoursError("End Time must be later than Start Time"));
      return;
    }

    const newWorkDay: IWorkDay = {
      day,
      startTime: moment(startTime).format("HHmm"),
      endTime: moment(endTime).format("HHmm"),
    };

    const newWorkDays = workDays.map((workDay) =>
      workDay.day === newWorkDay.day ? newWorkDay : workDay
    );

    updateDoc(doc(db, "calendar", siteId!), { workDays: newWorkDays });

    batch(() => {
      dispatch(updateWorkDay(newWorkDay));
      dispatch(closeModal());
    });
  };
