import { getDoc, doc, setDoc } from "firebase/firestore";

import { db } from "firebaseConfig";
import { ICalendar } from "types";
import { dummyCalendar } from "./dummyCalendar";

//Returns a tuple [exists, calendar data or null]
export const fetchSiteCalendar = async (
  siteId: string
): Promise<[boolean, null | ICalendar]> => {
  try {
    const docSnapshot = await getDoc(doc(db, "calendar", siteId!));

    if (docSnapshot.exists()) return [true, docSnapshot.data() as ICalendar];

    return [false, dummyCalendar];
  } catch (error) {
    console.error(error);
    return [false, null];
  }
};

export const setSiteCalendarAsDefault = async (siteId: string) => {
  try {
    const docRef = doc(db, "calendar", siteId!);

    await setDoc(docRef, dummyCalendar);
  } catch (error) {
    console.error(error);
  }
};
