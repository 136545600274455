import { doc, onSnapshot } from "firebase/firestore";
import axios from "axios";
import fileDownload from "js-file-download";

import { db } from "firebaseConfig";
import { ExportTypes, logExportEvent } from "helpers/analytics";
import { firebaseFunction } from "helpers";

interface IGenerateWeatherBaselineParams {
  plan: string;
  site: string;
  project: string;
}

interface IGeneratePDFParams {
  WBplan: string;
  site: string;
  project: string;
}

export const generateWeatherBaselineReport = async ({
  plan,
  site,
  project,
}: IGenerateWeatherBaselineParams) => {
  const createWeatherBaselineReport = firebaseFunction<{ WBPlanId: string }>(
    "createWeatherBaselineReport"
  );
  const response = await createWeatherBaselineReport({
    plan,
    site,
    project,
    weatherBaselinePlanName: `${site}_${plan}_${new Date().getTime()}`,
  });
  const WBPlanId = response?.data?.WBPlanId;
  if (!WBPlanId) {
    throw new Error("Error generating weather baseline report");
  }
  return WBPlanId;
};

export const generatePDF = ({ WBplan, site, project }: IGeneratePDFParams) => {
  const generatePDF = firebaseFunction("GenerateBaselinePDF");

  return generatePDF({
    WBplan,
    site,
    project,
  })
    .then((response) => response.data)
    .catch((err) => console.error(err));
};

export const downloadWBReport = async (url: string) => {
  const file = await axios.get(url, { responseType: "blob" });
  fileDownload(file.data as Blob, "Weather Baseline Report.pdf");
};

interface ISubscribeToReportURLParams {
  WBPlanId: string;
  handleSuccess: () => void;
  handleError: () => void;
}
export const subscribeToReportURL = ({
  WBPlanId,
  handleSuccess,
  handleError,
}: ISubscribeToReportURLParams) => {
  return onSnapshot(doc(db, "weatherBaselinePDF", WBPlanId), (snapshot) => {
    if (snapshot && snapshot.data()) {
      const pdfUrl = snapshot.data()?.pdfUrl;
      if (pdfUrl) {
        downloadWBReport(pdfUrl);
        logExportEvent({ planId: WBPlanId, exportType: ExportTypes.Baseline });
        handleSuccess();
      } else {
        handleError();
      }
    }
  });
};
