import moment from "moment";

export const convert24HourStartAndEndTime = (
  startTime: string,
  endTime: string
) => {
  return {
    startTimeDate: convert24HourStringToDate(startTime),
    endTimeDate: convert24HourStringToDate(endTime),
  };
};

const convert24HourStringToDate = (dateString: string) => {
  const startTimeHours = Number(`${dateString[0]}${dateString[1]}`);
  const startTimeMinutes = Number(`${dateString[2]}${dateString[3]}`);

  return moment()
    .set("hours", startTimeHours)
    .set("minutes", startTimeMinutes)
    .toDate();
};
